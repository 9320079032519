import { environment } from "../../environments/environment";
export var PAGE_TYPE = 1;
export var ORDER_STATE = {
    WAITING_FOR_ACCEPT_STORE: 1,
    CANCELED_BY_USER: 101,
    STORE_ACCEPTED: 3,
    STORE_REJECTED: 103,
    STORE_CANCELLED: 104,
    STORE_CANCELLED_REQUEST: 105,
    ADMIN_CANCELLED: 106,
    STORE_PREPARING_ORDER: 5,
    OREDER_READY: 7,
    NO_DELIVERY_MAN_FOUND: 8,
    WAITING_FOR_DELIVERY_MAN: 9,
    DELIVERY_MAN_ACCEPTED: 11,
    DELIVERY_MAN_REJECTED: 111,
    DELIVERY_MAN_CANCELLED: 112,
    DELIVERY_MAN_COMING: 13,
    DELIVERY_MAN_ARRIVED: 15,
    DELIVERY_MAN_PICKED_ORDER: 17,
    DELIVERY_MAN_STARTED_DELIVERY: 19,
    DELIVERY_MAN_ARRIVED_AT_DESTINATION: 21,
    DELIVERY_MAN_COMPLETE_DELIVERY: 23,
    ORDER_COMPLETED: 25,

    ADMIN_EDIT_ADDRESS: 201,
    ADMIN_EDIT_ORDER: 202,
    STORE_EDIT_ORDER: 203
};

export var WALLET_COMMENT_ID = {
    ADDED_BY_ADMIN: 1,
    ORDER_PROFIT: 6,
    ORDER_CANCELLATION_CHARGE: 7,
    WALLET_REQUEST_CHARGE: 8,
    
    SET_BY_ADMIN: 1,
    ADDED_BY_CARD: 2,
    ADDED_BY_REFERRAL: 3,
    ORDER_CHARGED: 4,
    ORDER_REFUND: 5,
    SET_ORDER_PROFIT: 6,
    SET_BY_WALLET_REQUEST: 8,
    SET_WEEKLY_PAYMENT_BY_ADMIN: 8,
};

export var CONSTANT = {
    IMAGE_BASE_URL: environment.imageUrl,
    BASE_URL: environment.apiUrl
}

export var DATE_FORMAT = {
    DD_MMM_YYYY: "dd MMM yyyy",
    HH_MM:"HH:mm",
    DD_MM_YYYY: "dd/MM/yyyy",
    DD_MMM_YYYY_HH_MM_A: "dd MMM yyyy hh:mm a",
    DD_MMM_YYYY_HH_MM_SS: "dd MMM yyyy hh:mm:ss"
};

export var ORDER_CANCELLATION_CHARGE_TYPE = {
    PERCENTAGE: 1,
    ABSOLUTE: 2
};

export var IMAGE_RATIO = {
    ITEM_IMAGE: 1,
    PRODUCT_IMAGE: 1
};

export var ADMIN_IMAGE_RATIO = {
    DELIVERY_IMAGE: 1,
    DELIVER_MAP_PIN: 0.45,
    DELIVERY_ICON: 1,
    VEHICLE_IMAGE: 1,
    VEHICLE_MAP_PIN: 0.45,
    ADS_BANNER_IMAGE: 1,
    ADS_FULL_IMAGE: 0.8
};

export var WEEK_DAY = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday"
};

export var ERROR_CODE_CONSTANT = {
    TOKEN_EXPIRED: 999,
    STORE_DATA_NOT_FOUND: 634,
    DATA_NOT_FOUND: 1000,
    TOKEN_INVALID: 2000
};


export var ORDER_STATUS_ID = {
    IDEAL: 0,
    RUNNING: 1,
    CANCELLED: 2,
    REJECTED: 3,
    NO_ANSWER: 4,
    COMPLETED: 10
};

export var WALLET_REQUEST_STATUS = {
    CREATED: 1,
    ACCEPTED: 2,
    TRANSFERED: 3,
    COMPLETED: 4,
    CANCELLED: 5
};

export var PAYMENT_GATEWAY = {
    CASH: 'cash',
    STRIPE: '586f7db95847c8704f537bd5',
    PAYPAL: '586f7db95847c8704f537bd6'
}




export var PROVIDER_TYPE = {
    NORMAL: 1,
    OTHER: 2
};

export var PROVIDER_TYPE_STRING = {
    NORMAL_STRING: 'Normal',
    OTHER_STRING: 'Other'
};

export var price_validation = {
    MAX_PRICE: 999,
    MAX_PRICE_AFTER_POINT: 9
}

export var TIMEOUT = {
    TOASTER_NOTIFICATION: 5000,
    PUSH_NOTIFICATION: 20000,
    NEW_PUSH_NOTIFICATION_REQUEST: 10000,
    NEW_ORDER_REQUEST: 10000
}

export var ADMIN_DATA_ID = {
    ADMIN: 1,
    SUB_ADMIN: 3,
    STORE: 2,
    USER: 7,
    PROVIDER: 8,
    PROVIDER_VEHICLE: 9
};


export var ADMIN_PROFIT_ON_ORDER = [
    {ID: 1, NAME: "PERCENTAGE"},
    {ID: 2, NAME: "PER ORDER"},
    {ID: 3, NAME: "PER ITEMS"}
];
// CONSTANTS
export var ADMIN_PROFIT_ON_ORDER_ID = {
    PERCENTAGE: 1,
    PER_ORDER: 2,
    PER_ITEMS: 3
};
export var ADMIN_PROFIT_ON_ORDER_STRING = {
    PERCENTAGE: "Percentage",
    PER_ORDER: "Absolute price per order",
    PER_ITEMS: "Absolute price per item"
};
export var ADMIN_PROFIT_ON_DELIVERY_ID = {
    PERCENTAGE: 1,
    PER_DELVIERY: 2
};

export var ADMIN_PROFIT_ON_DELIVERY_STRING = {
    PERCENTAGE: "Percentage",
    PER_DELVIERY: "Absolute price per delivery"
};
// CONSTANT ARRAY 
export var ADMIN_PROFIT_ON_ORDERS = [
    {ID: ADMIN_PROFIT_ON_ORDER_ID.PERCENTAGE, NAME: ADMIN_PROFIT_ON_ORDER_STRING.PERCENTAGE},
    {ID: ADMIN_PROFIT_ON_ORDER_ID.PER_ORDER, NAME: ADMIN_PROFIT_ON_ORDER_STRING.PER_ORDER},
    {ID: ADMIN_PROFIT_ON_ORDER_ID.PER_ITEMS, NAME: ADMIN_PROFIT_ON_ORDER_STRING.PER_ITEMS}
];
export var ADMIN_PROFIT_ON_DELIVERYS = [
    {ID: ADMIN_PROFIT_ON_DELIVERY_ID.PERCENTAGE, NAME: ADMIN_PROFIT_ON_DELIVERY_STRING.PERCENTAGE},
    {ID: ADMIN_PROFIT_ON_DELIVERY_ID.PER_DELVIERY, NAME: ADMIN_PROFIT_ON_DELIVERY_STRING.PER_DELVIERY}

];


export var ADMIN_IMAGE_TYPE_ID = {
    JPG: "image/jpg",
    PNG: "image/png",
    JPEG: "image/jpeg"
};

export var ADMIN_IMAGE_TYPE_STRING = {
    JPG: "jpg",
    PNG: "png",
    JPEG: "jpeg"
};

export var ADMIN_IMAGE_TYPES = [
    {ID: ADMIN_IMAGE_TYPE_ID.JPG, NAME: ADMIN_IMAGE_TYPE_STRING.JPG},
    {ID: ADMIN_IMAGE_TYPE_ID.PNG, NAME: ADMIN_IMAGE_TYPE_STRING.PNG},
    {ID: ADMIN_IMAGE_TYPE_ID.JPEG, NAME: ADMIN_IMAGE_TYPE_STRING.JPEG}

];

export var PROMO_FOR_ID = {
    SERVICE: 20,
    DELIVERIES: 0,
    STORE: 2,
    PRODUCT: 21,
    ITEM: 22
};

// export var PROMO_FOR_ID = {
//     DELIVERIES: 0,
//     STORE: 2,
//     PRODUCT: 11,
//     ITEM: 12
// };

export var PROMO_FOR_STRING = {
    DELIVERIES: "Deliveries",
    STORE: "Store",
    PRODUCT: "Product",
    ITEM: "Item",
    SERVICE: 'Service'
};
// CONSTANT ARRAY 
export var PROMO_FOR = [
    {ID: PROMO_FOR_ID.STORE, NAME: PROMO_FOR_STRING.STORE},
    {ID: PROMO_FOR_ID.PRODUCT, NAME: PROMO_FOR_STRING.PRODUCT},
    {ID: PROMO_FOR_ID.ITEM, NAME: PROMO_FOR_STRING.ITEM}
];

export var MONTH = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

export var WEEK = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth'
]

export var DAY = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]

export var PROMO_RECURSION_ID = {
    NO_RECURSION: 0,
    DAILY_RECURSION: 1,
    WEEKLY_RECURSION: 2,
    MONTHLY_RECURSION: 3,
    ANNUALLY_RECURSION: 4
};
export var PROMO_RECURSION_STRING = {
    NO_RECURSION: "No Recursion",
    DAILY_RECURSION: "Daily Recursion",
    WEEKLY_RECURSION: "Weekly Recursion",
    MONTHLY_RECURSION: "Monthly Recursion",
    ANNUALLY_RECURSION: "Annually Recursion"
};

export var PROMO_RECURSION = [
    {ID: PROMO_RECURSION_ID.NO_RECURSION, NAME: PROMO_RECURSION_STRING.NO_RECURSION},
    {ID: PROMO_RECURSION_ID.DAILY_RECURSION, NAME: PROMO_RECURSION_STRING.DAILY_RECURSION},
    {ID: PROMO_RECURSION_ID.WEEKLY_RECURSION, NAME: PROMO_RECURSION_STRING.WEEKLY_RECURSION},
    {ID: PROMO_RECURSION_ID.MONTHLY_RECURSION, NAME: PROMO_RECURSION_STRING.MONTHLY_RECURSION},
    {ID: PROMO_RECURSION_ID.ANNUALLY_RECURSION, NAME: PROMO_RECURSION_STRING.ANNUALLY_RECURSION}
];


export var ADMIN_PROMO_FOR_ID = {
    DELIVERIES: 0,
    STORE: 2,
    SERVICE: 20
};

export var ADMIN_PROMO_FOR_STRING = {
    DELIVERIES: "Deliveries",
    STORE: "Store",
    SERVICE: "Service",
    PRODUCT: "Product",
    ITEM: "Item",

};
// CONSTANT ARRAY 
export var ADMIN_PROMO_FOR = [
    {ID: ADMIN_PROMO_FOR_ID.DELIVERIES, NAME: ADMIN_PROMO_FOR_STRING.DELIVERIES},
    {ID: ADMIN_PROMO_FOR_ID.STORE, NAME: ADMIN_PROMO_FOR_STRING.STORE},
    {ID: ADMIN_PROMO_FOR_ID.SERVICE, NAME: ADMIN_PROMO_FOR_STRING.SERVICE}
];


export var ADMIN_PROMO_FOR_DELIVERY_SERVICE_ID = {
    DELIVERY_SERVICE: 13,

};

export var ADMIN_PROMO_FOR_DELIVERY_SERVICE_STRING = {
    DELIVERY_SERVICE: "Delivery Service",

};
// CONSTANT ARRAY 
export var ADMIN_PROMO_FOR_DELIVERY_SERVICE = [
    {ID: ADMIN_PROMO_FOR_DELIVERY_SERVICE_ID.DELIVERY_SERVICE, NAME: ADMIN_PROMO_FOR_DELIVERY_SERVICE_STRING.DELIVERY_SERVICE}

];



export var ADMIN_URL_ID = {

    DASHBOARD: "/admin/dashboard",
    ORDERS: "/admin/dispatch",
    USER: "/admin/users",
    STORE: "/admin/stores",
    DELIVERYMAN: "/admin/providers",
    MAP_VIEW: "/admin/store_location",
    PAYMENT: "/admin/earnings",
    DELIVERY_INFO: "/admin/delivery",
    SETTING: "/admin/promotions",
    TEMPLATE: "/admin/email",
    DECOMPLETE: "/admin/partner_earning"
};

export var ADMIN_URL_STRING = {
    DASHBOARD: "Dashboard",
    ORDERS: "Orders",
    USER: "Users",
    STORE: "Store",
    DELIVERYMAN: "Deliveryman",
    MAP_VIEW: "Map_view",
    PAYMENT: "Payment",
    DELIVERY_INFO: "Delivery_info",
    SETTING: "Setting",
    TEMPLATE: "Template",
    DECOMPLETE: "Decompte"
};





// CONSTANT ARRAY 
export var ADMIN_URL = [
    {ID: ADMIN_URL_ID.DASHBOARD, NAME: ADMIN_URL_STRING.DASHBOARD},
    {ID: ADMIN_URL_ID.ORDERS, NAME: ADMIN_URL_STRING.ORDERS},
    {ID: ADMIN_URL_ID.USER, NAME: ADMIN_URL_STRING.USER},
    {ID: ADMIN_URL_ID.STORE, NAME: ADMIN_URL_STRING.STORE},
    {ID: ADMIN_URL_ID.DELIVERYMAN, NAME: ADMIN_URL_STRING.DELIVERYMAN},
    {ID: ADMIN_URL_ID.MAP_VIEW, NAME: ADMIN_URL_STRING.MAP_VIEW},
    {ID: ADMIN_URL_ID.PAYMENT, NAME: ADMIN_URL_STRING.PAYMENT},
    {ID: ADMIN_URL_ID.DELIVERY_INFO, NAME: ADMIN_URL_STRING.DELIVERY_INFO},
    {ID: ADMIN_URL_ID.SETTING, NAME: ADMIN_URL_STRING.SETTING},
    {ID: ADMIN_URL_ID.TEMPLATE, NAME: ADMIN_URL_STRING.TEMPLATE},
    {ID: ADMIN_URL_ID.DECOMPLETE, NAME: ADMIN_URL_STRING.DECOMPLETE}
];

export var country_list = [
    
    {
    "country_name":"Switzerland",
    "currency_code":"CHF",
    "country_phone_code":"+41"
    },
    {
    "country_name":"France",
    "currency_code":"EUR",
    "country_phone_code":"+33"
    },
    {
    "country_name":"Belgium",
    "currency_code":"EUR",
    "country_phone_code":"+32"
    },
    {
    "country_name":"Italy",
    "currency_code":"EUR",
    "country_phone_code":"+39"
    },
    {
    "country_name":"India",
    "currency_code":"INR",
    "country_phone_code":"+91"
    },
    {
    "country_name":"United Kingdom",
    "currency_code":"GBP",
    "country_phone_code":"+44"
    },
    {
    "country_name":"United States",
    "currency_code":"USD",
    "country_phone_code":"+1"
    },
    {
    "country_name":"Canada",
    "currency_code":"CAD",
    "country_phone_code":"+1"
    },
    {
    "country_name":"Germany",
    "currency_code":"EUR",
    "country_phone_code":"+49"
    },

    {
    "country_name":"Spain",
    "currency_code":"EUR",
    "country_phone_code":"+34"
    },
    {
    "country_name":"Andorra",
    "currency_code":"EUR",
    "country_phone_code":"+376"
    },
    {
    "country_name":"United Arab Emirates",
    "currency_code":"AED",
    "country_phone_code":"+971"
    },
    {
    "country_name":"Austria",
    "currency_code":"EUR",
    "country_phone_code":"+43"
    },
    {
    "country_name":"Afghanistan",
    "currency_code":"AFN",
    "country_phone_code":"+93"
    },
    {
    "country_name":"Antigua And Barbuda",
    "currency_code":"XCD",
    "country_phone_code":"+1 268"
    },
    {
    "country_name":"Anguilla",
    "currency_code":"XCD",
    "country_phone_code":"+1 264"
    },
    {
    "country_name":"Albania",
    "currency_code":"ALL",
    "country_phone_code":"+355"
    },
    {
    "country_name":"Armenia",
    "currency_code":"AMD",
    "country_phone_code":"+374"
    },
    {
    "country_name":"France, Metropolitan",
    "currency_code":"EUR",
    "country_phone_code":"+241"
    },
    {
    "country_name":"Angola",
    "currency_code":"AOA",
    "country_phone_code":"+244"
    },
    {
    "country_name":"Antarctica",
    "country_phone_code":"+672"
    },
    {
    "country_name":"Argentina",
    "currency_code":"ARS",
    "country_phone_code":"+54"
    },
    {
    "country_name":"American Samoa",
    "currency_code":"USD",
    "country_phone_code":"+1 684"
    },
    
    {
    "country_name":"Ascension Island",
    "currency_code":"USD",
    "country_phone_code":"+247"
    },
    {
    "country_name":"Australia",
    "currency_code":"AUD",
    "country_phone_code":"+61"
    },
    {
    "country_name":"Aruba",
    "currency_code":"AWG",
    "country_phone_code":"+297"
    },
    {
    "country_name":"Åland Islands",
    "currency_code":"EUR",
    "country_phone_code":"+358"
    },
    {
    "country_name":"Azerbaijan",
    "currency_code":"AZN",
    "country_phone_code":"+994"
    },
    {
    "country_name":"Bosnia & Herzegovina",
    "currency_code":"BAM",
    "country_phone_code":"+387"
    },
    {
    "country_name":"Barbados",
    "currency_code":"BBD",
    "country_phone_code":"+1 246"
    },
    {
    "country_name":"Bangladesh",
    "currency_code":"BDT",
    "country_phone_code":"+880"
    },
    
    {
    "country_name":"Burkina Faso",
    "currency_code":"XOF",
    "country_phone_code":"+226"
    },
    {
    "country_name":"Bulgaria",
    "currency_code":"BGN",
    "country_phone_code":"+359"
    },
    {
    "country_name":"Bahrain",
    "currency_code":"BHD",
    "country_phone_code":"+973"
    },
    {
    "country_name":"Burundi",
    "currency_code":"BIF",
    "country_phone_code":"+257"
    },
    {
    "country_name":"Benin",
    "currency_code":"XOF",
    "country_phone_code":"+229"
    },
    {
    "country_name":"Saint Barthélemy",
    "currency_code":"EUR",
    "country_phone_code":"+590"
    },
    {
    "country_name":"Bermuda",
    "currency_code":"BMD",
    "country_phone_code":"+1 441"
    },
    {
    "country_name":"Brunei Darussalam",
    "currency_code":"BND",
    "country_phone_code":"+673"
    },
    {
    "country_name":"Bolivia, Plurinational State Of",
    "currency_code":"BOB",
    "country_phone_code":"+591"
    },
    {
    "country_name":"Bonaire, Saint Eustatius And Saba",
    "currency_code":"USD",
    "country_phone_code":"+599"
    },
    {
    "country_name":"Brazil",
    "currency_code":"BRL",
    "country_phone_code":"+55"
    },
    {
    "country_name":"Bahamas",
    "currency_code":"BSD",
    "country_phone_code":"+1 242"
    },
    {
    "country_name":"Bhutan",
    "currency_code":"INR",
    "country_phone_code":"+975"
    },
    {
    "country_name":"Botswana",
    "currency_code":"BWP",
    "country_phone_code":"+267"
    },
    {
    "country_name":"Belarus",
    "currency_code":"BYR",
    "country_phone_code":"+375"
    },
    {
    "country_name":"Belize",
    "currency_code":"BZD",
    "country_phone_code":"+501"
    },
    
    {
    "country_name":"Cocos (Keeling) Islands",
    "currency_code":"AUD",
    "country_phone_code":"+61"
    },
    {
    "country_name":"Democratic Republic Of Congo",
    "currency_code":"CDF",
    "country_phone_code":"+243"
    },
    {
    "country_name":"Central African Republic",
    "currency_code":"XAF",
    "country_phone_code":"+236"
    },
    {
    "country_name":"Republic Of Congo",
    "currency_code":"XAF",
    "country_phone_code":"+242"
    },
    
    {
    "country_name":"Côte d'Ivoire",
    "currency_code":"XOF",
    "country_phone_code":"+225"
    },
    {
    "country_name":"Cook Islands",
    "currency_code":"NZD",
    "country_phone_code":"+682"
    },
    {
    "country_name":"Chile",
    "currency_code":"CLP",
    "country_phone_code":"+56"
    },
    {
    "country_name":"Cameroon",
    "currency_code":"XAF",
    "country_phone_code":"+237"
    },
    {
    "country_name":"China",
    "currency_code":"CNY",
    "country_phone_code":"+86"
    },
    {
    "country_name":"Colombia",
    "currency_code":"COP",
    "country_phone_code":"+57"
    },
    {
    "country_name":"Costa Rica",
    "currency_code":"CRC",
    "country_phone_code":"+506"
    },
    {
    "country_name":"Cuba",
    "currency_code":"CUP",
    "country_phone_code":"+53"
    },
    {
    "country_name":"Cabo Verde",
    "currency_code":"CVE",
    "country_phone_code":"+238"
    },
    {
    "country_name":"Curacao",
    "currency_code":"ANG",
    "country_phone_code":"+599"
    },
    {
    "country_name":"Christmas Island",
    "currency_code":"AUD",
    "country_phone_code":"+61"
    },
    {
    "country_name":"Cyprus",
    "currency_code":"EUR",
    "country_phone_code":"+357"
    },
    {
    "country_name":"Czech Republic",
    "currency_code":"CZK",
    "country_phone_code":"+420"
    },
    
    {
    "country_name":"Djibouti",
    "currency_code":"DJF",
    "country_phone_code":"+253"
    },
    {
    "country_name":"Denmark",
    "currency_code":"DKK",
    "country_phone_code":"+45"
    },
    {
    "country_name":"Dominica",
    "currency_code":"XCD",
    "country_phone_code":"+1 767"
    },
    {
    "country_name":"Dominican Republic",
    "currency_code":"DOP",
    "country_phone_code":"+1 809"
    },
    {
    "country_name":"Algeria",
    "currency_code":"DZD",
    "country_phone_code":"+213"
    },
    {
    "country_name":"Ecuador",
    "currency_code":"USD",
    "country_phone_code":"+593"
    },
    {
    "country_name":"Estonia",
    "currency_code":"EUR",
    "country_phone_code":"+372"
    },
    {
    "country_name":"Egypt",
    "currency_code":"EGP",
    "country_phone_code":"+20"
    },
    {
    "country_name":"Western Sahara",
    "currency_code":"MAD",
    "country_phone_code":"+212"
    },
    {
    "country_name":"Eritrea",
    "currency_code":"ERN",
    "country_phone_code":"+291"
    },
    
    {
    "country_name":"Ethiopia",
    "currency_code":"ETB",
    "country_phone_code":"+251"
    },
    {
    "country_name":"European Union",
    "currency_code":"EUR",
    "country_phone_code":"+388"
    },
    {
    "country_name":"Finland",
    "currency_code":"EUR",
    "country_phone_code":"+358"
    },
    {
    "country_name":"Fiji",
    "currency_code":"FJD",
    "country_phone_code":"+679"
    },
    {
    "country_name":"Falkland Islands",
    "currency_code":"FKP",
    "country_phone_code":"+500"
    },
    {
    "country_name":"Micronesia, Federated States Of",
    "currency_code":"USD",
    "country_phone_code":"+691"
    },
    {
    "country_name":"Faroe Islands",
    "currency_code":"DKK",
    "country_phone_code":"+298"
    },
    
    {
    "country_name":"Gabon",
    "currency_code":"XAF",
    "country_phone_code":"+241"
    },
    
    {
    "country_name":"Grenada",
    "currency_code":"XCD",
    "country_phone_code":"+473"
    },
    {
    "country_name":"Georgia",
    "currency_code":"GEL",
    "country_phone_code":"+995"
    },
    {
    "country_name":"French Guiana",
    "currency_code":"EUR",
    "country_phone_code":"+594"
    },
    {
    "country_name":"Guernsey",
    "currency_code":"GBP",
    "country_phone_code":"+44"
    },
    {
    "country_name":"Ghana",
    "currency_code":"GHS",
    "country_phone_code":"+233"
    },
    {
    "country_name":"Gibraltar",
    "currency_code":"GIP",
    "country_phone_code":"+350"
    },
    {
    "country_name":"Greenland",
    "currency_code":"DKK",
    "country_phone_code":"+299"
    },
    {
    "country_name":"Gambia",
    "currency_code":"GMD",
    "country_phone_code":"+220"
    },
    {
    "country_name":"Guinea",
    "currency_code":"GNF",
    "country_phone_code":"+224"
    },
    {
    "country_name":"Guadeloupe",
    "currency_code":"EUR",
    "country_phone_code":"+590"
    },
    {
    "country_name":"Equatorial Guinea",
    "currency_code":"XAF",
    "country_phone_code":"+240"
    },
    {
    "country_name":"Greece",
    "currency_code":"EUR",
    "country_phone_code":"+30"
    },
    {
    "country_name":"Guatemala",
    "currency_code":"GTQ",
    "country_phone_code":"+502"
    },
    {
    "country_name":"Guam",
    "currency_code":"USD",
    "country_phone_code":"+1 671"
    },
    {
    "country_name":"Guinea-bissau",
    "currency_code":"XOF",
    "country_phone_code":"+245"
    },
    {
    "country_name":"Guyana",
    "currency_code":"GYD",
    "country_phone_code":"+592"
    },
    {
    "country_name":"Hong Kong",
    "currency_code":"HKD",
    "country_phone_code":"+852"
    },
    {
    "country_name":"Honduras",
    "currency_code":"HNL",
    "country_phone_code":"+504"
    },
    {
    "country_name":"Croatia",
    "currency_code":"HRK",
    "country_phone_code":"+385"
    },
    {
    "country_name":"Haiti",
    "currency_code":"HTG",
    "country_phone_code":"+509"
    },
    {
    "country_name":"Hungary",
    "currency_code":"HUF",
    "country_phone_code":"+36"
    },
    {
    "country_name":"Indonesia",
    "currency_code":"IDR",
    "country_phone_code":"+62"
    },
    {
    "country_name":"Ireland",
    "currency_code":"EUR",
    "country_phone_code":"+353"
    },
    {
    "country_name":"Israel",
    "currency_code":"ILS",
    "country_phone_code":"+972"
    },
    {
    "country_name":"Isle Of Man",
    "currency_code":"GBP",
    "country_phone_code":"+44"
    },
    
    {
    "country_name":"British Indian Ocean Territory",
    "currency_code":"USD",
    "country_phone_code":"+246"
    },
    {
    "country_name":"Iraq",
    "currency_code":"IQD",
    "country_phone_code":"+964"
    },
    {
    "country_name":"Iran, Islamic Republic Of",
    "currency_code":"IRR",
    "country_phone_code":"+98"
    },
    {
    "country_name":"Iceland",
    "currency_code":"ISK",
    "country_phone_code":"+354"
    },
    
    {
    "country_name":"Jersey",
    "currency_code":"GBP",
    "country_phone_code":"+44"
    },
    {
    "country_name":"Jamaica",
    "currency_code":"JMD",
    "country_phone_code":"+1 876"
    },
    {
    "country_name":"Jordan",
    "currency_code":"JOD",
    "country_phone_code":"+962"
    },
    {
    "country_name":"Japan",
    "currency_code":"JPY",
    "country_phone_code":"+81"
    },
    {
    "country_name":"Kenya",
    "currency_code":"KES",
    "country_phone_code":"+254"
    },
    {
    "country_name":"Kyrgyzstan",
    "currency_code":"KGS",
    "country_phone_code":"+996"
    },
    {
    "country_name":"Cambodia",
    "currency_code":"KHR",
    "country_phone_code":"+855"
    },
    {
    "country_name":"Kiribati",
    "currency_code":"AUD",
    "country_phone_code":"+686"
    },
    {
    "country_name":"Comoros",
    "currency_code":"KMF",
    "country_phone_code":"+269"
    },
    {
    "country_name":"Saint Kitts And Nevis",
    "currency_code":"XCD",
    "country_phone_code":"+1 869"
    },
    {
    "country_name":"Korea, Democratic People's Republic Of",
    "currency_code":"KPW",
    "country_phone_code":"+850"
    },
    {
    "country_name":"Korea, Republic Of",
    "currency_code":"KRW",
    "country_phone_code":"+82"
    },
    {
    "country_name":"Kuwait",
    "currency_code":"KWD",
    "country_phone_code":"+965"
    },
    {
    "country_name":"Cayman Islands",
    "currency_code":"KYD",
    "country_phone_code":"+1 345"
    },
    {
    "country_name":"Kazakhstan",
    "currency_code":"KZT",
    "country_phone_code":"+7"
    },
    {
    "country_name":"Lao People's Democratic Republic",
    "currency_code":"LAK",
    "country_phone_code":"+856"
    },
    {
    "country_name":"Lebanon",
    "currency_code":"LBP",
    "country_phone_code":"+961"
    },
    {
    "country_name":"Saint Lucia",
    "currency_code":"XCD",
    "country_phone_code":"+1 758"
    },
    {
    "country_name":"Liechtenstein",
    "currency_code":"CHF",
    "country_phone_code":"+423"
    },
    {
    "country_name":"Sri Lanka",
    "currency_code":"LKR",
    "country_phone_code":"+94"
    },
    {
    "country_name":"Liberia",
    "currency_code":"LRD",
    "country_phone_code":"+231"
    },
    {
    "country_name":"Lesotho",
    "currency_code":"LSL",
    "country_phone_code":"+266"
    },
    {
    "country_name":"Lithuania",
    "currency_code":"EUR",
    "country_phone_code":"+370"
    },
    {
    "country_name":"Luxembourg",
    "currency_code":"EUR",
    "country_phone_code":"+352"
    },
    {
    "country_name":"Latvia",
    "currency_code":"EUR",
    "country_phone_code":"+371"
    },
    {
    "country_name":"Libya",
    "currency_code":"LYD",
    "country_phone_code":"+218"
    },
    {
    "country_name":"Morocco",
    "currency_code":"MAD",
    "country_phone_code":"+212"
    },
    {
    "country_name":"Monaco",
    "currency_code":"EUR",
    "country_phone_code":"+377"
    },
    {
    "country_name":"Moldova",
    "currency_code":"MDL",
    "country_phone_code":"+373"
    },
    {
    "country_name":"Montenegro",
    "currency_code":"EUR",
    "country_phone_code":"+382"
    },
    {
    "country_name":"Saint Martin",
    "currency_code":"EUR",
    "country_phone_code":"+590"
    },
    {
    "country_name":"Madagascar",
    "currency_code":"MGA",
    "country_phone_code":"+261"
    },
    {
    "country_name":"Marshall Islands",
    "currency_code":"USD",
    "country_phone_code":"+692"
    },
    {
    "country_name":"Macedonia, The Former Yugoslav Republic Of",
    "currency_code":"MKD",
    "country_phone_code":"+389"
    },
    {
    "country_name":"Mali",
    "currency_code":"XOF",
    "country_phone_code":"+223"
    },
    {
    "country_name":"Myanmar",
    "currency_code":"MMK",
    "country_phone_code":"+95"
    },
    {
    "country_name":"Mongolia",
    "currency_code":"MNT",
    "country_phone_code":"+976"
    },
    {
    "country_name":"Macao",
    "currency_code":"MOP",
    "country_phone_code":"+853"
    },
    {
    "country_name":"Northern Mariana Islands",
    "currency_code":"USD",
    "country_phone_code":"+1 670"
    },
    {
    "country_name":"Martinique",
    "currency_code":"EUR",
    "country_phone_code":"+596"
    },
    {
    "country_name":"Mauritania",
    "currency_code":"MRO",
    "country_phone_code":"+222"
    },
    {
    "country_name":"Montserrat",
    "currency_code":"XCD",
    "country_phone_code":"+1 664"
    },
    {
    "country_name":"Malta",
    "currency_code":"EUR",
    "country_phone_code":"+356"
    },
    {
    "country_name":"Mauritius",
    "currency_code":"MUR",
    "country_phone_code":"+230"
    },
    {
    "country_name":"Maldives",
    "currency_code":"MVR",
    "country_phone_code":"+960"
    },
    {
    "country_name":"Malawi",
    "currency_code":"MWK",
    "country_phone_code":"+265"
    },
    {
    "country_name":"Mexico",
    "currency_code":"MXN",
    "country_phone_code":"+52"
    },
    {
    "country_name":"Malaysia",
    "currency_code":"MYR",
    "country_phone_code":"+60"
    },
    {
    "country_name":"Mozambique",
    "currency_code":"MZN",
    "country_phone_code":"+258"
    },
    {
    "country_name":"Namibia",
    "currency_code":"NAD",
    "country_phone_code":"+264"
    },
    {
    "country_name":"New Caledonia",
    "currency_code":"XPF",
    "country_phone_code":"+687"
    },
    {
    "country_name":"Niger",
    "currency_code":"XOF",
    "country_phone_code":"+227"
    },
    {
    "country_name":"Norfolk Island",
    "currency_code":"AUD",
    "country_phone_code":"+672"
    },
    {
    "country_name":"Nigeria",
    "currency_code":"NGN",
    "country_phone_code":"+234"
    },
    {
    "country_name":"Nicaragua",
    "currency_code":"NIO",
    "country_phone_code":"+505"
    },
    {
    "country_name":"Netherlands",
    "currency_code":"EUR",
    "country_phone_code":"+31"
    },
    {
    "country_name":"Norway",
    "currency_code":"NOK",
    "country_phone_code":"+47"
    },
    {
    "country_name":"Nepal",
    "currency_code":"NPR",
    "country_phone_code":"+977"
    },
    {
    "country_name":"Nauru",
    "currency_code":"AUD",
    "country_phone_code":"+674"
    },
    {
    "country_name":"Niue",
    "currency_code":"NZD",
    "country_phone_code":"+683"
    },
    {
    "country_name":"New Zealand",
    "currency_code":"NZD",
    "country_phone_code":"+64"
    },
    {
    "country_name":"Oman",
    "currency_code":"OMR",
    "country_phone_code":"+968"
    },
    {
    "country_name":"Panama",
    "currency_code":"PAB",
    "country_phone_code":"+507"
    },
    {
    "country_name":"Peru",
    "currency_code":"PEN",
    "country_phone_code":"+51"
    },
    {
    "country_name":"French Polynesia",
    "currency_code":"XPF",
    "country_phone_code":"+689"
    },
    {
    "country_name":"Papua New Guinea",
    "currency_code":"PGK",
    "country_phone_code":"+675"
    },
    {
    "country_name":"Philippines",
    "currency_code":"PHP",
    "country_phone_code":"+63"
    },
    {
    "country_name":"Pakistan",
    "currency_code":"PKR",
    "country_phone_code":"+92"
    },
    {
    "country_name":"Poland",
    "currency_code":"PLN",
    "country_phone_code":"+48"
    },
    {
    "country_name":"Saint Pierre And Miquelon",
    "currency_code":"EUR",
    "country_phone_code":"+508"
    },
    {
    "country_name":"Pitcairn",
    "currency_code":"NZD",
    "country_phone_code":"+872"
    },
    {
    "country_name":"Puerto Rico",
    "currency_code":"USD",
    "country_phone_code":"+1 787"
    },
    {
    "country_name":"Palestinian Territory, Occupied",
    "currency_code":"JOD",
    "country_phone_code":"+970"
    },
    {
    "country_name":"Portugal",
    "currency_code":"EUR",
    "country_phone_code":"+351"
    },
    {
    "country_name":"Palau",
    "currency_code":"USD",
    "country_phone_code":"+680"
    },
    {
    "country_name":"Paraguay",
    "currency_code":"PYG",
    "country_phone_code":"+595"
    },
    {
    "country_name":"Qatar",
    "currency_code":"QAR",
    "country_phone_code":"+974"
    },
    {
    "country_name":"Reunion",
    "currency_code":"EUR",
    "country_phone_code":"+262"
    },
    {
    "country_name":"Romania",
    "currency_code":"RON",
    "country_phone_code":"+40"
    },
    {
    "country_name":"Serbia",
    "currency_code":"RSD",
    "country_phone_code":"+381"
    },
    {
    "country_name":"Russian Federation",
    "currency_code":"RUB",
    "country_phone_code":"+7"
    },
    {
    "country_name":"Rwanda",
    "currency_code":"RWF",
    "country_phone_code":"+250"
    },
    {
    "country_name":"Saudi Arabia",
    "currency_code":"SAR",
    "country_phone_code":"+966"
    },
    {
    "country_name":"Solomon Islands",
    "currency_code":"SBD",
    "country_phone_code":"+677"
    },
    {
    "country_name":"Seychelles",
    "currency_code":"SCR",
    "country_phone_code":"+248"
    },
    {
    "country_name":"Sudan",
    "currency_code":"SDG",
    "country_phone_code":"+249"
    },
    {
    "country_name":"Sweden",
    "currency_code":"SEK",
    "country_phone_code":"+46"
    },
    {
    "country_name":"Singapore",
    "currency_code":"SGD",
    "country_phone_code":"+65"
    },
    {
    "country_name":"Saint Helena, Ascension And Tristan Da Cunha",
    "currency_code":"SHP",
    "country_phone_code":"+290"
    },
    {
    "country_name":"Slovenia",
    "currency_code":"EUR",
    "country_phone_code":"+386"
    },
    {
    "country_name":"Svalbard And Jan Mayen",
    "currency_code":"NOK",
    "country_phone_code":"+47"
    },
    {
    "country_name":"Slovakia",
    "currency_code":"EUR",
    "country_phone_code":"+421"
    },
    {
    "country_name":"Sierra Leone",
    "currency_code":"SLL",
    "country_phone_code":"+232"
    },
    {
    "country_name":"San Marino",
    "currency_code":"EUR",
    "country_phone_code":"+378"
    },
    {
    "country_name":"Senegal",
    "currency_code":"XOF",
    "country_phone_code":"+221"
    },
    {
    "country_name":"Somalia",
    "currency_code":"SOS",
    "country_phone_code":"+252"
    },
    {
    "country_name":"Suriname",
    "currency_code":"SRD",
    "country_phone_code":"+597"
    },
    {
    "country_name":"South Sudan",
    "currency_code":"SSP",
    "country_phone_code":"+211"
    },
    {
    "country_name":"Sao Tome and Principe",
    "currency_code":"STD",
    "country_phone_code":"+239"
    },
    {
    "country_name":"El Salvador",
    "currency_code":"USD",
    "country_phone_code":"+503"
    },
    {
    "country_name":"Sint Maarten",
    "currency_code":"ANG",
    "country_phone_code":"+1 721"
    },
    {
    "country_name":"Syrian Arab Republic",
    "currency_code":"SYP",
    "country_phone_code":"+963"
    },
    {
    "country_name":"Swaziland",
    "currency_code":"SZL",
    "country_phone_code":"+268"
    },
    {
    "country_name":"Tristan de Cunha",
    "currency_code":"GBP",
    "country_phone_code":"+290"
    },
    {
    "country_name":"Turks And Caicos Islands",
    "currency_code":"USD",
    "country_phone_code":"+1 649"
    },
    {
    "country_name":"Chad",
    "currency_code":"XAF",
    "country_phone_code":"+235"
    },
    {
    "country_name":"Togo",
    "currency_code":"XOF",
    "country_phone_code":"+228"
    },
    {
    "country_name":"Thailand",
    "currency_code":"THB",
    "country_phone_code":"+66"
    },
    {
    "country_name":"Tajikistan",
    "currency_code":"TJS",
    "country_phone_code":"+992"
    },
    {
    "country_name":"Tokelau",
    "currency_code":"NZD",
    "country_phone_code":"+690"
    },
    {
    "country_name":"Timor-Leste, Democratic Republic of",
    "currency_code":"USD",
    "country_phone_code":"+670"
    },
    {
    "country_name":"Turkmenistan",
    "currency_code":"TMT",
    "country_phone_code":"+993"
    },
    {
    "country_name":"Tunisia",
    "currency_code":"TND",
    "country_phone_code":"+216"
    },
    {
    "country_name":"Tonga",
    "currency_code":"TOP",
    "country_phone_code":"+676"
    },
    {
    "country_name":"Turkey",
    "currency_code":"TRY",
    "country_phone_code":"+90"
    },
    {
    "country_name":"Trinidad And Tobago",
    "currency_code":"TTD",
    "country_phone_code":"+1 868"
    },
    {
    "country_name":"Tuvalu",
    "currency_code":"AUD",
    "country_phone_code":"+688"
    },
    {
    "country_name":"Taiwan",
    "currency_code":"TWD",
    "country_phone_code":"+886"
    },
    {
    "country_name":"Tanzania, United Republic Of",
    "currency_code":"TZS",
    "country_phone_code":"+255"
    },
    {
    "country_name":"Ukraine",
    "currency_code":"UAH",
    "country_phone_code":"+380"
    },
    {
    "country_name":"Uganda",
    "currency_code":"UGX",
    "country_phone_code":"+256"
    },
    {
    "country_name":"United States Minor Outlying Islands",
    "currency_code":"USD",
    "country_phone_code":"+1"
    },
    
    {
    "country_name":"Uruguay",
    "currency_code":"UYU",
    "country_phone_code":"+598"
    },
    {
    "country_name":"Uzbekistan",
    "currency_code":"UZS",
    "country_phone_code":"+998"
    },
    {
    "country_name":"Vatican City State",
    "currency_code":"EUR",
    "country_phone_code":"+379"
    },
    {
    "country_name":"Saint Vincent And The Grenadines",
    "currency_code":"XCD",
    "country_phone_code":"+1 784"
    },
    {
    "country_name":"Venezuela, Bolivarian Republic Of",
    "currency_code":"VEF",
    "country_phone_code":"+58"
    },
    {
    "country_name":"Virgin Islands (British)",
    "currency_code":"USD",
    "country_phone_code":"+1 284"
    },
    {
    "country_name":"Virgin Islands (US)",
    "currency_code":"USD",
    "country_phone_code":"+1 340"
    },
    {
    "country_name":"Viet Nam",
    "currency_code":"VND",
    "country_phone_code":"+84"
    },
    {
    "country_name":"Vanuatu",
    "currency_code":"VUV",
    "country_phone_code":"+678"
    },
    {
    "country_name":"Wallis And Futuna",
    "currency_code":"XPF",
    "country_phone_code":"+681"
    },
    {
    "country_name":"Samoa",
    "currency_code":"WST",
    "country_phone_code":"+685"
    },
    {
    "country_name":"Kosovo",
    "currency_code":"EUR",
    "country_phone_code":"+383"
    },
    {
    "country_name":"Yemen",
    "currency_code":"YER",
    "country_phone_code":"+967"
    },
    {
    "country_name":"Mayotte",
    "currency_code":"EUR",
    "country_phone_code":"+262"
    },
    {
    "country_name":"South Africa",
    "currency_code":"ZAR",
    "country_phone_code":"+27"
    },
    {
    "country_name":"Zambia",
    "currency_code":"ZMW",
    "country_phone_code":"+260"
    },
    {
    "country_name":"Zimbabwe",
    "currency_code":"USD",
    "country_phone_code":"+263"
    }
]