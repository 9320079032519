import { Component, OnInit } from '@angular/core';
import { CartService } from '../shared/cart.service';
import { Helper } from '../shared/helper';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  providers: [CartService, Helper]
})
export class ProfileMenuComponent implements OnInit {

  constructor(public helper: Helper, public cart_service:CartService) { }

  ngOnInit(): void {
  }

}
