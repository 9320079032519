import { Component, OnInit, ViewEncapsulation, TemplateRef, HostListener, OnDestroy } from '@angular/core';
import { Helper } from '../shared/helper';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Params} from '@angular/router';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./order-detail.component.scss'],
  providers: [Helper]
})
export class OrderDetailComponent implements OnInit, OnDestroy {

  mod_ref:any;
  constructor(public helper: Helper, private modalService: NgbModal) { }

  order_id: string = '';
  interval:any;
  user_id: string = '';
  server_token: string = '';
  order_detail: any = {};
  cancel_reason:string = '';

  @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
      if(this.mod_ref){
        this.mod_ref.close();
      }
    }

  openModal(template: TemplateRef<any>) {
    this.mod_ref = this.modalService.open(template, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'cancel-order-popup popup-mid', });
    this.cancel_reason = this.helper.text.cancel_list1;
  }

  ngOnInit(): void {

    if(localStorage['user']){
      let user = JSON.parse(localStorage['user']);
      if(user && user._id){
        this.user_id = user._id;
        this.server_token = user.server_token;
        this.helper.route.params.subscribe((x: Params) => {
          if(x['order_id']){
            this.order_id = x['order_id'];
            this.get_order_detail(true);
            this.interval = setInterval(()=>{
              // this.get_order_detail(false);
            },5000);
          } else {
            this.helper.router.navigate(['']);
          }
        });

      }
    } else {
      this.helper.router.navigate(['']);
    }  
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  openModal1(template: TemplateRef<any>) {
    this.mod_ref = this.modalService.open(template, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'edit-cart-popup o-d-e-cart-popup popup-mid product-item-popup mob-full-popup', });
  }

  async get_order_detail(is_loading:boolean){
      var json = {
        order_id: this.order_id,
        user_id: this.user_id,
        server_token: this.server_token
      }
      var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_ORDER_DETAIL, json, is_loading, true, false, false);

      if(response_data){
        this.order_detail = response_data.order;
      } else {
        this.helper.router.navigate(['']);
      }
  }

  check_button(item_id:string){
    var div:any = document.getElementById(item_id);
    if(div){
      if(div.scrollHeight>75){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async cancel_order(){
    if(this.cancel_reason){
      let json = {
          user_id: this.user_id,
          server_token: this.server_token,
          order_id: this.order_id,
          order_status: this.order_detail.order_status,
          cancel_reason: this.cancel_reason
      }

      var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.USER_CANCEL_ORDER, json, true, true, true, true)
          if (res_data.success) {
            this.mod_ref.close();
            this.get_order_detail(true)
          } else {
          }
    }
  }

}
