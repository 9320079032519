import { Component, NgZone, OnInit, ElementRef, ViewChild, Injectable } from '@angular/core';
import { CommonData } from './common_data';
import { Helper } from './helper';
import { BehaviorSubject } from "rxjs";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";
import { CartService } from '../shared/cart.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

export interface UserLogin {
    email: string,
    social_id: string,
    login_by: any,
    cart_unique_token: any,
    password: string
}
export interface UserRegister{
    first_name: String,
    last_name: String,
    email: string,
    password: String,
    social_id: string,
    login_by:string,
    confirm_password: String,
    country_id: string,
    city: Object,
    address: String,
    country_phone_code: string,
    cart_unique_token: any,
    phone: any,
    image_url: String,
    referral_code:String,
    is_email_verified:Boolean,
    is_phone_number_verified:Boolean,
    terms: any,
    currency_code: string,
    is_whop: boolean,
    website_id: string
}
export interface UserForgotPassword{
    email: String
}

@Injectable()
export class LoginService {

	user_id: string = '';
  	server_token: string = '';
  	signin_type: number = 1;
  	public user_login: UserLogin = {
      cart_unique_token: localStorage.getItem('cart_unique_token'),
      email: '',
      password: '',
      login_by: this.helper.title.manual,
      social_id: ''
  	};
  	public user_register: UserRegister = {
      cart_unique_token: localStorage.getItem('cart_unique_token'),
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      social_id: "",
      login_by: this.helper.title.manual,
      confirm_password: "",
      country_id: "",
      city: "",
      address: "",
      country_phone_code: "",
      phone: null,
      image_url: "./default.png",
      referral_code: "",
      is_phone_number_verified: false,
      is_email_verified : false,
      terms: false,
      currency_code: '',
      is_whop: true, 
      website_id: this.helper.website_data.detail.store_id
  	};
  	public user_forgot_password: UserForgotPassword = {
      email: "",
  	};
  	email_placeholder:Number = 1;
  	email_or_phone_error: Boolean = false;
  	country_list: any[] = [];
  	error_message: string = '';
  	session_subscriber:any;
  	modal_ref:any;
  	modal_ref1:any;
  	password_type:string='password';
    error_code:any;
  is_show_country:boolean=false;

	constructor(private modalService: NgbModal, private authService: SocialAuthService, public cart_service: CartService, public common_data: CommonData, public helper: Helper){

    }

    change_password_type(){
    	if(this.password_type=='password'){
      		this.password_type = 'text'
    	} else {
      		this.password_type = 'password'
    	}
  	}

    select_country(country:any){
      this.user_register.currency_code = country.currency_code;
      this.user_register.country_phone_code = country.country_phone_code;
      this.user_register.country_id = country.country_name;
      this.is_show_country = false;
    }

  userLogin(logindata:any)
  {
      this.user_login.social_id = '';
      this.user_login.login_by = this.helper.title.manual;
      this.user_login.email=this.user_login.email.trim();
      logindata.email=logindata.email.trim();
      if(this.email_placeholder == 1)
      {
          var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          if(!isNaN(logindata.email) || reg.test(logindata.email))
          {
              this.email_or_phone_error=false;
              this.Login()
          }
          else
          {
              this.email_or_phone_error=true;
          }
      }
      else
      {
          this.email_or_phone_error=false;
          this.Login()
      }
  }

  async Login(){
      var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.LOGIN, this.user_login, true, true, true, true);
      if (response_data.success) {
          localStorage.setItem('user', JSON.stringify(response_data.user));
          this.helper.fire_observable()
          this.cart_service.get_cart()
          if(this.modal_ref){
            this.modal_ref.close();
          }
          this.error_code = '';
      } else {
        this.error_code = response_data.error_code;
        this.error_message = this.helper.ERROR_CODE[response_data.error_code];
      }
  }

  signInWithGoogle(): void {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user:any)=>{
          if(user){
              if(this.signin_type == 1){
                  this.user_login = {
                      cart_unique_token: localStorage.getItem('cart_unique_token'),
                      email: '',
                      password: '',
                      login_by: user.provider.toLowerCase(),
                      social_id: user.id
                  }
                  this.Login();
              } else {
                  this.user_register.social_id = user.id;
                  this.user_register.first_name = user.firstName;
                  this.user_register.last_name = user.lastName;
                  this.user_register.email = user.email;
                  this.user_register.login_by = user.provider.toLowerCase();

                  // document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
              }
          }
      },(error)=>{
        console.log(error)
      });
    }

    signInWithFB(): void {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user:any)=>{
          if(user){
              if(this.signin_type == 1){
                  this.user_login = {
                      cart_unique_token: localStorage.getItem('cart_unique_token'),
                      email: '',
                      password: '',
                      login_by: user.provider.toLowerCase(),
                      social_id: user.id
                  }
                  this.Login();
              } else {
                  this.user_register.social_id = user.id;
                  this.user_register.first_name = user.firstName;
                  this.user_register.last_name = user.lastName;
                  this.user_register.email = user.email;
                  this.user_register.login_by = user.provider.toLowerCase();
                  // document.querySelector(".modal").scrollTo({top:0,behavior:'smooth'});
              }
          }
      });
    }

    signInWithApple() {
        var YOUR_CLIENT_ID = 'com.hop.orderingapp';
        var YOUR_REDIRECT_ID = '';
        var windowWidth = 400;
        var windowHeight = 500;
        var left = (screen.width - windowWidth) / 2;
        var top = (screen.height - windowHeight) / 4;


        var new_window:any = window.open(
          'https://appleid.apple.com/auth/authorize?' +
            `client_id=${YOUR_CLIENT_ID}&` +
            `redirect_uri=${encodeURIComponent(YOUR_REDIRECT_ID)}&` +
            'response_type=code id_token&' +
            'scope=name email&' +
            'response_mode=form_post',
          'popUpWindow', 'height='+windowHeight+',width='+windowWidth+',left='+left+',top='+top+',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
        );
        new_window.onbeforeunload = function(){ 
            console.log('popup close')
        }
    }

    async userForgotPassword(forgotpassworddata:any, SendNewPassword:any)
    {
        var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.FORGOT_PASSWORD,{email:forgotpassworddata.email.trim(), type:7, is_whop: true, website_id: this.helper.website_data.detail.store_id}, true, true, true, true);
        if(response_data.success == false)
        {
          this.error_code = response_data.error_code;
        }
        else
        {
          if(this.modal_ref1){
          	this.modal_ref1.close();
          }
          this.error_message = '';
          this.modalService.open(SendNewPassword, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'send-new-pass moa-popup popup-mid', });

        }
    }

    public formData = new FormData();
    async userRegister(userdata:any, AlreadyAccount:any, NewUserSignUp:any)
    {
      
        var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.REGISTER, this.user_register, true, true, true, true)
        if(response_data.success == false)
        {
          this.error_code = response_data.error_code
          this.error_message = this.helper.ERROR_CODE[response_data.error_code];
          this.formData = new FormData();
          if(this.user_register.login_by == this.helper.title.social){
              this.user_register.password = '123456';
              this.user_register.confirm_password='123456';
          }
          if(this.error_code==this.helper.ERROR_CODE.EMAIL_ALREADY){
            if(this.modal_ref){
              this.modal_ref.close();
            }
            this.modal_ref = this.modalService.open(AlreadyAccount, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'user-already-account-popup new-user-signup-popup popup-mid', });
          }
        }
        else
        {
          this.error_code = '';
          this.error_message = '';
          localStorage.setItem('user', JSON.stringify(response_data.user));
          this.helper.fire_observable()
          this.cart_service.get_cart()
          if(this.modal_ref){
            this.modal_ref.close();
          }
          this.modalService.open(NewUserSignUp, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'new-user-signup-popup popup-mid', });
        }
    }

    go_to_login(template:any){
      this.modal_ref.close();
      if(template){
        this.modal_ref = this.modalService.open(template, { centered: true, size: 'lg', modalDialogClass: 'account-popup popup-mid mob-full-popup', });
      }
      this.signin_type = 1;
      this.user_login.email = this.user_register.email;
    }

    go_to_forgot_password(SendNewPassword:any){
      this.modal_ref.close();
      this.signin_type = 1;
      this.user_forgot_password.email = this.user_register.email;
      this.userForgotPassword({email: this.user_forgot_password.email}, SendNewPassword)
    }
}