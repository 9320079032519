import { Component, OnInit } from '@angular/core';
import { Helper } from '../shared/helper';

@Component({
  selector: 'app-account-order',
  templateUrl: './account-order.component.html',
  styleUrls: ['./account-order.component.scss'],
  providers: [Helper],
})
export class AccountOrderComponent implements OnInit {

  active = 1;
  private user_id: string = '';
  private server_token: string = '';
  current_order_list: any[] = [];
  order_history: any[] = [];

  constructor(public helper: Helper) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage['user']);
      if(user && user._id){
          this.user_id = user._id;
          this.server_token = user.server_token;
      } else {
        this.helper.router.navigate([''])
      }
      this.get_current_order_list();
      this.history();
  }

  async get_current_order_list(){

        let json = {
            user_id: this.user_id, 
            server_token: this.server_token,
            store_id: this.helper.website_data.detail.store_id,
            is_hopper_on_demand: false
        }
        var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_ORDERS, json, true, true, false, false);

            if(response_data.success){
                this.current_order_list = response_data.order_list;
                this.current_order_list.sort(this.sortOrder);
            } else {
                this.current_order_list = [];
            }
    }

    sortOrder(a:any, b:any) {
        if (a.unique_id < b.unique_id)
            return 1;
        if (a.unique_id > b.unique_id)
            return -1;
        return 0;
    }

    async history(){

        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            store_id: this.helper.website_data.detail.store_id,
            is_hopper_on_demand: false
        }
        var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.ORDER_HISTORY, json, true, true, false, false);

            if(response_data.success){
                this.order_history = response_data.order_list;
                this.order_history.sort(this.sortOrder);
            } else {
                this.order_history = [];
            }
    }

    go_to_order_detail(order_id: string){
        this.helper.router.navigate(['/order-detail/'+order_id])
    }

}
