import { Component, OnInit } from '@angular/core';
import { Helper } from '../shared/helper';
declare var google:any;

export interface AddAddress {
    latitude: any,
    longitude: any,
    address: string,
    note: string,
    address_name: string,
    floor: string,
    entry_code: string,
    country: string,
    city: string,
    state: string,
    postal_code: string,
    country_code: string
}

@Component({
  selector: 'app-account-address',
  templateUrl: './account-address.component.html',
  styleUrls: ['./account-address.component.scss'],
  providers: [Helper],
})
export class AccountAddressComponent implements OnInit {

  favourite_addresses: any[] = [];
  private user_id: string = '';
  private server_token: string = '';
  address_ids : any[] =[];
  is_edit: boolean = false;
  add_address: AddAddress = {
      address: '',
      address_name: '',
      note: '',
      country: '',
      city: '',
      state: '',
      postal_code: '',
      country_code: '',
      floor: '',
      entry_code: '',
      latitude: null,
      longitude: null
  };
  is_add: boolean = false;

  constructor(public helper: Helper) { }

  async ngOnInit(){

    let autocompleteElm = <HTMLInputElement>document.getElementById('fav_address');
    let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {});

    autocomplete.addListener('place_changed', () => {
        var place = autocomplete.getPlace();
        this.add_address.latitude = place.geometry.location.lat()
        this.add_address.longitude = place.geometry.location.lng();
        this.add_address.address = place.formatted_address;
        for (var i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types[0] == "country") {
                this.add_address.country = place.address_components[i].long_name;
                this.add_address.country_code = place.address_components[i].short_name;
            }else if (place.address_components[i].types[0] == "locality") {
              this.add_address.city = place.address_components[i].long_name;
            }else if (place.address_components[i].types[0] == "administrative_area_level_1") {
              this.add_address.state = place.address_components[i].long_name;
            } else if (place.address_components[i].types[0] == "postal_code") {
                this.add_address.postal_code = place.address_components[i].long_name;
            }
        }
    });

    let user = JSON.parse(localStorage['user']);
    if(user && user._id){
        this.user_id = user._id;
        this.server_token = user.server_token;
    }
    var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_FAVOUTIRE_ADDRESSES, {user_id: this.user_id, server_token: this.server_token}, true, true, true, true)
      if(res_data.success){
          this.favourite_addresses = res_data.favourite_addresses;
      }
  }

  check_address(address_id:string){
      var index = this.address_ids.indexOf(address_id);
      if(index == -1){
          this.address_ids.push(address_id);
      } else {
          this.address_ids.splice(index, 1);
      }
  }

  async delete_address(){
      var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.DELETE_FAVOURITE_ADDRESS, {address_ids: this.address_ids, user_id: this.user_id, server_token: this.server_token}, true, true, true, true)
        this.favourite_addresses = res_data.favourite_addresses;
        this.address_ids = [];
        this.is_edit = false;
  }

  async add_address_data(data:any){
      data.user_id = this.user_id;
      data.server_token = this.server_token;
      var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_FAVOURITE_ADDRESS, data, true, true, true, true)
        this.favourite_addresses = res_data.favourite_addresses;
        this.is_add = false;
        this.add_address = {
            address: '',
            address_name: '',
            note: '',
            country: '',
            city: '',
            state: '',
            postal_code: '',
            country_code: '',
            floor: '',
            entry_code: '',
            latitude: null,
            longitude: null
        }
  }

}
