<div class="pro-cont-title d-flex align-items-center">
    <h5>{{helper.menu_title.adresses | translate}}</h5>
    <div class="modi-btn ml-auto">
        <a (click)="is_edit=true" *ngIf="!is_edit && !is_add">{{helper.button.modifier | translate}}</a>
        <button type="button" (click)="is_edit=false;is_add=false" *ngIf="is_edit || is_add" aria-label="Close" class="close pull-right "><span aria-hidden="true">×</span></button>
    </div>
</div>
<div class="pro-cont-detail">
     <div class="form-no-input" *ngIf="!is_add">
        <div class="form-block" [class.modifier]="is_edit" *ngFor="let address of favourite_addresses;">
             <label for="">{{address.address_name}}</label>
             <div type="text" class="form-control d-flex align-items-center">
                 <span>{{address.address}}</span>
             </div>
             <div class="checkbox-block" *ngIf="is_edit">
                <label class="check_lb check-square">
                    <input type="checkbox" [checked]="address_ids.indexOf(address._id) !== -1" (change)="check_address(address._id)">
                    <span class="checkmark"></span>
                </label>
             </div>                        
        </div>
    </div>
    <div class="address-form" [hidden]="!is_add">
         <form #addAddressForm="ngForm" (ngSubmit)="addAddressForm.valid && add_address_data(addAddressForm.value)">
            <input type="hidden" name="latitude" [(ngModel)]="add_address.latitude" #latitude="ngModel" required>
            <input type="hidden" name="longitude" [(ngModel)]="add_address.longitude" #longitude="ngModel" required>
            <input type="hidden" name="country" [(ngModel)]="add_address.country" #country="ngModel" >
            <input type="hidden" name="country_code" [(ngModel)]="add_address.country_code" #country_code="ngModel" >
             <div class="row">
                 <div class="col-12">
                     <div class="form-block">
                        <input type="text" class="form-control" placeholder="{{helper.placeholder.address_name | translate}}" name="address_name" [(ngModel)]="add_address.address_name" #address_name="ngModel" required>
                     </div>
                 </div>
                 <div class="col-12">
                    <div class="form-block">
                       <input type="text" class="form-control" id="fav_address" placeholder="{{helper.placeholder.address | translate}}" name="address" [(ngModel)]="add_address.address" #address="ngModel" required>
                    </div>
                </div>
             </div>
             <div class="row">
                 <div class="col-6">
                    <div class="form-block">
                        <input type="text" class="form-control" placeholder="{{helper.placeholder.etage | translate}}" name="floor" [(ngModel)]="add_address.floor" #floor="ngModel">
                     </div>
                 </div>
                 <div class="col-6">
                    <div class="form-block">
                        <input type="text" class="form-control" placeholder="{{helper.placeholder.code_entree | translate}}" name="entry_code" [(ngModel)]="add_address.entry_code" #entry_code="ngModel">
                     </div>
                 </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-block">
                       <input type="text" class="form-control" placeholder="{{helper.placeholder.commentaire | translate}}" name="note" [(ngModel)]="add_address.note" #note="ngModel">
                    </div>
                </div>
            </div>
            <div class="add-address-btn d-flex justify-content-center m-t-30">
                <button type="submit" class="primary-btn">{{helper.button.save | translate}}</button>
             </div>
         </form>
     </div>
     <div class="add-address-btn d-flex justify-content-center m-t-30" *ngIf="!is_edit && !is_add" (click)="is_add=true;">
        <button class="primary-btn">{{helper.button.ajouter_une_adresse | translate}}</button>
     </div>
     <div class="add-address-btn d-flex justify-content-center m-t-30" *ngIf="is_edit && !is_add && address_ids.length>0" (click)="delete_address()">
        <button class="primary-btn red">{{helper.button.supprimer_la_selection | translate}}</button>
     </div>
     
</div>