import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { WebsiteData } from './shared/website_data';
import { CommonData } from './shared/common_data';
import { ClickOutsideDirective } from './shared/clickout.directive'

import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ShopComponent } from './shop/shop.component';
import { FormsModule } from '@angular/forms';
import { LoginRegisterModalComponent } from './login-register-modal/login-register-modal.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { CheckoutComponent } from './checkout/checkout.component';
import { HeaderComponent } from './header/header.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { AccountAddressComponent } from './account-address/account-address.component';
import { AccountPaymentComponent } from './account-payment/account-payment.component';
import { AccountOrderComponent } from './account-order/account-order.component';
import { ProfileCommonComponent } from './profile-common/profile-common.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ThirdPartyOrderDetailComponent } from './third-party-order-detail/third-party-order-detail.component';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeliveryErrorModalComponent } from './delivery-error-modal/delivery-error-modal.component';
import { SearchPipe } from './shared/search.pipe';
import { OfflineMenuComponent } from './offline-menu/offline-menu.component'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchPipe,
    ShopComponent,
    LoginRegisterModalComponent,
    CheckoutComponent,
    ClickOutsideDirective,
    HeaderComponent,
    ProfileMenuComponent,
    AccountProfileComponent,
    AccountAddressComponent,
    AccountPaymentComponent,
    AccountOrderComponent,
    ProfileCommonComponent,
    OrderDetailComponent,
    ThirdPartyOrderDetailComponent,
    OrderTrackingComponent,
    DeliveryErrorModalComponent,
    OfflineMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    SocialLoginModule,
    HttpClientModule,
    PdfViewerModule,
    LazyLoadImageModule,
    TranslateModule.forRoot({
			loader: {
			  provide: TranslateLoader,
			  useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
			  deps: [HttpClient]
			}
		}),
    ToastrModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [TranslateService, WebsiteData, CommonData,
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '94334734421-s9r1bvm2lm98ua61qqf0sls6e4kmej62.apps.googleusercontent.com'
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider('658120322070559')
            }
          ]
        } as SocialAuthServiceConfig,
      }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
