import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Helper } from '../shared/helper';
import { CartService } from '../shared/cart.service';
import { LoginService } from '../shared/login.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialAuthService } from "angularx-social-login";

@Component({
  selector: 'app-login-register-modal',
  templateUrl: './login-register-modal.component.html',
  styleUrls: ['./login-register-modal.component.scss'],
  providers: [Helper, LoginService]
})
export class LoginRegisterModalComponent implements OnInit {

  constructor(private authService: SocialAuthService, public login_service: LoginService, public cart_service: CartService, public helper: Helper, private modalService: NgbModal) { }

  @ViewChild('template') template!: TemplateRef<any>;

  user_id: string = '';
  server_token: string = '';
  session_subscriber:any;
  @Input() login_header_text = '';
  @Input() is_register_button = false;
  @Input() is_second_text = false;

  openModal1(template: TemplateRef<any>) {
    if(this.login_service.modal_ref){
      this.login_service.modal_ref.close();
    }
    this.login_service.modal_ref1 = this.modalService.open(template, { centered: true, size: 'lg', modalDialogClass: 'forgot-popup', });
  }

  openModal() {
    this.login_service.signin_type=1;
    this.login_service.user_login = {
        cart_unique_token: localStorage.getItem('cart_unique_token'),
        email: '',
        password: '',
        login_by: this.helper.title.manual,
        social_id: ''
    }
    this.login_service.user_forgot_password={
        email: "",
    }
    this.login_service.error_message = '';
    this.login_service.user_register ={
        cart_unique_token: localStorage.getItem('cart_unique_token'),
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        social_id: "",
        login_by: this.helper.title.manual,
        confirm_password: "",
        country_id: "",
        city: "",
        address: "",
        country_phone_code: "",
        phone: null,
        image_url: "./default.png",
        referral_code: "",
        is_phone_number_verified: false,
        terms: false,
        is_email_verified : false,
        currency_code: '',
          is_whop: true,
          website_id: this.helper.website_data.detail.store_id
    }
    this.login_service.country_list = this.helper.country_list;
    if(this.login_service.country_list.length>0){
      this.login_service.user_register.currency_code = 'CHF';
      this.login_service.user_register.country_phone_code = '+41';
      this.login_service.user_register.country_id = 'Switzerland';
      if(this.helper.website_data.detail && this.helper.website_data.detail.country_phone_code){
        let index = this.login_service.country_list.findIndex((x)=>x.country_phone_code==this.helper.website_data.detail.country_phone_code);
        if(index!==-1){
          this.login_service.user_register.currency_code = this.login_service.country_list[index].currency_code;
          this.login_service.user_register.country_phone_code = this.login_service.country_list[index].country_phone_code;
          this.login_service.user_register.country_id = this.login_service.country_list[index].country_name;
        }
      }
    }
    this.login_service.modal_ref = this.modalService.open(this.template, { centered: true, size: 'lg', modalDialogClass: 'account-popup popup-mid mob-full-popup', });
  }

  

  ngOnDestroy(){
    if(this.session_subscriber){
      this.session_subscriber.unsubscribe();
    }
  }

  ngOnInit(): void {
    // this.check_detail();
    if(!localStorage.getItem('cart_unique_token')){
      localStorage.setItem('cart_unique_token', this.helper.generatorRandomChar(32));
    }
    this.session_subscriber = this.helper.common_data.session_observable.subscribe((data)=>{
      this.check_detail();
    });
  }

  check_detail(){
    if(localStorage['user']){
      let user = JSON.parse(localStorage['user']);
      if(user && user._id){
          this.user_id = user._id;
          this.server_token = user.server_token;
      }
      // this.cart_service.get_cart()
    } else {
      this.user_id = '';
      this.server_token = '';
    }
  }

  
}
