<form #userProfileForm="ngForm" (ngSubmit)="userProfileForm.valid && user_update(userProfileForm.value)">
    <div class="pro-cont-title d-flex align-items-center">
        <h5>{{helper.menu_title.profil | translate}}</h5>
        <div class="modi-btn ml-auto">
            <a *ngIf="!edit" (click)="edit=true">{{helper.button.modifier | translate}}</a>
            <a *ngIf="edit">
                <button class="profile_button" [disabled]="userProfileForm.invalid" type="submit">{{helper.button.sauvegarder | translate}}</button>
            </a>
        </div>
    </div>
    <div class="pro-cont-detail">
         
             <div class="form-block" [class.modifier]="edit">
                 <label for="" class="form-label">{{helper.label.prenom2 | translate}}</label>
                 <input type="text" class="form-control"  name="first_name" [disabled]="!edit" placeholder="{{helper.label.nom | translate}}" [(ngModel)]="user_profile.first_name" #first_name="ngModel" required>
             </div>
             <div class="form-block" [class.modifier]="edit">
                <label for="" class="form-label">{{helper.label.nom2 | translate}}</label>
                <input type="text" class="form-control" name="last_name" [disabled]="!edit" placeholder="{{helper.label.prenom | translate}}" [(ngModel)]="user_profile.last_name" #last_name="ngModel" required>
            </div>
            <div class="form-block" [class.modifier]="edit">
                <label for="" class="form-label">{{helper.label.adresse_email2 | translate}}</label>
                <input type="email"  placeholder="{{helper.label.adresse_email | translate}}" pattern="\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*" class="form-control" name="email" [disabled]="!edit" [(ngModel)]="user_profile.email" #email="ngModel" required email>
            </div>
            <div class="form-block" [class.modifier]="edit">
                <label for="" class="form-label">{{helper.label.nouveau_mot_de_passe | translate}}</label>
                <input type="password" class="form-control" placeholder="{{helper.title.new_password | translate}}" name="new_password" [disabled]="!edit" [(ngModel)]="user_profile.new_password" minlength="6" #new_password="ngModel">
            </div>
            <div class="form-block" [class.modifier]="edit">
                <label for="" class="form-label">{{helper.label.numero_de_telephone2 | translate}}</label>
                <input type="text"  placeholder="{{helper.label.numero_de_telephone | translate}}" class="form-control" name="phone" [disabled]="!edit" [(ngModel)]="user_profile.phone" #phone="ngModel" required>
            </div>
         
    </div>
</form>