import { Component, OnInit, OnDestroy } from '@angular/core';
import { Helper } from '../shared/helper';
import {Params} from '@angular/router';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss'],
  providers: [Helper]
})
export class OrderTrackingComponent implements OnInit, OnDestroy {

  	constructor(public helper: Helper) { }

  	order_id: string = '';
  	interval:any;
  	user_id: string = '';
  	server_token: string = '';
  	order_detail: any = {};

  	ngOnInit(): void {
	    if(localStorage['user']){
	      let user = JSON.parse(localStorage['user']);
	      if(user && user._id){
	        this.user_id = user._id;
	        this.server_token = user.server_token;
	        this.helper.route.params.subscribe((x: Params) => {
	          if(x['order_id']){
	            this.order_id = x['order_id'];
	            this.get_order_detail(true);
	            this.interval = setInterval(()=>{
	              // this.get_order_detail(false);
	            },5000);
	          } else {
	            this.helper.router.navigate(['']);
	          }
	        });

	      }
	    } else {
	      this.helper.router.navigate(['']);
	    }  
	}

	ngOnDestroy(){
  	clearInterval(this.interval);
	}


	async get_order_detail(is_loading:boolean){
	    var json = {
	      order_id: this.order_id,
	      user_id: this.user_id,
	      server_token: this.server_token
	    }
	    var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_ORDER_DETAIL, json, is_loading, true, false, false);

	    if(response_data){
	      this.order_detail = response_data.order;
	    } else {
	      this.helper.router.navigate(['']);
	    }
	}




}
