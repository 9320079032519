<div class="page-content">
	<div class="page-content-wrp" *ngIf="helper.website_data.detail.menu_url">
			<div *ngIf="helper.website_data.detail.menu_url_type==='application/pdf'">
				<pdf-viewer [src]="helper.image_base_url + helper.website_data.detail.menu_url" 
		              [render-text]="true"
		              [original-size]="true"
		              [fit-to-page]="true"
		              style="display: block;width: 100%; height: 100vh;padding:50px 0;"
		  		></pdf-viewer>
			</div>
			<div *ngIf="helper.website_data.detail.menu_url_type!=='application/pdf'">
				<img style="width: 100%;padding: 100px 0;" src="{{helper.image_base_url + helper.website_data.detail.menu_url}}" alt="">
			</div>
	</div>
</div>