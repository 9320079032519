<div class="pro-cont-title d-flex align-items-center">
    <h5>{{helper.menu_title.paiements | translate}}</h5>
</div>
<div class="pro-cont-detail">
     <div class="form-no-input">
         <h5>{{helper.title.carte_de_credit_selectionnee | translate}}</h5>
         <div class="form-block modifier" *ngFor="let card of card_list; let card_index=index;" (click)="select_card(card._id, card_index)">
             <!-- <input type="text" class="form-control" placeholder="XXXX XXXX XXXX 4242 visa"> -->
             <div type="text" class="form-control d-flex align-items-center">
                <span>XXXX XXXX XXXX {{card.last_four}} {{card.card_type}}</span>
            </div>
             <div class="checkbox-block right-icons modi-btn d-flex align-items-center payment-page">
                <button type="button" aria-label="Close" *ngIf="card.is_default" class="correct close"><span aria-hidden="true"></span></button>
                <button type="button" aria-label="Close" (click)="delete_card(card._id, card_index)" class="close"><span aria-hidden="true">×</span></button>  
             </div>                      
         </div>
         <h5 class="d-flex align-items-center">
             {{helper.title.credit_hop_disponible | translate}}
            <a href="#" class="ml-auto">{{wallet.toFixed(2)}} {{wallet_currency_code}}</a>
         </h5>
         
    </div>
    

     <div class="add-address-btn d-flex justify-content-center m-t-30">
        <button class="primary-btn" (click)="add_new_card(AddNewCard)">{{helper.button.ajouter_une_carte_de_credit | translate}}</button>
     </div>

    <div class="pro-cont-title add-map d-flex align-items-center" *ngIf="show_add_card">
        <h5 class="m-b-0">{{helper.title.ajouter_une_carte | translate}}</h5>
        <div class="modi-btn ml-auto">
            <!-- <a href="#" class="">{{helper.button.modifier | translate}}</a> -->
            <button (click)="show_add_card=false" type="button" aria-label="Close" class="close pull-right"><span aria-hidden="true">×</span></button>
        </div>
    </div>
</div>

<ng-template #AddNewCard let-modal>

    <div class="modal-main">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.641964 4.44674C0.27079 4.07556 0.0852035 3.64252 0.0852036 3.14762C0.0852039 2.65272 0.270791 2.21969 0.641965 1.84851C1.01314 1.47734 1.43844 1.29948 1.91788 1.31495C2.39731 1.33042 2.82261 1.52374 3.19379 1.89491L12.6355 11.3367C13.0067 11.7078 13.1923 12.1409 13.1923 12.6358C13.2078 13.1152 13.0299 13.5405 12.6587 13.9117C12.2876 14.2829 11.8545 14.4684 11.3596 14.4684C10.8802 14.453 10.4549 14.2597 10.0837 13.8885L0.641964 4.44674ZM0.804354 13.6565C0.371317 13.2235 0.147065 12.7208 0.1316 12.1486C0.1316 11.5918 0.348119 11.0969 0.781156 10.6639L9.82853 1.61653C10.2616 1.18349 10.7642 0.974707 11.3364 0.990173C11.9087 1.00564 12.4113 1.22989 12.8443 1.66293C13.2928 2.11143 13.5093 2.60633 13.4939 3.14763C13.4939 3.70439 13.2774 4.19929 12.8443 4.63232L3.79695 13.6797C3.36391 14.1127 2.86901 14.3293 2.31225 14.3293C1.75549 14.3293 1.25286 14.105 0.804354 13.6565Z"/>
            </svg></span>
        </button>
        
        <div class="modal-body">
            <div class="modal-body-wrp">
                <div class="card-details-form">
                    <div class="row">
                        <div class="col-12 m-b-25">
                            <label for="" class="form-label">{{helper.label.numero_de_carte | translate}}</label>
                            <div id="card-number-element" class="form-control"></div>
                        </div>
                        <div class="col-6 m-b-25">
                            <label for="" class="form-label">{{helper.label.date_d_expiration | translate}}</label>
                            <div id="card-expiry-element" class="form-control"></div>
                        </div>
                        <div class="col-4">
                            <label for="" class="form-label">{{helper.label.ccv | translate}}</label>
                            <div id="card-cvc-element" class="form-control"></div>
                        </div>
                        <div class="col-12 m-b-25" *ngIf="error_message">
                            <span class="error-msg">{{error_message}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal-footer">
            <div class="modal-footer-wrp d-flex align-items-center justify-content-center">
                <div class="c-drop-footer">
                    <div class="add-new-pro-btn popup-footer-btn d-flex align-items-center">
                        <button class="primary-btn back-btn-popup" (click)="modal.dismiss('Cross click')"><svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1054 19.1318C11.504 19.8092 10.4673 19.8709 9.78982 19.2695L0.551371 11.0682C0.200719 10.7569 5.04105e-05 10.3104 5.04514e-05 9.84152C5.04924e-05 9.37263 0.200719 8.92616 0.551372 8.61487L9.78982 0.413563C10.4673 -0.187844 11.504 -0.126188 12.1054 0.551269C12.7068 1.22873 12.6452 2.26546 11.9677 2.86686L4.11102 9.84152L11.9677 16.8162C12.6452 17.4176 12.7068 18.4543 12.1054 19.1318Z"/>
                            </svg>
                        </button>
                        <button class="primary-btn" (click)="add_card()">
                            {{helper.button.utiliser_cette_nouvelle_carte | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</ng-template>