import { Component, NgZone, OnInit, ElementRef, ViewChild, Injectable } from '@angular/core';
import { CommonData } from './common_data';
import { Helper } from './helper';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class OfferService {

	cart_offer_message: string = '';
  	cart_offer_value: number = 0;
  	cart_offer_error: string = '';
  	cart_offer_error2: string = '';
  	cart_offer_progress_bar: number = -1;
  	delivery_offers: any[] = [];
  	is_show_cart_offer:boolean=false;
  	is_show_header_popup:boolean=false;

	constructor(public common_data: CommonData, public helper: Helper){

    }

	check_cart_offer(current_store:any, delivery_currency:string){
	    var cart_offer_message = '';
	    var cart_offer_error = '';
	    var cart_offer_error2 = '';
	    this.cart_offer_value = 0
	    var index = current_store.offers.findIndex((offer:any)=>offer.offer_type==4);
	    if(index !== -1){
	      this.is_show_cart_offer = true;
	        var offer_detail = current_store.offers[index];
	        var discount_type = offer_detail.discount_type;
	        var discount_value = offer_detail.discount_value;
	        
	        if(!offer_detail.is_min_order_price || (offer_detail.is_min_order_price && this.helper.common_data._user_cart.cart_data.total_cart_amount>=offer_detail.min_order_price)){
	            this.cart_offer_error = '';
	            this.cart_offer_error2 = '';
	            if(discount_type==0){
					if(this.helper.website_data.selected_language=='en'){
						this.cart_offer_message = '⭐️️ you benefit from -'+discount_value+'% on your order ⭐️️';
					} else {
						this.cart_offer_message = '⭐️️ vous bénéficiez de -'+discount_value+'% sur votre commande ⭐️️';
					}
					this.cart_offer_progress_bar = 100;
					this.cart_offer_value = ((this.helper.common_data._user_cart.cart_data.total_cart_amount * discount_value)/100);
	            } else{
					if(this.helper.website_data.selected_language=='en'){
	                	this.cart_offer_message = '⭐️️ you benefit from -'+discount_value+' '+delivery_currency+' on your order ⭐️️';
					} else {
	                	this.cart_offer_message = '⭐️️ vous bénéficiez de -'+discount_value+' '+delivery_currency+' sur votre commande ⭐️️';
					}
	                this.cart_offer_progress_bar = -1;
	                this.cart_offer_value = discount_value;
	                if(this.cart_offer_value > this.helper.common_data._user_cart.cart_data.total_cart_amount){
	                  this.cart_offer_value = this.helper.common_data._user_cart.cart_data.total_cart_amount
	                }
	            }
	        } else {
	            this.cart_offer_message = '';
	            this.cart_offer_value = 0
	            var amount = offer_detail.min_order_price - this.helper.common_data._user_cart.cart_data.total_cart_amount;
	            this.cart_offer_progress_bar = ((this.helper.common_data._user_cart.cart_data.total_cart_amount*100)/offer_detail.min_order_price);
				if(this.helper.website_data.selected_language=='en'){
	            	this.cart_offer_error2 = '⭐️️ More than '+amount.toFixed(2)+' '+delivery_currency+' to take advantage of '
					if(discount_type==0){
						this.cart_offer_error2 = this.cart_offer_error2 + '- '+discount_value+'% on your order ⭐️️'
					} else {
						this.cart_offer_error2 = this.cart_offer_error2 + '- '+discount_value+delivery_currency+' on your order ⭐️️'
					}
				} else {
					this.cart_offer_error2 = '⭐️️ Plus que '+amount.toFixed(2)+' '+delivery_currency+' pour profiter de '
					if(discount_type==0){
						this.cart_offer_error2 = this.cart_offer_error2 + '- '+discount_value+'% sur votre commande ⭐️️'
					} else {
						this.cart_offer_error2 = this.cart_offer_error2 + '- '+discount_value+delivery_currency+' sur votre commande ⭐️️'
					}
				}
	        }
	    } else {
	      this.cart_offer_message = '';
	      this.cart_offer_error = '';
	      this.cart_offer_error2 = '';
	      this.cart_offer_value = 0
	    }
	}

	check_delivery_offer(current_store:any, delivery_currency:string){
	    this.delivery_offers = [];
	    var offer_list = current_store.offers.filter((offer:any)=>offer.offer_type==3);
	    offer_list.forEach((offer_detail:any)=>{
	      var offer_message = '';
	      if(offer_detail.discount_type==0){
			if(this.helper.website_data.selected_language=='en'){
				offer_message = offer_detail.discount_value+'% delivery costs are offered'
			} else {
				offer_message = offer_detail.discount_value+'% des frais de livraison sont offerts'
			}
	      } else if(offer_detail.discount_type==1){
			if(this.helper.website_data.selected_language=='en'){
				offer_message = offer_detail.discount_value+delivery_currency+' delivery costs are offered'
			} else {
				offer_message = offer_detail.discount_value+delivery_currency+' des frais de livraison sont offerts'
			}
	      } else if(offer_detail.discount_type==2){
			if(this.helper.website_data.selected_language=='en'){
				offer_message = 'Free delivery costs'
			} else {
				offer_message = 'Frais de livraisons offerts'
			}
	      }
	      if(offer_detail.is_min_order_price){
			if(this.helper.website_data.selected_language=='en'){
				offer_message = offer_message + ' of the '+offer_detail.min_order_price+delivery_currency
			} else {
				offer_message = offer_message + ' dès '+offer_detail.min_order_price+delivery_currency

			}
	      }
	      this.delivery_offers.push(offer_message);
	    })
	}

	check_buy_one_get_one(current_item:any){
	    if(current_item.offer_tags){
	      var index = current_item.offer_tags.findIndex((offer:any)=>offer.offer_type==1);
	      if(index !== -1){
	        return true;
	      } else {
	        return false;
	      }
	    } else {
	      return false;
	    }
	}

	change_buy_one_get_one(current_item:any, is_buy_one_get_one:boolean){
    	current_item.is_buy_one_get_one = is_buy_one_get_one;
  	}

	check_item_offer(current_item:any, total:number){
	    if(current_item &&current_item.offer_tags){
	      var index = current_item.offer_tags.findIndex((offer:any)=>offer.offer_type==2);
	      if(index !== -1){
	        var offer_detail = current_item.offer_tags[index];
	        var discount_type = offer_detail.discount_type;
	        var discount_value = offer_detail.discount_value;
	        var offer_price = 0;
	        if(discount_type==0){
	          offer_price = (total * discount_value)/100;
	        } else{
	          offer_price = discount_value;
	          if(offer_price > total){
	            offer_price = total;
	          }
	        }
	        // this.total = this.total - offer_price;
	        current_item.offer_price = offer_price;
	      }
	    }
	}
}