import { Component, OnInit, ViewChild, ElementRef, HostListener, TemplateRef} from '@angular/core';
import { Helper } from '../shared/helper';
declare var stripe: any;
declare var elements: any;
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

export interface AddCard{
    card_holder_name: string,
    card_number: string,
    last_four: any,
    expiry_month: any,
    expiry_year: any,
    cvv: any,
    card_type: string,
    payment_method: string
}

@Component({
  selector: 'app-account-payment',
  templateUrl: './account-payment.component.html',
  styleUrls: ['./account-payment.component.scss'],
  providers: [Helper],
})
export class AccountPaymentComponent implements OnInit {

  constructor(private modalService: NgbModal, public helper: Helper) { }


  public add_card_data: AddCard = {
      card_holder_name: '',
      card_number: '',
      last_four: null,
      expiry_year: null,
      expiry_month: null,
      cvv: null,
      card_type: '',
      payment_method: ''
  };
  public card_list : any[] = [];
  public user_id: string = '';
  public server_token: string = '';
  public card_error : string = '';
  show_add_card:boolean = false;
  error: any;
  public wallet: number = 0;
  public wallet_currency_code: string = '';

  mod_ref1:any;
  cardNumberElement:any;
  cardExpiryElement:any;
  cardCvcElement:any;
  error_message:string='';
  is_card_element_created:boolean=false;

  @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
      if(this.mod_ref1){
        this.mod_ref1.close();
      }
    }

  ngOnInit(): void {
    let user = JSON.parse(localStorage['user']);
    if(user && user._id){
        this.user_id = user._id;
        this.server_token = user.server_token;
        this.get_card()
    } else {
      this.helper.router.navigate(['']);
    }
  }

  ngAfterViewInit(){
        setTimeout(()=>{
            if(elements._elements.length > 0){
              elements._elements = [];
            }
        }, 1000)
    }

  async get_card() {
    var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_CARD_LIST, {user_id: this.user_id, server_token: this.server_token}, true, true, false, false);

      if(response_data.success){
          this.card_list = response_data.cards;
          this.wallet = response_data.wallet;
          this.wallet_currency_code = response_data.wallet_currency_code;
      } else {
        this.wallet = response_data.wallet;
          this.wallet_currency_code = response_data.wallet_currency_code;
      }
  }

  add_new_card(template: TemplateRef<any>){
      this.error_message = '';
      this.mod_ref1 = this.modalService.open(template, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'open-new-card-popup card-edit-popup popup-mid', });
      if(!this.is_card_element_created){
        this.is_card_element_created = true;
        setTimeout(()=>{
          this.cardNumberElement = elements.create('cardNumber', {});
          this.cardNumberElement.mount('#card-number-element');

          this.cardExpiryElement = elements.create('cardExpiry', {});
          this.cardExpiryElement.mount('#card-expiry-element');

          this.cardCvcElement = elements.create('cardCvc', {});
          this.cardCvcElement.mount('#card-cvc-element');
        },100);
      } else {
        setTimeout(()=>{
          this.cardNumberElement.mount('#card-number-element');
          this.cardExpiryElement.mount('#card-expiry-element');
          this.cardCvcElement.mount('#card-cvc-element');
        },100);
      }
  }

  async add_card(){
    let user_detail = JSON.parse(localStorage['user']);
    var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STRIPE_ADD_CARD_INTENT, {}, true, false, false, true);
    stripe.handleCardSetup(
      response_data.client_secret, this.cardNumberElement, {
        payment_method_data: {
          billing_details: {
            name: user_detail.name,
            phone: user_detail.country_phone_code + user_detail.phone,
            email: user_detail.email,
          }
        }
      }
    ).then((result:any)=> {
      console.log(result)
      if (result.error) {
        this.helper.loader_service.loading=false;
        this.error_message = result.error.message;
      } else {
        this.error_message = '';
        this.add_card_data.payment_method = result.setupIntent.payment_method;
        this.add_card_service(this.add_card_data)
      }
    });
  }

  async add_card_service(card_data:any) {
      card_data.user_id = this.user_id;
      card_data.server_token = this.server_token;
      card_data.payment_id = this.helper.PAYMENT_GATEWAY_CONSTANT.STRIPE;
      card_data.type = 7;
      card_data.card_expiry_date = card_data.expiry_month+'/'+card_data.expiry_year;

      // delete card_data.card_number;
      var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_CARD, card_data, true, true, true, true);

          if(response_data.success){
              this.show_add_card = false;
              this.card_list.push(response_data.card);
              this.cardNumberElement.clear();
              this.mod_ref1.close()
              this.add_card_data = {
                  card_holder_name: '',
                  card_number: '',
                  last_four: null,
                  expiry_year: null,
                  expiry_month: null,
                  cvv: null,
                  card_type: '',
                  payment_method: ''
              }
          } else {
              // this.helper.data.storage = {
              //     "message": this.helper.ERROR_CODE[res_data.error_code],
              //     "class": "alert-danger"
              // }
          }
          // this.helper.message();
  }

    async delete_card(card_id:string, card_index:number){
        var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.DELETE_CARD, {user_id: this.user_id, server_token: this.server_token, card_id: card_id}, true, true, true, true);

            if(response_data.success){
                if(this.card_list[card_index].is_default && this.card_list.length > 1){
                    this.card_list.splice(card_index, 1);
                    this.card_list[0].is_default = true;

                } else {
                    this.card_list.splice(card_index, 1)
                }
                // this.helper.data.storage = {
                //     "message": this.helper.MESSAGE_CODE[response_data.message],
                //     "class": "alert-info"
                // }

            } else {
                // this.helper.data.storage = {
                //     "message": this.helper.ERROR_CODE[response_data.error_code],
                //     "class": "alert-danger"
                // }
            }
            // this.helper.message();
    }

    async select_card(card_id:string, card_index:number){
        if(!this.card_list[card_index].is_default){
            var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.SELECT_CARD, {user_id: this.user_id, server_token: this.server_token, card_id: card_id}, true, true, false, false);

              if(response_data.success){
                  let index = this.card_list.findIndex((x)=> x.is_default == true)
                  if(index !== -1){
                      this.card_list[index].is_default = false;
                  }
                  this.card_list[card_index].is_default = true;
              } else {
                  // this.helper.data.storage = {
                  //     "message": this.helper.ERROR_CODE[response_data.error_code],
                  //     "class": "alert-danger"
                  // }
              }
        }

    }

}
