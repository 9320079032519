<div class="page-content cart-page account-page">
    <div class="page-content-wrp">

        <div class="address-page-wrp">
            <div class="address-page-inn">
                <div class="account-page-container">
                    <div class="account-header-top d-flex align-items-center">
                        <div class="pro-img">
                            <img src="../../assets/images/about-img.png" alt="">
                        </div>
                        <div class="pro-name">
                            <span>{{user_name}}</span>
                        </div>
                    </div>
                    <div class="profile-detail d-flex align-items-top">
                        <div class="pro-list">
                            <ul>
                                <li [class.active]="helper.router.url=='/account/profile'"><a routerLink="/account/profile">{{helper.menu_title.profil | translate}}</a></li>
                                <li [class.active]="helper.router.url=='/account/adresses'"><a routerLink="/account/addresses">{{helper.menu_title.adresses | translate}}</a></li>
                                <li [class.active]="helper.router.url=='/account/paiements'"><a routerLink="/account/payments">{{helper.menu_title.paiements | translate}}</a></li>
                                <li [class.active]="helper.router.url=='/account/orders'"><a routerLink="/account/orders">{{helper.menu_title.commandes | translate}}</a></li>
                            </ul>
                        </div>
                        <div class="pro-content">

                        	<router-outlet></router-outlet>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 