import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ShopComponent } from './shop/shop.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HeaderComponent } from './header/header.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { AccountAddressComponent } from './account-address/account-address.component';
import { ProfileCommonComponent } from './profile-common/profile-common.component';
import { AccountPaymentComponent } from './account-payment/account-payment.component';
import { AccountOrderComponent } from './account-order/account-order.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { OfflineMenuComponent } from './offline-menu/offline-menu.component';
import { ThirdPartyOrderDetailComponent } from './third-party-order-detail/third-party-order-detail.component';

const routes: Routes = [

  {
    path: '',
    data: {
      title: 'Home',
    },
    component: HomeComponent
  },
  {
    path: 'home',
    data: {
      title: 'Home',
    },
    component: HomeComponent
  },
  {
    path: 'home/table',
    data: {
      title: 'Home',
    },
    component: HomeComponent
  },
  {
    path: 'shop',
    data: {
      title: 'Shop',
    },
    component: ShopComponent
  },
  {
    path: 'shop/table/:table_no',
    data: {
      title: 'Shop',
    },
    component: ShopComponent
  },
  {
    path: 'shop/table/:table_no/:item_name',
    data: {
      title: 'Shop',
    },
    component: ShopComponent
  },
  {
    path: 'shop/:item_name',
    data: {
      title: 'Shop',
    },
    component: ShopComponent
  },
  {
    path: 'third-party-order-detail',
    data: {
      title: 'Order Detail',
    },
    component: ThirdPartyOrderDetailComponent
  },
  {
    path: '',
    component: HeaderComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'checkout',
        data: {
          title: 'Checkout',
        },
        component: CheckoutComponent
      },
      {
        path: 'checkout/table',
        data: {
          title: 'Checkout',
        },
        component: CheckoutComponent
      },
      {
        path: 'checkout/table/:table_no',
        data: {
          title: 'Checkout',
        },
        component: CheckoutComponent
      },
      {
        path: 'menu',
        data: {
          title: 'Menu',
        },
        component: OfflineMenuComponent
      },
      {
        path: 'order-tracking/:order_id',
        data: {
          title: 'Order Tracking',
        },
        component: OrderTrackingComponent
      },
      {
        path: 'order-detail/:order_id',
        data: {
          title: 'Shop',
        },
        component: OrderDetailComponent
      },
      {
        path: 'account',
        component: ProfileCommonComponent,
        data: {
          title: 'Home'
        },
        children: [
          {
            path: 'profile',
            data: {
              title: 'Profile',
            },
            component: AccountProfileComponent
          },
          {
            path: 'addresses',
            data: {
              title: 'Account Address',
            },
            component: AccountAddressComponent
          },
          {
            path: 'payments',
            data: {
              title: 'Account payment',
            },
            component: AccountPaymentComponent
          },
          {
            path: 'orders',
            data: {
              title: 'Account Order',
            },
            component: AccountOrderComponent
          }
        ]
      }
    ]
  },
  {
    path: '**',
    data: {
      title: 'Home',
    },
    component: HomeComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
