


export var GET_METHOD = {
    GET_COUNTRY_LIST : '/api/admin/get_country_list'
}


export var POST_METHOD = {
    GET_CITY_LIST:"admin/get_all_city_list",
    CHECK_VALID_ADDRESS: 'api/user/check_address_inside_city_store',
    CHECK_AND_GET_DOMAIN_DATA: 'api/store/check_and_get_domain_data',
    GET_STORE_DETAIL: 'api/user/get_store_detail',
    FORGOT_PASSWORD : 'api/admin/forgot_password',
    LOGIN : 'api/user/login',
    LOGOUT: 'api/user/logout',
    REGISTER : 'api/user/register',
    UPDATE : 'api/user/update',
    GET_DETAIL: 'api/user/get_detail',
    GET_FAVOUTIRE_ADDRESSES: 'api/user/get_favoutire_addresses',
    USER_GET_STORE_PRODUCT_ITEM_LIST : "api/user/user_get_store_product_item_list",
    ADD_ITEM_IN_CART: "api/user/add_item_in_cart",
    GET_CART: "api/user/get_cart",
    CLEAR_CART: "api/user/clear_cart",
    GET_ORDER_CART_INVOICE: "api/user/get_order_cart_invoice",
    APPLY_PROMO_CODE: "api/user/apply_promo_code",
    CHANGE_DELIVERY_ADDRESS: 'api/user/change_delivery_address',
    GET_STRIPE_PAYMENT_INTENT: 'api/get_stripe_payment_intent',
    PAY_ORDER_PAYMENT: "api/user/pay_order_payment",
    CREATE_ORDER: "api/user/create_order",
    ORDER_HISTORY: 'api/user/order_history',
    GET_ORDERS: "api/user/get_orders",
    GET_ORDER_DETAIL: "api/user/get_order_detail",
    GET_PAYMENT_GATEWAY: "api/user/get_payment_gateway",
    GET_CARD_LIST: "api/user/get_card_list",
    GET_STRIPE_ADD_CARD_INTENT: 'api/get_stripe_add_card_intent',
    ADD_CARD: "api/user/add_card",
    DELETE_CARD: "api/user/delete_card",
    SELECT_CARD: "api/user/select_card",
    CHANGE_USER_WALLET_STATUS: "api/user/change_user_wallet_status",
    ADD_WALLET_AMOUNT: "api/user/add_wallet_amount",
    ADD_FAVOURITE_ADDRESS: 'api/user/add_favourite_address',
    UPDATE_FAVOURITE_ADDRESS: 'api/user/update_favourite_address',
    DELETE_FAVOURITE_ADDRESS: 'api/user/delete_favourite_address',
    USER_CANCEL_ORDER: "api/user/user_cancel_order",
    
}
