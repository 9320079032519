import { Pipe, PipeTransform } from '@angular/core';
import { DeliverySettingService } from '../shared/delivery-setting.service';

@Pipe({
    name: 'searchpipe',
    pure: false
})
export class SearchPipe implements PipeTransform {

    constructor(public delivery_setting_service: DeliverySettingService){}

    transform(value: any, search: string, searcharea: string[]): any {
        if (search !== undefined) {
            // const regex = new RegExp(search, 'i');
            search = search.replace(/^\s+|\s+$/g, '');
            search = search.replace(/ +(?= )/g, '');
            search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            // search = search.replace(/ /g, "");
            // search = search.replace(/(|)/g, "");
            search = search.replace(/[^a-zA-Z ]/g, "");
            const regex = new RegExp(search,"gi");
            const data:any[] = [];
            value.forEach((element:any) => {
                let flag = false;
                if(searcharea && searcharea.length){
                    searcharea.forEach(ele => {
                        var main = ele.split('.');
                        if(typeof element[ele] === 'object'){
                            var langIndex = 0;
                            if (element[ele][langIndex] && element[ele][langIndex].match(regex)) {
                                flag = true;
                            }
                        }
                        else{
                            var string = main.length > 1 ?element[main[0]][main[1]] : element[main[0]];
                            // string = string.replace(/^\s+|\s+$/g, '');
                            // string = string.replace(/ +(?= )/g, '');
                            string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            // string = string.replace(/ /g, "");
                            // string = string.replace(/(|)/g, "");
                            string = string.replace(/[^a-zA-Z ]/g, "");
                            // const string_regex = new RegExp(string,"gi");
                            if (string.match(regex)) {
                                // flag = element[ele][langIndex].match(regex);
                                flag = true;
                            }
                        }
                    });
                }else{
                    flag = element.toString().match(regex);
                }
                if (flag) {
                    data.push(element);
                }
            });
            let autocomplete_element:any = document.getElementsByClassName('pac-container');
            autocomplete_element = autocomplete_element[autocomplete_element.length-1]
            if(data.length>0){
                this.delivery_setting_service.is_show_address = true;
                autocomplete_element.style.display = 'none';
                setTimeout(()=>{
                    autocomplete_element.style.display = 'none';
                })
            } else {
                this.delivery_setting_service.is_show_address = false;
                autocomplete_element.style.display = 'block';
                setTimeout(()=>{
                    autocomplete_element.style.display = 'block';
                })
            }
            return data;
        }
        return value;
    }
}