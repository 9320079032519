import { Component, NgZone, OnInit, ElementRef, ViewChild, Injectable } from '@angular/core';
import { CommonData } from './common_data';
import { Helper } from './helper';
import { BehaviorSubject } from "rxjs";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

export interface CartProductItems{
    item_id: Object,
    unique_id: Number,
    item_name: string,
    quantity: Number,
    image_url: any,
    formule_list: any[],
    details: string,
    specifications: any[],
    item_price: Number,
    offer_price: number,
    is_buy_one_get_one: boolean,
    total_specification_price: Number,
    total_item_and_specification_price: Number,
    offer_tags: any[],
    note_for_item: string
}

export interface cartProducts{
    items: any[],
    product_id: Object,
    product_name: string,
    unique_id: number,
    total_item_price: number
}

export interface cartSpecificationGroups{
    list: any[],
    name: string,
    price: number,
    type: number,
    formule_id: string,
    min_selection: any,
    max_selection: any,
    is_required: boolean,
    unique_id: number
}

@Injectable({ providedIn: "root" })
export class CartService {

    private cartProductItems : CartProductItems = {
        item_id: '',
        unique_id: 0,
        item_name: '',
        quantity: 0,
        image_url: [],
        formule_list: [],
        details: '',
        specifications: [],
        item_price: 0,
        offer_price: 0,
        is_buy_one_get_one: false,
        total_specification_price: 0,
        total_item_and_specification_price: 0,
        note_for_item: '',
        offer_tags: []
    };
    private cartProducts : cartProducts = {
        items: [],
        product_id: '',
        product_name: '',
        unique_id: 0,
        total_item_price: 0
      };
    private cartSpecificationGroups : cartSpecificationGroups = {
        list: [],
        name: '',
        formule_id: '',
        price: 0,
        type: 0,
        is_required: false,
        min_selection: null,
        max_selection: null,
        unique_id: 0
    };
    is_add_cart_observable = new BehaviorSubject<Boolean>(false);

    constructor(public common_data: CommonData, private modalService: NgbModal, public helper: Helper){

    }

    increase_qty(product_index:number, item_index:number, min_order_price:any, MoaAlert:any){
        this.common_data._user_cart.cart_data.cart[product_index].items[item_index].quantity++;
        let qty = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].quantity;
        let item_price = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].item_price;
        let total_specification_price = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].total_specification_price;
        let formule_list = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].formule_list
        if(formule_list && formule_list.length>0){
          formule_list.forEach((formule:any)=>{
            if(formule.price){
              // total_specification_price = total_specification_price + Number(formule.price);
            }
          })
        }
        this.common_data._user_cart.cart_data.cart[product_index].items[item_index].total_item_and_specification_price = ((item_price + total_specification_price) * qty);

        this.calculateTotalAmount(null, min_order_price, MoaAlert)
    }

    decrease_qty(product_index:number, item_index:number, modal_ref:any, min_order_price:any, MoaAlert:any){

        if(this.common_data._user_cart.cart_data.cart[product_index].items[item_index].quantity > 1){
            this.common_data._user_cart.cart_data.cart[product_index].items[item_index].quantity--;

            let qty = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].quantity;
            let item_price = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].item_price;
            let total_specification_price = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].total_specification_price;

            let formule_list = this.common_data._user_cart.cart_data.cart[product_index].items[item_index].formule_list
            if(formule_list && formule_list.length>0){
              formule_list.forEach((formule:any)=>{
                if(formule.price){
                  // total_specification_price = total_specification_price + Number(formule.price);
                }
              })
            }

            this.common_data._user_cart.cart_data.cart[product_index].items[item_index].total_item_and_specification_price = ((item_price + total_specification_price) * qty);
            this.calculateTotalAmount(modal_ref, min_order_price, MoaAlert);

        } else {
            this.remove_from_cart(product_index, item_index, modal_ref, min_order_price, MoaAlert)
        }

    }

    calculateTotalAmount(modal_ref:any, min_order_price:any, MoaAlert:any){
        let total = 0.;
        this.common_data._user_cart.cart_data.cart.forEach((product) => {
            product.items.forEach((item:any) => {
                this.check_item_offer(item)
                total = total + item.total_item_and_specification_price - item.offer_price;
            })
        });
        this.common_data._user_cart.cart_data.total_cart_amount = total;

        if(!min_order_price || !MoaAlert || min_order_price<=this.common_data._user_cart.cart_data.total_cart_amount){
            if(this.common_data._user_cart.cart_data.total_item == 0){
                this.clear_cart(modal_ref);
            } else {
                this.add_to_cart();
            }
        } else {
            // this.get_cart();
            this.modalService.open(MoaAlert, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'moa-popup popup-mid', });
        }

    }

    remove_from_cart(product_index:number, item_index:number, modal_ref:any, min_order_price:any, MoaAlert:any){
        this.common_data._user_cart.cart_data.cart[product_index].items.splice(item_index, 1);
        if(this.common_data._user_cart.cart_data.cart[product_index].items.length <= 0){
            this.common_data._user_cart.cart_data.cart.splice(product_index, 1);
        }
        this.common_data._user_cart.cart_data.total_item--;
        this.calculateTotalAmount(modal_ref, min_order_price, MoaAlert);
    }

    async add_to_cart(){
        var user_id = '';
        var user_type = null;
        var server_token = '';
        let user = null
        if(localStorage['user']){
            user = JSON.parse(localStorage['user']);
            if(user && user._id){
                user_id = user._id;
                user_type = user.user_type;
                server_token = user.server_token;
            }
        }
        if(this.common_data._user_cart.cart_data.destination_addresses.length == 0){
            if(user && user._id){
                this.common_data.cart_data.destination_address.user_type = user.user_type;
                this.common_data.cart_data.destination_address.user_details = {
                    "name": user.first_name + ' ' + user.last_name,
                    "country_phone_code": user.country_phone_code,
                    "phone": user.phone,
                    "email": user.email
                };
            }
            this.common_data._user_cart.cart_data.destination_addresses.push(this.common_data.cart_data.destination_address);
        }
        let json ={
            user_id: user_id,
            server_token: server_token,
            user_type: user_type,
            cart_id: this.common_data._user_cart.cart_data.cart_id,
            store_id: this.common_data._user_cart.cart_data.selectedStoreId,
            cart_unique_token: localStorage.getItem('cart_unique_token'),
            order_details: this.common_data._user_cart.cart_data.cart,
            myLoading: false,
            destination_addresses: this.common_data._user_cart.cart_data.destination_addresses,
            pickup_addresses: this.common_data._user_cart.cart_data.pickup_addresses
        }
        var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_ITEM_IN_CART, json, false, false, true, true)
            if(res_data.success){
                // this.router_id.user.currency = this.router_id.user.delivery_currency;
                this.common_data._user_cart.cart_data.cart_id = res_data.cart_id;
                this.common_data._user_cart.cart_data.city_id = res_data.city_id;
                this.get_cart();
                this.is_add_cart_observable.next(true);
                return {success: true}
                
            } else {
                this.get_cart();
                this.is_add_cart_observable.next(true);
                return {success: false}
            }
            

    }

    async get_cart(){
        
        var user_id = '';
        var user_type = null;
        var server_token = '';
        if(localStorage['user']){
            let user = JSON.parse(localStorage['user']);
            if(user && user._id){
                user_id = user._id;
                user_type = user.user_type;
                server_token = user.server_token;
            }
        }
        
        
        let json:any = {
            user_id: user_id,
            server_token: server_token,
            store_id: this.helper.website_data.detail.store_id,
            cart_unique_token: localStorage.getItem('cart_unique_token'),
            myLoading: false
        }

        if(localStorage['is_schedule_order']=='true'){
          let date = localStorage['schedule_date'].split('-');
          let time = localStorage['schedule_time'].split(':')

          let selected_date: any = new Date(Date.now());
          selected_date.setDate(date[2])
          selected_date.setMonth(date[1]-1)
          selected_date.setFullYear(date[0])
          selected_date.setHours(time[0], time[1], 0, 0);
          selected_date = new Date(selected_date);
          selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.helper.common_data.cart_data.timezone})

          console.log(selected_date)
          json['selected_date'] = selected_date;
        }

        var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_CART, json, false, false, true, true)

            this.cartProductItems = {
                item_id: '',
                unique_id: 0,
                item_name: '',
                quantity: 0,
                image_url: [],
                formule_list: [],
                offer_tags: [],
                details: '',
                specifications: [],
                item_price: 0,
                offer_price: 0,
                is_buy_one_get_one: false,
                total_specification_price: 0,
                total_item_and_specification_price: 0,
                note_for_item: ''
            }
            this.cartProducts = {
                items: [],
                product_id: '',
                product_name: '',
                unique_id: 0,
                total_item_price: 0
            }

            this.cartSpecificationGroups = {
                list: [],
                name: '',
                formule_id: '',
                price: 0,
                type: 0,
                is_required: false,
                min_selection: null,
                max_selection: null,
                unique_id: 0
            }
        
            this.common_data._user_cart.cart_data.cart = [];
            this.common_data._user_cart.cart_data.total_item = 0;
            this.common_data._user_cart.cart_data.total_cart_amount = 0;
            this.common_data._user_cart.cart_main_item = [];
            // this.common_data._user_cart.cart_data.is_get_card = true;

            if(res_data.success){

                this.common_data.cart_data.server_date = res_data.server_time;
                this.common_data.cart_data.timezone = res_data.timezone;

                // this.router_id.user.currency = res_data.currency;
                this.common_data._user_cart.cart_data.cart_id = res_data.cart_id;
                this.common_data._user_cart.cart_data.city_id = res_data.city_id;

                // this.user_cart.store_location = res_data.location;
                // this.user_cart.cart_data.deliveryAddress = res_data.destination_address;
                // this.user_cart.cart_data.deliveryLatLng = res_data.destination_location;
                this.common_data._user_cart.cart_data.destination_addresses = res_data.destination_addresses;
                this.common_data._user_cart.cart_data.pickup_addresses = res_data.pickup_addresses;
                this.common_data._user_cart.cart_data.selectedStoreId = res_data.store_id;
                let cart_data_res = res_data.cart.order_details;
                this.set_product_item_offer_tag(res_data.offers, cart_data_res)

                cart_data_res.forEach((cart_product:any) => {
                    this.cartProducts = JSON.parse(JSON.stringify(this.cartProducts));

                    // this.cartProducts.items = cart_product.items;
                    this.cartProducts.product_id = cart_product.product_detail._id;
                    this.cartProducts.product_name = cart_product.product_detail.name;
                    this.cartProducts.unique_id = cart_product.product_detail.unique_id;

                    let item_array:any[] = [];
                    let itemPriceTotal = 0;
                    cart_product.items.forEach((cart_item:any) => {

                        this.common_data._user_cart.cart_main_item.push(cart_item.item_details);

                        this.cartProductItems = JSON.parse(JSON.stringify(this.cartProductItems));

                        this.cartProductItems.item_id = cart_item.item_details._id;
                        this.cartProductItems.formule_list = cart_item.formule_list;
                        this.cartProductItems.unique_id = cart_item.item_details.unique_id;
                        this.cartProductItems.item_name = cart_item.item_details.name;
                        this.cartProductItems.quantity = cart_item.quantity;
                        this.cartProductItems.image_url = cart_item.item_details.image_url;
                        this.cartProductItems.details = cart_item.item_details.details;
                        // this.cartProductItems.specifications = specificationList;
                        this.cartProductItems.item_price = cart_item.item_details.price;

                        // this.cartProductItems.total_specification_price = specificationPriceTotal;
                        // this.cartProductItems.total_item_and_specification_price = this.total;
                        this.cartProductItems.note_for_item = cart_item.note_for_item;
                        let specificationPriceTotal =0;
                        let specification_group_array:any[] = [];
                        this.check_item_offer(cart_item)
                        this.cartProductItems.offer_price = cart_item.offer_price;
                        this.cartProductItems.is_buy_one_get_one = cart_item.is_buy_one_get_one;
                        this.cartProductItems.offer_tags = cart_item.offer_tags;
                        cart_item.specifications.forEach((specification_group:any) => {

                            this.cartSpecificationGroups = JSON.parse(JSON.stringify(this.cartSpecificationGroups));
                            this.cartSpecificationGroups.name = specification_group.name;
                            this.cartSpecificationGroups.type = specification_group.type;
                            this.cartSpecificationGroups.is_required = specification_group.is_required;
                            this.cartSpecificationGroups.min_selection = specification_group.min_selection;
                            this.cartSpecificationGroups.max_selection = specification_group.max_selection;
                            this.cartSpecificationGroups.unique_id = specification_group.unique_id;
                            this.cartSpecificationGroups.formule_id = specification_group.formule_id;
                            // this.cartSpecificationGroups.price = specification_group.price;
                            // this.cartSpecificationGroups.list = specification_group.list;
                            let specification_array:any[] = [];
                            let specification_price = 0;
                            specification_group.list.forEach((specification:any) => {
                                cart_item.item_details.specifications.forEach((new_specification_group:any) => {
                                  if(!new_specification_group.formule_id || new_specification_group.formule_id == specification_group.formule_id){
                                    if(specification_group.unique_id == new_specification_group.unique_id){
                                        new_specification_group.list.forEach((new_specification:any) => {
                                            if(specification.unique_id == new_specification.unique_id){
                                                specification.price = Number(new_specification.price);
                                                specification_price = specification_price + Number(new_specification.price);
                                                specification_array.push(specification);
                                                specificationPriceTotal = specificationPriceTotal + Number(new_specification.price);
                                            }
                                        })
                                    }
                                  }
                                })
                            });
                            this.cartSpecificationGroups.price = specification_price;
                            this.cartSpecificationGroups.list = specification_array;
                            specification_group_array.push(this.cartSpecificationGroups);
                        });
                        this.cartProductItems.specifications = specification_group_array;
                        this.cartProductItems.total_specification_price = specificationPriceTotal;
                        let item_price = cart_item.item_details.price
                        this.cartProductItems.formule_list.forEach((formule:any)=>{
                          if(formule.price){
                            // specificationPriceTotal = specificationPriceTotal + Number(formule.price)
                            this.cartProductItems.item_price = Number(formule.price)
                            item_price = Number(formule.price)
                            this.cartProductItems.item_name = formule.name;
                          }
                        })
                        this.cartProductItems.total_item_and_specification_price = (specificationPriceTotal + item_price)* cart_item.quantity;

                        item_array.push(this.cartProductItems)
                        itemPriceTotal = itemPriceTotal - (this.cartProductItems.offer_price) + ((specificationPriceTotal + item_price) * cart_item.quantity);
                        this.common_data._user_cart.cart_data.total_item++;
                    });
                    this.cartProducts.items = item_array;
                    this.cartProducts.total_item_price = itemPriceTotal;
                    this.common_data._user_cart.cart_data.cart.push(this.cartProducts);
                    this.common_data._user_cart.cart_data.total_cart_amount = this.common_data._user_cart.cart_data.total_cart_amount + itemPriceTotal;

                });
            } else {
                // this.common_data._user_cart.is_get_cart_observable.next(true);
            }


    }

    async clear_cart(modal_ref:any){
        var user_id = '';
        var user_type = null;
        var server_token = '';
        if(localStorage['user']){
            let user = JSON.parse(localStorage['user']);
            if(user && user._id){
                user_id = user._id;
                user_type = user.user_type;
                server_token = user.server_token;
            }
        }
        let json = {
            user_id: user_id,
            server_token: server_token,
            cart_id: this.common_data._user_cart.cart_data.cart_id,
            myLoading: false, 
            cart_unique_token: localStorage.getItem('cart_unique_token')
        }
        var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.CLEAR_CART, json, false, false, true, true)
            if(res_data.success){
                this.common_data._user_cart.cart_data.total_item = 0;
                this.common_data._user_cart.cart_data.max_item_quantity_add_by_user = 0;
                this.common_data._user_cart.cart_data.total_cart_amount = 0;
                this.common_data._user_cart.cart_data.order_payment_id = '';
                this.common_data._user_cart.cart_data.cart_id = '';
                this.common_data._user_cart.cart_data.city_id = '';
                this.common_data._user_cart.cart_data.selectedStoreId = '';
                this.common_data._user_cart.cart_data.cart = [];
                this.common_data._user_cart.cart_data.destination_addresses = [];
                this.common_data._user_cart.cart_data.pickup_addresses = [];
                this.helper.router.navigate(['shop']);
                if(modal_ref){
                    modal_ref.close();
                }
            } else {
                this.get_cart()
            }
    }

    check_item_offer(cart_item:any){
      if(cart_item.offer_tags){
        var index = cart_item.offer_tags.findIndex((offer:any)=>offer.offer_type==2);
        if(index !== -1){
          var offer_detail = cart_item.offer_tags[index];
          var discount_type = offer_detail.discount_type;
          var discount_value = offer_detail.discount_value;
          var item_price = cart_item.item_price + cart_item.total_specification_price
          var offer_price = 0;
          if(discount_type==0){
            offer_price = (item_price * discount_value)/100;
          } else{
            offer_price = discount_value;
            if(offer_price > cart_item.total_item_and_specification_price){
              offer_price = cart_item.total_item_and_specification_price;
            }
          }
          // this.total = this.total - offer_price;
          cart_item.offer_price = offer_price * cart_item.quantity;
          // cartProductItems.offer_price = offer_price;
        }
      }
    }

    check_buy_one_get_one(item_detail:any){
        var index = item_detail.offer_tags.findIndex((offer:any)=>offer.offer_type==1);
        if(index !== -1){
            return true;
        } else {
            return false;
        }
    }

    set_product_item_offer_tag(offers: any[], cart_data_res:any){
        cart_data_res.forEach((product_detail:any, product_index:number)=>{
            product_detail.product_detail.offer_tags = [];
            product_detail.items.forEach((item_detail:any, item_index:any)=>{
                item_detail.offer_price = 0;
                item_detail.offer_tags = [];
            });
        });
      if(offers){
        offers.forEach((offer)=>{
          if(offer.offer_type==1 || offer.offer_type==2){
            offer.product_item_ids.forEach((product_item_id: string)=>{
              this.check_product_item_offer(cart_data_res, product_item_id, offer)
            })
          }
        })
      }
    }

    check_product_item_offer(cart_data_res:any, product_item_id:string, offer:any){
        cart_data_res.forEach((product_detail:any, product_index:number)=>{
            if(product_item_id.toString() == product_detail.product_detail._id.toString()){
              if(!product_detail.product_detail.offer_tags){
                product_detail.product_detail.offer_tags = []
              }
              product_detail.product_detail.offer_tags.push(offer)
              product_detail.items.forEach((item_detail:any, item_index:any)=>{
                if(!item_detail.offer_tags){
                  item_detail.offer_tags = []
                }
                item_detail.offer_tags.push(offer)
                if(offer.offer_type==2){
                  item_detail.offer_price = 0;
                  var discount_value = offer.discount_value;
                  if(offer.discount_type==0){
                    if(discount_value>100){
                      discount_value=100
                    }
                    item_detail.offer_price = item_detail.price - ((item_detail.price * discount_value)/100);
                  } else {
                    if(discount_value>item_detail.price){
                      discount_value=item_detail.price
                    }
                    item_detail.offer_price = item_detail.price - discount_value
                  }
                }
              });
            } else {
              product_detail.items.forEach((item_detail:any, item_index:any)=>{
                if(product_item_id.toString() == item_detail.item_id.toString()){
                  if(!item_detail.offer_tags){
                    item_detail.offer_tags = []
                  }
                    item_detail.offer_tags.push(offer);
                  if(offer.offer_type==2){
                    item_detail.offer_price = 0;
                    var discount_value = offer.discount_value;
                    if(offer.discount_type==0){
                      if(discount_value>100){
                        discount_value=100
                      }
                      item_detail.offer_price = item_detail.price - ((item_detail.price * discount_value)/100);
                    } else {
                      if(discount_value>item_detail.price){
                        discount_value=item_detail.price
                      }
                      item_detail.offer_price = item_detail.price - discount_value
                    }
                  }
                }
              })
            }
        })

    }

    async logout(){
        var user = JSON.parse(localStorage['user']);
        if(user!==null)
        {
            var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.LOGOUT, {myLoading: false, user_id:user._id},false, false, false, false)
            localStorage.removeItem('user');
        }
        else
        {
            localStorage.removeItem('user');
        }
        // this.helper.router.navigate(['/home']);
        this.helper.router.navigate(['home']).then(() => {
            window.location.reload();
        });
        // this.helper.fire_observable()
        // this.get_cart()
    }

    go_to_missing_choice(current_item:any){
        var index = current_item.specifications.findIndex((x:any)=>(x.is_disabled==true && x.is_required==true));
        if(index !== -1){
          var elmnt:any = document.getElementById('specification'+current_item.specifications[index].unique_id)
          if(elmnt){
            elmnt.scrollIntoView({behavior: "smooth"});
          }
        }
    }

    get_missing_choice(current_item:any, formule_id: string){
        var index = current_item.specifications.findIndex((x:any)=>(x.is_disabled==true && x.is_required==true && (!x.formule_id || x.formule_id==formule_id)));
        if(index !== -1){
          return this.helper.text.missing_choice_text + current_item.specifications[index].name
        } else {
          return '';
        }
    }
}