<ng-template #NotDeliver let-modal>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.641964 4.44674C0.27079 4.07556 0.0852035 3.64252 0.0852036 3.14762C0.0852039 2.65272 0.270791 2.21969 0.641965 1.84851C1.01314 1.47734 1.43844 1.29948 1.91788 1.31495C2.39731 1.33042 2.82261 1.52374 3.19379 1.89491L12.6355 11.3367C13.0067 11.7078 13.1923 12.1409 13.1923 12.6358C13.2078 13.1152 13.0299 13.5405 12.6587 13.9117C12.2876 14.2829 11.8545 14.4684 11.3596 14.4684C10.8802 14.453 10.4549 14.2597 10.0837 13.8885L0.641964 4.44674ZM0.804354 13.6565C0.371317 13.2235 0.147065 12.7208 0.1316 12.1486C0.1316 11.5918 0.348119 11.0969 0.781156 10.6639L9.82853 1.61653C10.2616 1.18349 10.7642 0.974707 11.3364 0.990173C11.9087 1.00564 12.4113 1.22989 12.8443 1.66293C13.2928 2.11143 13.5093 2.60633 13.4939 3.14763C13.4939 3.70439 13.2774 4.19929 12.8443 4.63232L3.79695 13.6797C3.36391 14.1127 2.86901 14.3293 2.31225 14.3293C1.75549 14.3293 1.25286 14.105 0.804354 13.6565Z"/>
            </svg></span>
    </button>
    <div class="modal-body">
      <div class="modal-body-wrp">
          <div class="see-menu-title">
              <span>{{helper.text.oops | translate}}</span>
              <h3>{{helper.text.not_deliver_text | translate}}</h3>
          </div>
          <div class="send-address">
              <p>{{helper.text.send_addess | translate}}</p>
              <form action="">
                  <input type="email" [(ngModel)]="email" name="email" class="form-control" placeholder="{{helper.placeholder.email_address | translate}}">
              </form>
          </div>
      </div>
    </div>
    <div class="modal-footer">
        <div class="modal-footer-wrp d-flex align-items-center justify-content-center">
          <div class="popup-footer-btn">
              <button (click)="modal.dismiss('Cross click')" class="primary-btn">{{helper.button.envoyer | translate}}</button>
          </div>
        </div>
    </div>
  
</ng-template>

<ng-template #ScopeOfDelivery  let-modal>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.641964 4.44674C0.27079 4.07556 0.0852035 3.64252 0.0852036 3.14762C0.0852039 2.65272 0.270791 2.21969 0.641965 1.84851C1.01314 1.47734 1.43844 1.29948 1.91788 1.31495C2.39731 1.33042 2.82261 1.52374 3.19379 1.89491L12.6355 11.3367C13.0067 11.7078 13.1923 12.1409 13.1923 12.6358C13.2078 13.1152 13.0299 13.5405 12.6587 13.9117C12.2876 14.2829 11.8545 14.4684 11.3596 14.4684C10.8802 14.453 10.4549 14.2597 10.0837 13.8885L0.641964 4.44674ZM0.804354 13.6565C0.371317 13.2235 0.147065 12.7208 0.1316 12.1486C0.1316 11.5918 0.348119 11.0969 0.781156 10.6639L9.82853 1.61653C10.2616 1.18349 10.7642 0.974707 11.3364 0.990173C11.9087 1.00564 12.4113 1.22989 12.8443 1.66293C13.2928 2.11143 13.5093 2.60633 13.4939 3.14763C13.4939 3.70439 13.2774 4.19929 12.8443 4.63232L3.79695 13.6797C3.36391 14.1127 2.86901 14.3293 2.31225 14.3293C1.75549 14.3293 1.25286 14.105 0.804354 13.6565Z"/>
            </svg>
            </span>
    </button>
    <div class="modal-header justify-content-center no-header">
        <h2>{{helper.heading_title.perimetre_de_livraison | translate}}</h2>
    </div>
    <div class="modal-body">
      <div class="modal-body-wrp">
        <div class="deli-map" id="map" style="height: 350px;">
        </div>
      </div>
    </div>
</ng-template>