<div class="home-main order-tracking-page" [ngStyle]="{'background-image': 'url(' + helper.image_base_url + helper.website_data.detail.image_url + ')'}">
    <div class="home-wrp">
        <div class="home-content">
            <div class="tracking-profile d-flex align-items-center">
                <div class="t-profile-img">
                    <span><img src="{{helper.website_data.detail.site_logo}}" alt=""></span>
                </div> 
                <div class="t-p-thanku-msg">
                    <h3>{{helper.website_data.detail.name}}</h3>
                </div>  
            </div>
            <div class="order-tracking-detail">
                <div class="order-number">
                    <label>{{helper.label.numero_de_commande | translate}}</label>
                    <span>#{{order_detail.unique_id}}</span>
                </div>
                <div class="order-time" *ngIf="order_detail.order_status_id==helper.ORDER_STATUS_ID.RUNNING || order_detail.order_status_id==helper.ORDER_STATUS_ID.IDEAL">
                    <span>{{helper.label.ready_time_title | translate}}</span>
                    <div class="time-show">
                        <label>{{order_detail.estimated_time_for_ready_order | date: "Ve. dd/MM à HH:mm"}}</label>
                    </div>
                </div>
                <div class="order-time" *ngIf="(order_detail.order_status_id==helper.ORDER_STATUS_ID.RUNNING || order_detail.order_status_id==helper.ORDER_STATUS_ID.IDEAL) && !order_detail.is_user_pick_up_order">
                    <span>{{helper.label.delivery_time_title | translate}}</span>
                    <div class="time-show">
                        <label>{{order_detail.estimated_time_for_delivery_order | date: "Ve. dd/MM à HH:mm"}}</label>
                    </div>
                </div>
                <div class="order-time" *ngIf="order_detail.order_status_id==helper.ORDER_STATUS_ID.COMPLETED">
                    <span>{{helper.label.complete_time_title | translate}}</span>
                    <div class="time-show">
                        <label>{{order_detail.completed_at  | date: "Ve. dd/MM à HH:mm"}}</label>
                    </div>
                </div>
                <div class="order-time" *ngIf="order_detail.order_status_id==helper.ORDER_STATUS_ID.CANCELLED">
                    <span>{{helper.label.cancel_time_title | translate}}</span>
                    <div class="time-show">
                        <label>{{order_detail.completed_at  | date: "Ve. dd/MM à HH:mm"}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>