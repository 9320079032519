<div class="shop-page-header" [class.checkout-heder]="helper.router.url=='/checkout' && !user_id">
    <div class="shop-header-wrp d-flex align-items-center">
        <div class="shop-profile-img">
            <span (click)="go_to_home()" class="checkout-logo" *ngIf="helper.router.url=='/checkout'"><img src="{{helper.website_data.detail.site_logo}}" alt=""></span>
            <button class="primary-btn svg-left" [class.back-button]="helper.router.url=='/checkout'" (click)="go_to_shop()"><svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70217 13.7851C9.26905 14.273 8.52243 14.3174 8.03453 13.8842L1.3812 7.97783C1.12866 7.75365 0.984147 7.43211 0.984147 7.09442C0.984147 6.75674 1.12866 6.4352 1.3812 6.21102L8.03454 0.304611C8.52243 -0.128508 9.26905 -0.0841056 9.70217 0.403787C10.1353 0.891679 10.0909 1.63831 9.603 2.07142L3.94478 7.09443L9.603 12.1174C10.0909 12.5505 10.1353 13.2972 9.70217 13.7851Z"/>
                </svg>
                <span>{{helper.button.retour_a_l_accueil | translate}}</span>
            </button>
        </div>
        <div class="menu-plus ml-auto" [class.active]="is_language_dropdown">
            <button class="primary-btn" (click)="is_language_dropdown=!is_language_dropdown">{{helper.website_data.selected_language | translate}} <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6.5 7L12 1" stroke-width="2"/>
                </svg>
            </button>
            <ul class="v-scroll-hide" *ngIf="is_language_dropdown">
                <li *ngFor="let product of helper.website_data.language_list;let index=index;" [class.active]="product.code==helper.website_data.selected_language" (click)="helper.select_language(product.code,index);is_language_dropdown=false"><a>{{product.code}}</a></li>
            </ul>
        </div>
        <div class="login-btn" *ngIf="user_id">
            <!-- <button class="primary-btn svg-left" *ngIf="!user_id" (click)="AccountPopup.openModal()"><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.76807 0.351929C8.43606 0.351929 7.39542 0.842182 6.64617 1.82269C5.89691 2.80319 5.52229 4.05658 5.52229 5.58284C5.51304 7.4791 6.12354 8.98224 7.3538 10.0922C7.51105 10.2402 7.56655 10.4299 7.5203 10.6611L7.20117 11.3271C7.09942 11.5491 6.94911 11.7226 6.75023 11.8474C6.55136 11.9723 6.13279 12.1504 5.49454 12.3816C5.46679 12.3909 4.88172 12.5805 3.73934 12.9505C2.59695 13.3205 1.98876 13.524 1.91476 13.561C1.13776 13.8848 0.629004 14.2224 0.388503 14.5739C0.129501 15.1567 0 16.632 0 19.0001H19.5361C19.5361 16.632 19.4066 15.1567 19.1476 14.5739C18.9071 14.2224 18.3984 13.8848 17.6214 13.561C17.5474 13.524 16.9392 13.3205 15.7968 12.9505C14.6544 12.5805 14.0693 12.3909 14.0416 12.3816C13.4033 12.1504 12.9848 11.9723 12.7859 11.8474C12.587 11.7226 12.4367 11.5491 12.335 11.3271L12.0158 10.6611C11.9696 10.4299 12.0251 10.2402 12.1823 10.0922C13.4126 8.98224 14.0231 7.4791 14.0138 5.58284C14.0138 4.05658 13.6392 2.80319 12.89 1.82269C12.1407 0.842182 11.1001 0.351929 9.76807 0.351929Z"/>
                </svg>
                 {{helper.button.se_connecter | translate}}
            </button> -->
            <button class="primary-btn svg-left only-svg "><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.76807 0.351929C8.43606 0.351929 7.39542 0.842182 6.64617 1.82269C5.89691 2.80319 5.52229 4.05658 5.52229 5.58284C5.51304 7.4791 6.12354 8.98224 7.3538 10.0922C7.51105 10.2402 7.56655 10.4299 7.5203 10.6611L7.20117 11.3271C7.09942 11.5491 6.94911 11.7226 6.75023 11.8474C6.55136 11.9723 6.13279 12.1504 5.49454 12.3816C5.46679 12.3909 4.88172 12.5805 3.73934 12.9505C2.59695 13.3205 1.98876 13.524 1.91476 13.561C1.13776 13.8848 0.629004 14.2224 0.388503 14.5739C0.129501 15.1567 0 16.632 0 19.0001H19.5361C19.5361 16.632 19.4066 15.1567 19.1476 14.5739C18.9071 14.2224 18.3984 13.8848 17.6214 13.561C17.5474 13.524 16.9392 13.3205 15.7968 12.9505C14.6544 12.5805 14.0693 12.3909 14.0416 12.3816C13.4033 12.1504 12.9848 11.9723 12.7859 11.8474C12.587 11.7226 12.4367 11.5491 12.335 11.3271L12.0158 10.6611C11.9696 10.4299 12.0251 10.2402 12.1823 10.0922C13.4126 8.98224 14.0231 7.4791 14.0138 5.58284C14.0138 4.05658 13.6392 2.80319 12.89 1.82269C12.1407 0.842182 11.1001 0.351929 9.76807 0.351929Z"/>
                </svg>
            </button>
            <app-profile-menu></app-profile-menu>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<app-login-register-modal #AccountPopup [is_register_button]="true" [is_second_text]="true" [login_header_text]="helper.title.pay_text1"></app-login-register-modal>
