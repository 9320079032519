import { Component, OnInit, ViewEncapsulation, TemplateRef, HostListener, OnDestroy } from '@angular/core';
import { Helper } from '../shared/helper';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Params} from '@angular/router';

@Component({
  selector: 'app-third-party-order-detail',
  templateUrl: './third-party-order-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./third-party-order-detail.component.scss'],
  providers: [Helper]
})
export class ThirdPartyOrderDetailComponent implements OnInit, OnDestroy {

  mod_ref:any;
  constructor(public helper: Helper, private modalService: NgbModal) { }

  order_number: string = '';
  interval:any;
  user_id: string = '';
  server_token: string = '';
  order_detail: any = {};
  cancel_reason:string = '';

  @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
      if(this.mod_ref){
        this.mod_ref.close();
      }
    }

  openModal(template: TemplateRef<any>) {
    this.mod_ref = this.modalService.open(template, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'cancel-order-popup popup-mid', });
    this.cancel_reason = this.helper.text.cancel_list1;
  }

  ngOnInit(): void {
    this.helper.loader_service.main_loading = false;
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  openModal1(template: TemplateRef<any>) {
    this.mod_ref = this.modalService.open(template, { centered: true, scrollable: true, size: 'lg', modalDialogClass: 'edit-cart-popup o-d-e-cart-popup popup-mid product-item-popup mob-full-popup', });
  }

  clear_order(){
    this.order_detail = {};
    this.order_number = '';
  }

  async get_order_detail(is_loading:boolean){
    if(this.order_number){
      var json = {
        // order_id: this.order_id,
        is_third_party_order: true,
        order_number: this.order_number,
        user_id: this.user_id,
        server_token: this.server_token
      }
      var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_ORDER_DETAIL, json, is_loading, true, false, true);

      if(response_data){
        this.order_detail = response_data.order;
      } else {
        this.clear_order();
      }
    }
  }

  check_button(item_id:string){
    var div:any = document.getElementById(item_id);
    if(div){
      if(div.scrollHeight>75){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}
