<div class="account-dropdown">
    <ul>
        <li>
            <a routerLink="/account/profile" class="blank_a">
                <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="15" viewBox="0 0 14 16" width="15" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g id="Symbols"><g id="Icon---Profile" transform="translate(-8.000000, -7.000000)"><path class="st0" d="M22,22.2c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8v-1.6c0-1.3-1-2.4-2.3-2.4h-6.2c-1.3,0-2.3,1.1-2.3,2.4v1.6c0,0.4-0.3,0.8-0.8,0.8S8,22.6,8,22.2v-1.6c0-2.2,1.7-4,3.9-4h6.2c2.1,0,3.9,1.8,3.9,4V22.2z M15,15c-2.1,0-3.9-1.8-3.9-4s1.7-4,3.9-4s3.9,1.8,3.9,4S17.1,15,15,15z M15,13.4c1.3,0,2.3-1.1,2.3-2.4s-1-2.4-2.3-2.4s-2.3,1.1-2.3,2.4S13.7,13.4,15,13.4z" id="Icon"></path></g></g></svg>
                <span>{{helper.menu_title.profil | translate}}</span>
            </a>
        </li>
        <li>
            <a routerLink="/account/addresses" class="blank_a">
                <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="15" viewBox="0 0 17 16" width="15" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g id="Symbols"><g id="Icon---Address" transform="translate(-7.000000, -7.000000)"><g id="Content" transform="translate(7.000000, 7.000000)"><path class="st0" d="M12.6,8.3c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v5.5c0,1.2-1,2.2-2.2,2.2h-7c-1.2,0-2.2-1-2.2-2.2V8.3c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v5.5c0,0.4,0.3,0.7,0.7,0.7h7c0.4,0,0.7-0.3,0.7-0.7V8.3z" id="Fill-1"></path><g id="Clip-4"></g><defs><filter filterUnits="userSpaceOnUse" height="7.8" id="Adobe_OpacityMaskFilter" width="16.7" x="0" y="0.1"><feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"></feColorMatrix></filter></defs><mask height="7.8" id="mask-2_1_" maskUnits="userSpaceOnUse" width="16.7" x="0" y="0.1"><g class="st1"><polygon class="st2" id="path-1_1_" points="0,0.1 16.7,0.1 16.7,7.9 0,7.9                    "></polygon></g></mask><path class="st3" d="M0.7,7.9c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.7,0-1l7.7-6.4c0.3-0.3,0.7-0.3,1,0l7.7,6.4c0.3,0.3,0.3,0.7,0,1c-0.3,0.3-0.7,0.3-1,0L8.4,1.8L1.2,7.7C1.1,7.8,0.9,7.9,0.7,7.9" id="Fill-3"></path></g></g></g></svg>
                <span>{{helper.menu_title.adresses | translate}}</span>
            </a>
        </li>
        <li>
            <a routerLink="/account/payments" class="blank_a">
                <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="15" viewBox="0 0 18 14" width="15" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g id="Symbols"><g id="Icon---Payments" transform="translate(-6.000000, -8.000000)"><g id="Content"><path class="st0" d="M22.5,12.7v-2.3c0-0.4-0.3-0.8-0.8-0.8H8.2c-0.4,0-0.8,0.3-0.8,0.8v2.3H22.5z M22.5,14.2h-15v5.4c0,0.4,0.3,0.8,0.8,0.8h13.5c0.4,0,0.8-0.3,0.8-0.8V14.2z M8.2,8h13.5C23,8,24,9,24,10.3v9.3c0,1.3-1,2.3-2.2,2.3H8.2C7,22,6,21,6,19.7v-9.3C6,9,7,8,8.2,8z" id="Icon"></path></g></g></g></svg>
                <span>{{helper.menu_title.paiements | translate}}</span>
            </a>
        </li>
        <li>
            <a routerLink="/account/orders" class="blank_a">
                <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="15" viewBox="0 0 20 22" width="15" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g id="Symbols"><g id="Delivery---Schedule" transform="translate(-1.000000, 0.000000)"><path class="st0" d="M19,8V5c0-0.6-0.4-1-1-1h-2v1c0,0.6-0.4,1-1,1s-1-0.4-1-1V4H8v1c0,0.6-0.4,1-1,1S6,5.6,6,5V4H4C3.4,4,3,4.4,3,5v3H19z M19,10H3v9c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V10z M8,2h6V1c0-0.6,0.4-1,1-1s1,0.4,1,1v1h2c1.7,0,3,1.3,3,3v14c0,1.7-1.3,3-3,3H4c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h2V1c0-0.6,0.4-1,1-1s1,0.4,1,1V2z" id="Icon"></path></g></g></svg>
                <span>{{helper.menu_title.commandes | translate}}</span>
            </a>
        </li>
        <li>
            <a (click)="cart_service.logout()" class="blank_a">
                <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" height="15" viewBox="0 0 14 14" width="15" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><g id="Symbols"><g id="Icon---Logout" transform="translate(-8.000000, -8.000000)"><path class="st0" d="M19.6,15.7h-6.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h6.7L17.3,12c-0.3-0.3-0.3-0.7,0-1s0.7-0.3,1,0l3.5,3.5c0.3,0.3,0.3,0.7,0,1L18.3,19c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1L19.6,15.7z M12.9,20.6c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-2.8C8.9,22,8,21.1,8,19.9v-9.8C8,8.9,8.9,8,10.1,8h2.8c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7h-2.8c-0.4,0-0.7,0.3-0.7,0.7v9.8c0,0.4,0.3,0.7,0.7,0.7H12.9z" id="Combined-Shape"></path></g></g></svg>
                <span>{{helper.menu_title.deconnexion | translate}}</span>
            </a>
        </li>
    </ul>
</div>