import {
  Component,
  NgZone,
  OnInit,
  ElementRef,
  ViewChild,
  Injectable,
} from '@angular/core';
import { CommonData } from './common_data';
import { Helper } from './helper';
import { CartService } from './cart.service';
declare var google: any;
import { DeliveryErrorModalComponent } from '../delivery-error-modal/delivery-error-modal.component';

@Injectable({ providedIn: 'root' })
export class DeliverySettingService {
  is_schedule_order: Boolean = false;
  schedule_date: any = '';
  schedule_time: string = '';
  is_show_date: boolean = false;
  is_show_time: boolean = false;
  schedule_time_error: boolean = false;
  // is_close_asap:boolean=false;

  date_array: any[] = [];
  time_array: any[] = [];
  clicked_date: any = null;
  server_date: any = null;
  delivered_time: string = '';

  is_autocomplete_loaded: boolean = false;
  is_address_valid: boolean = true;
  favourite_addresses: any[] = [];
  is_show_address: boolean = false;

  is_delay_order: boolean = false;
  delay_distance: number = 0;
  delay_time: number = 0;
  close_till: any = null;
  delay_order_error: boolean = false;

  is_emergency: boolean = false;
  emergency_close_till: any = null;
  @ViewChild('NotDeliver') NotDeliver!: DeliveryErrorModalComponent;

  constructor(
    public cart_service: CartService,
    public common_data: CommonData,
    public helper: Helper
  ) {}

  set_date(date: any, current_store: any) {
    console.log('set_date');
    this.schedule_date = date.date;
    localStorage.setItem('schedule_date', this.schedule_date);
    this.clicked_date = date.date_format;
    localStorage.setItem('clicked_date', this.clicked_date);
    this.helper.common_data.cart_data.schedule_date = new Date(date.date);
    this.is_show_date = false;
    this.is_show_time = true;
    this.check_and_set_schedule(current_store);
  }

  select_time(current_store: any) {
    if (this.is_show_time) {
      this.set_time({ time_format: this.schedule_time }, current_store);
      if (current_store.close) {
        this.schedule_time = this.time_array[0].time_format;
        this.change_schedule(true, current_store);
      }
    } else {
      this.is_show_time = true;
    }
  }

  set_show_address() {
    if (this.is_show_address) {
      this.is_show_address = false;
      var current_location: any = localStorage.getItem('current_location');
      if (current_location) {
        current_location = JSON.parse(current_location);
        this.helper.common_data.cart_data.address = current_location.address;
      }
    } else {
      this.is_show_address = true;
      this.helper.common_data.cart_data.address = '';
    }
  }

  set_time(schedule_time: any, current_store: any) {
    if (!this.is_show_date) {
      this.schedule_time = schedule_time.time_format;
      localStorage.setItem('schedule_time', this.schedule_time);
      let server_date: any = new Date(
        this.helper.common_data.cart_data.server_date
      );
      server_date = new Date(server_date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      server_date = new Date(server_date);

      let date = JSON.parse(JSON.stringify(this.schedule_date.split('-')));
      let time = this.schedule_time.split(':');

      let selected_date: any = new Date(Date.now());
      selected_date = new Date(selected_date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      selected_date = new Date(selected_date);
      selected_date.setDate(date[2]);
      selected_date.setMonth(date[1] - 1);
      selected_date.setFullYear(date[0]);
      selected_date.setHours(time[0], time[1], 0, 0);
      let timeDiff = Math.round(
        selected_date.getTime() - server_date.getTime()
      );

      var preparation_time = 0;
      if (this.helper.common_data.cart_data.ipickup_delivery) {
        preparation_time = current_store['preparation_time_for_pickup'];
      } else {
        preparation_time = current_store['preparation_time_for_delivery'];
        if (this.helper.common_data.cart_data.google_time) {
          preparation_time =
            preparation_time +
            Math.ceil(this.helper.common_data.cart_data.google_time / 60);
        }
      }

      var schedule_order_create_after_minute = 0;
      if (current_store.is_only_schedule_order) {
        schedule_order_create_after_minute =
          current_store.only_schedule_order_after_hour * 60;
      } else {
        schedule_order_create_after_minute = preparation_time;
      }

      if (timeDiff / 60000 >= schedule_order_create_after_minute) {
        this.schedule_time_error = false;
        this.helper.common_data.cart_data.schedule_date = selected_date;
        this.helper.common_data.cart_data.clicked_date =
          this.clicked_date + ' ' + this.schedule_time;
        this.helper.common_data.cart_data.is_schedule_order =
          this.is_schedule_order;

        this.is_show_time = false;
        this.check_open(current_store, selected_date, false, true);
      } else {
        this.schedule_time_error = true;
      }
    }
  }

  change_schedule(is_schedule_order: boolean, current_store: any) {
    this.helper.common_data.cart_data.is_schedule_order = is_schedule_order;
    this.is_schedule_order = is_schedule_order;
    localStorage.setItem('is_schedule_order', is_schedule_order.toString());
    if (!is_schedule_order) {
      var date: any = new Date();
      date = new Date(date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      date = new Date(date);
      this.check_open(current_store, date, true, true);
    } else {
      this.set_time({ time_format: this.schedule_time }, current_store);
    }
  }

  update_address_detail(json: any) {
    this.helper.common_data.cart_data.destination_address.floor = json.floor;
    this.helper.common_data.cart_data.destination_address.entry_code =
      json.entry_code;
    this.helper.common_data.cart_data.destination_address.note = json.note;
    var current_location: any = localStorage.getItem('current_location');
    if (current_location) {
      current_location = JSON.parse(current_location);
      current_location.floor = json.floor;
      current_location.entry_code = json.entry_code;
      current_location.note = json.note;
      localStorage.setItem(
        'current_location',
        JSON.stringify(current_location)
      );
    }
  }

  check_and_set_schedule(current_store: any) {
    this.is_schedule_order = false;
    var is_schedule_order = localStorage['is_schedule_order'];
    if (is_schedule_order == 'true') {
      this.is_schedule_order = true;
      this.helper.common_data.cart_data.is_schedule_order = true;

      if (
        !this.schedule_date &&
        localStorage['schedule_date'] &&
        !this.schedule_time &&
        localStorage['schedule_time']
      ) {
        let date = localStorage['schedule_date'].split('-');
        let time = localStorage['schedule_time'].split(':');

        let selected_date: any = new Date(Date.now());
        selected_date.setDate(date[2]);
        selected_date.setMonth(date[1] - 1);
        selected_date.setFullYear(date[0]);
        selected_date.setHours(time[0], time[1], 0, 0);
        // selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.helper.common_data.cart_data.timezone})
        selected_date = new Date(selected_date);
        var today: any = new Date();
        today = new Date(today).toLocaleString('en-US', {
          timeZone: this.helper.common_data.cart_data.timezone,
        });
        today = new Date(today);
        if (selected_date > today) {
          this.schedule_date = localStorage['schedule_date'];
          this.schedule_time = localStorage['schedule_time'];
          this.set_time({ time_format: this.schedule_time }, current_store);
          if (!this.clicked_date && localStorage['clicked_date']) {
            this.clicked_date = localStorage['clicked_date'];
          }
        } else {
          this.change_schedule(false, current_store);
        }
      }
    } else {
      this.is_schedule_order = false;
      this.helper.common_data.cart_data.is_schedule_order = false;
    }
    // this.helper.common_data.cart_data.is_schedule_order =
    let server_date: any;
    if (this.schedule_date) {
      server_date = new Date(this.schedule_date);
    } else if (this.helper.common_data.cart_data.server_date) {
      server_date = new Date(this.helper.common_data.cart_data.server_date);
    } else {
      server_date = new Date();
    }
    if (this.helper.common_data.cart_data.timezone) {
      // server_date = new Date(server_date).toLocaleString("en-US", {timeZone: this.helper.common_data.cart_data.timezone})
      server_date = new Date(server_date);
    }
    this.server_date = server_date;
    var days = ['Di', 'Lu', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    var months = [
      'Jan',
      'Fev',
      'Mar',
      'Avr',
      'Mai',
      'Jun',
      'Juil',
      'Août',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];

    let time_date = new Date(server_date);
    time_date.setHours(0, 0, 0, 0);
    // for(var i=0; i<96; i++){
    //     this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
    //     time_date.setMinutes(time_date.getMinutes()+15);
    // }

    var selected_date: any = new Date(
      this.helper.common_data.cart_data.server_date
    );
    var week_day = selected_date.getDay();
    if (this.helper.common_data.cart_data.schedule_date) {
      selected_date = new Date(this.helper.common_data.cart_data.schedule_date);
    }
    let weekday = selected_date.getDay();

    var current_date = new Date();
    var current_week_day = current_date.getDay();

    let week_index = current_store.store_time.findIndex(
      (x: any) => x.day == weekday
    );
    let day_time = current_store.store_time[week_index].day_time;
    day_time = day_time.filter((x: any) => {
      if (
        (x.is_on == true || x.is_on == undefined) &&
        current_store.store_time[week_index].is_store_open
      ) {
        return x;
      }
    });

    this.time_array = [];
    // if(this.store_detail.store_time[week_index].is_store_open_full_time){
    //     for(var i=0; i<96; i++){
    //         this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
    //         time_date.setMinutes(time_date.getMinutes()+15);
    //     }
    // } else {
    var time_index = 0;
    day_time.forEach((store_time: any, index: number) => {
      let open_time = store_time.store_open_time;
      open_time = open_time.split(':');

      var open_hour = Number(open_time[0]);
      var open_min = Number(open_time[1]);

      let close_time = store_time.store_close_time;
      close_time = close_time.split(':');

      var close_hour = Number(close_time[0]);
      var close_min = Number(close_time[1]);

      var min = 0;
      var open_diff_hour = 4;
      if (open_min == 0) {
        min = 0;
        open_diff_hour = 5;
      } else if (open_min > 0 && open_min <= 15) {
        min = 15;
        open_diff_hour = 4;
      } else if (open_min > 15 && open_min <= 30) {
        min = 30;
        open_diff_hour = 3;
      } else if (open_min > 30 && open_min <= 45) {
        min = 45;
        open_diff_hour = 2;
      } else if (open_min > 45 && open_min <= 60) {
        min = 0;
        open_hour++;
        open_diff_hour = 1;
      }

      var close_diff_hour = 0;
      if (close_min > 0 && close_min <= 15) {
        close_diff_hour = 1;
      } else if (close_min > 15 && close_min <= 30) {
        close_diff_hour = 2;
      } else if (close_min > 30 && close_min <= 45) {
        close_diff_hour = 3;
      } else if (close_min > 45 && close_min <= 60) {
        close_diff_hour = 4;
      }

      var diff_hour = close_hour - open_hour - 1;
      diff_hour = diff_hour * 4;
      diff_hour = diff_hour + open_diff_hour + close_diff_hour;

      time_date.setHours(open_hour, min, 0, 0);
      var preparation_time = 0;
      if (this.helper.common_data.cart_data.ipickup_delivery) {
        preparation_time = current_store['preparation_time_for_pickup'];
      } else {
        preparation_time = current_store['preparation_time_for_delivery'];
        if (this.helper.common_data.cart_data.google_time) {
          preparation_time =
            preparation_time +
            Math.ceil(this.helper.common_data.cart_data.google_time / 60);
        }
      }
      var preparation_time_diff = 0;
      if (preparation_time) {
        preparation_time_diff = Math.ceil(preparation_time / 15);
        diff_hour = diff_hour - preparation_time_diff;
        preparation_time = 15 * preparation_time_diff;
        time_date.setMinutes(time_date.getMinutes() + preparation_time, 0, 0);
      }
      var today: any = new Date();
      today = new Date(today).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      today = new Date(today);
      if (current_store.is_only_schedule_order) {
        today = new Date(
          today.setHours(
            today.getHours() + current_store.only_schedule_order_after_hour
          )
        );
      } else {
        today = new Date(
          today.setMinutes(today.getMinutes() + preparation_time, 0, 0)
        );
        // today = new Date(today.setMinutes(today.getMinutes() + current_store.schedule_order_create_after_minute))
      }
      for (var i = 0; i < diff_hour; i++) {
        if (time_date.getTime() > today.getTime()) {
          var time_format =
            this.helper.pad2(time_date.getHours()) +
            ':' +
            this.helper.pad2(time_date.getMinutes());
          this.time_array[time_index] = {
            time_format: time_format,
            time: time_date.getHours() + ':' + time_date.getMinutes(),
          };
          time_date.setMinutes(time_date.getMinutes() + 15);
          time_index++;
          // if(i==0){
          if (!this.schedule_time) {
            this.schedule_time = time_format;
            localStorage.setItem('schedule_time', this.schedule_time);
          }
          // }
        } else {
          time_date.setMinutes(time_date.getMinutes() + 15);
        }
      }

      // this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
      // time_date.setMinutes(time_date.getMinutes()+15);
    });
    if (day_time.length == 0) {
      for (var i = 0; i < 1; i++) {
        this.time_array[i] = {
          time_format:
            this.helper.pad2(time_date.getHours()) +
            ':' +
            this.helper.pad2(time_date.getMinutes()),
          time: time_date.getHours() + ':' + time_date.getMinutes(),
        };
        time_date.setMinutes(time_date.getMinutes() + 15);
      }
    }
    // }

    if (this.date_array.length == 0) {
      for (var i = 0; i < 7; i++) {
        let date = new Date();
        date.setDate(date.getDate() + i);
        date = new Date(date);

        var weekday1 = date.getDay();
        let week_index1 = current_store.store_time.findIndex(
          (x: any) => x.day == weekday1
        );
        let day_time_list = current_store.store_time[week_index1].day_time;
        day_time_list = day_time_list.filter((x: any) => {
          if (
            (x.is_on == true || x.is_on == undefined) &&
            current_store.store_time[week_index1].is_store_open
          ) {
            return x;
          }
        });
        var day = days[date.getDay()];
        var month = months[date.getMonth()];
        var date_format = day + ', ' + month + ' ' + date.getDate();

        if (
          day_time_list.length > 0 &&
          (i !== 0 ||
            (i == 0 &&
              (!this.helper.website_data.detail.close ||
                this.helper.website_data.detail.nextopentime.length == 5 ||
                this.helper.website_data.detail.is_delivery_stop_admin ||
                this.helper.website_data.detail.nextopentime.includes(day))))
        ) {
          this.date_array.push({
            week_day: week_day,
            date_format: date_format,
            date:
              date.getFullYear() +
              '-' +
              this.helper.pad2(date.getMonth() + 1) +
              '-' +
              this.helper.pad2(date.getDate()),
          });

          if (week_day == 6) {
            week_day = 0;
          } else {
            week_day++;
          }
          if (i == 0) {
            if (!this.schedule_date) {
              this.schedule_date =
                date.getFullYear() +
                '-' +
                this.helper.pad2(date.getMonth() + 1) +
                '-' +
                this.helper.pad2(date.getDate());
              localStorage.setItem('schedule_date', this.schedule_date);
            }
            if (!this.clicked_date) {
              this.clicked_date = date_format;
              localStorage.setItem('clicked_date', this.clicked_date);
            }
          }
        }
      }
    }
    if (this.time_array.length == 0 && this.date_array.length > 0) {
      setTimeout(() => {
        this.date_array.splice(0, 1);
      });
      this.set_date(this.date_array[1], current_store);
      this.schedule_time = this.time_array[0].time_format;
    }
  }

  check_open(
    current_store: any,
    selected_date: Date,
    today: boolean,
    bool: boolean
  ) {
    var date: any = JSON.parse(JSON.stringify(selected_date));
    // date = new Date(date).toLocaleString("en-US", {timeZone: this.helper.common_data.cart_data.timezone})
    date = new Date(date);
    let weekday = date.getDay();
    let current_time = date.getTime();
    if (current_store.is_business) {
      current_store.close = true;
      current_store.conges = false;
      current_store.nextopentime = '';
      current_store['is_pickup_possible'] = false;
      current_store['is_delivery_possible'] = false;

      var current_date = new Date();
      var current_week_day = current_date.getDay();
      let week_index = current_store.store_time.findIndex(
        (x: any) => x.day == weekday
      );

      current_store.store_time.filter((x: any) => {
        x.is_current_day = false;
      });

      current_store.store_time[week_index].is_current_day = true;
      if (current_store.store_time[week_index].is_store_open) {
        let day_time = current_store.store_time[week_index].day_time;
        day_time = day_time.filter((x: any) => {
          if (x.is_on == true || x.is_on == undefined) {
            return x;
          }
        });
        if (day_time.length == 0) {
          current_store.close = true;
          this.check_next_open_time(
            selected_date,
            current_store,
            week_index,
            bool,
            0,
            null,
            0
          );
        } else {
          day_time.forEach((store_time: any, index: number) => {
            let open_time = store_time.store_open_time;
            open_time = open_time.split(':');
            let x = date.setHours(open_time[0], open_time[1], 0, 0);
            let x1 = new Date(x);
            if (this.is_schedule_order) {
              var preparation_time = 0;
              if (this.helper.common_data.cart_data.ipickup_delivery) {
                preparation_time = current_store['preparation_time_for_pickup'];
              } else {
                preparation_time =
                  current_store['preparation_time_for_delivery'];
                if (this.helper.common_data.cart_data.google_time) {
                  preparation_time =
                    preparation_time +
                    Math.ceil(
                      this.helper.common_data.cart_data.google_time / 60
                    );
                }
              }
              var c_date = new Date(
                new Date().toLocaleString('en-US', {
                  timeZone: this.helper.common_data.cart_data.timezone,
                })
              );
              if (x1.getTime() < c_date.getTime()) {
                x1 = c_date;
              }
              x1 = new Date(x1.setMinutes(x1.getMinutes() + preparation_time));
            }

            let x2 = x1.getTime();

            let close_time = store_time.store_close_time;
            close_time = close_time.split(':');
            let y = date.setHours(close_time[0], close_time[1], 0, 0);
            let y1 = new Date(y);
            let y2 = y1.getTime();

            if (current_time >= x2 && current_time <= y2) {
              current_store.close = false;
              current_store['is_delivery_possible'] = true;
              current_store['is_pickup_possible'] = true;
            }

            if (current_time < x2 && current_store.nextopentime == '') {
              current_store.nextopentime = store_time.store_open_time;
            }
          });
          if (current_store.close && !current_store.nextopentime) {
            this.check_next_open_time(
              selected_date,
              current_store,
              week_index,
              bool,
              0,
              null,
              0
            );
          }
        }
      } else {
        current_store.close = true;
        this.check_next_open_time(
          selected_date,
          current_store,
          week_index,
          bool,
          0,
          null,
          0
        );
      }

      if (!current_store.close) {
        if (!current_store.is_stop_new_order_all_day) {
          if (current_store.close_till) {
            let close_till = current_store.close_till;
            close_till = close_till.split(':');
            let open_date_time = date.setHours(
              close_till[0],
              close_till[1],
              0,
              0
            );
            open_date_time = new Date(open_date_time);

            if (this.is_schedule_order) {
              var preparation_time = 0;
              if (this.helper.common_data.cart_data.ipickup_delivery) {
                preparation_time = current_store['preparation_time_for_pickup'];
              } else {
                preparation_time =
                  current_store['preparation_time_for_delivery'];
                if (this.helper.common_data.cart_data.google_time) {
                  preparation_time =
                    preparation_time +
                    Math.ceil(
                      this.helper.common_data.cart_data.google_time / 60
                    );
                }
              }
              open_date_time = new Date(
                open_date_time.setMinutes(
                  open_date_time.getMinutes() + preparation_time
                )
              );
            }
            open_date_time = open_date_time.getTime();

            if (current_time < open_date_time) {
              current_store.close = true;
              current_store.nextopentime = current_store.close_till;
              if (current_store.store_order[0] == 1) {
                current_store['is_delivery_possible'] = true;
                current_store['is_pickup_possible'] = false;
              } else if (current_store.store_order[0] == 2) {
                current_store['is_pickup_possible'] = true;
                current_store['is_delivery_possible'] = false;
              } else if (current_store.store_order[0] == 0) {
                current_store['is_pickup_possible'] = false;
                current_store['is_delivery_possible'] = false;
              }
            }
          }
        } else {
          const today = new Date();
          if (
            date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
          ) {
            current_store.close = true;
            current_store.nextopentime = '';
            if (current_store.is_stop_new_order_all_day) {
              if (current_store.store_order[0] == 1) {
                current_store['is_delivery_possible'] = true;
                current_store['is_pickup_possible'] = false;
              } else if (current_store.store_order[0] == 2) {
                current_store['is_pickup_possible'] = true;
                current_store['is_delivery_possible'] = false;
              } else if (current_store.store_order[0] == 0) {
                current_store['is_pickup_possible'] = false;
                current_store['is_delivery_possible'] = false;
              }
            }
          }
        }
      }

      if (
        !current_store.close &&
        !this.helper.common_data.cart_data.ipickup_delivery &&
        this.is_emergency &&
        this.emergency_close_till
      ) {
        var close_till_time = 0;
        let close_till: any = new Date(this.emergency_close_till);
        close_till = new Date(close_till).toLocaleString('en-US', {
          timeZone: this.helper.common_data.cart_data.timezone,
        });
        close_till = new Date(close_till);
        if (this.helper.common_data.cart_data.is_schedule_order) {
          let selected_date: any = new Date(
            this.helper.common_data.cart_data.schedule_date
          );
          selected_date = new Date(selected_date).toLocaleString('en-US', {
            timeZone: this.helper.common_data.cart_data.timezone,
          });
          selected_date = new Date(selected_date);

          close_till_time = Math.round(
            close_till.getTime() - selected_date.getTime()
          );
        }

        if (
          close_till_time > 0 ||
          !this.helper.common_data.cart_data.is_schedule_order
        ) {
          current_store.close = true;
          current_store['is_delivery_possible'] = false;
          current_store['is_pickup_possible'] = true;
          current_store['is_delivery_stop_admin'] = true;
          current_store.nextopentime =
            close_till.getHours() + ':' + close_till.getMinutes();
        }
      }
    } else {
      current_store['is_delivery_possible'] = false;
      current_store['is_pickup_possible'] = false;
      current_store.close = true;
      if (!current_store.is_business) {
        current_store.nextopentime = '';
      } else {
        // date = new Date(date.setHours(date.getHours()+current_store.only_schedule_order_after_hour))
        // current_store.nextopentime = this.helper.pad2(date.getHours())+':'+this.helper.pad2(date.getMinutes());
      }
    }

    if (current_store.store_conges && current_store.store_conges.length > 0) {
      this.check_conges(
        current_store,
        selected_date,
        this.helper.common_data.cart_data.timezone,
        bool
      );
    }

    if (!current_store.close) {
      if (
        !this.helper.common_data.cart_data.ipickup_delivery &&
        this.is_delay_order &&
        this.helper.common_data.cart_data.google_distance / 1000 >=
          this.delay_distance
      ) {
        this.delay_order_error = this.check_delay_order(this.close_till);
      } else {
        this.delay_order_error = false;
      }
    } else {
      this.delay_order_error = false;
    }

    if (current_store.nextopentime && !current_store.is_delivery_stop_admin) {
      // if(this.is_schedule_order){
      var preparation_time = 0;
      if (this.helper.common_data.cart_data.ipickup_delivery) {
        preparation_time = current_store['preparation_time_for_pickup'];
      } else {
        preparation_time = current_store['preparation_time_for_delivery'];
        if (this.helper.common_data.cart_data.google_time) {
          preparation_time =
            preparation_time +
            Math.ceil(this.helper.common_data.cart_data.google_time / 60);
        }
      }
      var nextopentime = current_store.nextopentime.split(':');
      var date: any = new Date();
      date = new Date(
        date.setHours(
          Number(nextopentime[0].slice(-2)),
          Number(nextopentime[1]),
          0,
          0
        )
      );
      date = new Date(
        date.setMinutes(date.getMinutes() + preparation_time, 0, 0)
      );
      current_store['next_order_time'] =
        ('0' + date.getHours()).slice(-2) +
        ':' +
        ('0' + date.getMinutes()).slice(-2);
      // } else {
      //   current_store['next_order_time'] = '';
      // }
    }
    // if(today && !current_store['is_delivery_stop_admin']){
    if (today) {
      if (current_store.close || current_store.is_only_schedule_order) {
        current_store.is_close_asap = true;
      } else {
        current_store.is_close_asap = false;
      }
    }
    this.calculate_delivery_time(
      current_store.preparation_time_for_pickup,
      current_store.preparation_time_for_delivery
    );
  }

  check_delay_order(close_till: any) {
    var close_till_time = 0;
    if (
      this.helper.common_data.cart_data.is_schedule_order &&
      this.helper.common_data.cart_data.schedule_date &&
      close_till
    ) {
      let selected_date: any = new Date(
        this.helper.common_data.cart_data.schedule_date
      );
      selected_date = new Date(selected_date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      selected_date = new Date(selected_date);

      close_till = new Date(close_till);
      close_till = new Date(close_till).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      close_till = new Date(close_till);

      close_till_time = Math.round(
        close_till.getTime() - selected_date.getTime()
      );
    }
    if (
      close_till_time > 0 ||
      !this.helper.common_data.cart_data.is_schedule_order
    ) {
      return true;
    } else {
      return false;
    }
  }

  calculate_delivery_time(
    preparation_time_for_pickup: number,
    preparation_time_for_delivery: number
  ) {
    var date: any = new Date();
    date = new Date(date).toLocaleString('en-US', {
      timeZone: this.helper.common_data.cart_data.timezone,
    });
    date = new Date(date);
    if (this.delay_order_error) {
      date = new Date(date.setMinutes(date.getMinutes() + this.delay_time));
    }
    if (this.helper.common_data.cart_data.ipickup_delivery) {
      date = new Date(
        date.setMinutes(date.getMinutes() + preparation_time_for_pickup + 5)
      );
      this.delivered_time =
        this.helper.pad2(date.getHours()) +
        ':' +
        this.helper.pad2(date.getMinutes());
    } else {
      date = new Date(
        date.setMinutes(
          date.getMinutes() +
            preparation_time_for_delivery +
            (this.helper.common_data.cart_data.google_time
              ? this.helper.common_data.cart_data.google_time / 60
              : 0) +
            5
        )
      );
      this.delivered_time =
        this.helper.pad2(date.getHours()) +
        ':' +
        this.helper.pad2(date.getMinutes());
    }
  }

  check_conges(
    current_store: any,
    server_date: Date,
    timezone: string,
    bool: boolean
  ) {
    let date: any = server_date;
    date = new Date(date).toLocaleString('en-US', { timeZone: timezone });
    date = new Date(date);
    var current_time = date.getTime();
    current_store.store_conges.forEach((store_conges_detail: any) => {
      var start_time: any = new Date(
        store_conges_detail.start_time
      ).toLocaleString('en-US', { timeZone: timezone });
      start_time = new Date(start_time);
      var end_time: any = new Date(store_conges_detail.end_time).toLocaleString(
        'en-US',
        { timeZone: timezone }
      );
      end_time = new Date(end_time);
      if (
        start_time.getTime() <= current_time &&
        end_time.getTime() > current_time
      ) {
        current_store.close = true;
        current_store['is_delivery_possible'] = false;
        current_store['is_pickup_possible'] = false;
        current_store.conges = true;
        const diffTime = Math.abs(end_time.getTime() - date.getTime());
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        // if(diffDays<=7){
        let weekday = end_time.getDay();
        current_store.conges = false;
        current_store.nextopentime = '';
        this.check_next_open_time(
          server_date,
          current_store,
          weekday - 1,
          bool,
          diffDays - 1,
          end_time.getTime(),
          true
        );
        // } else {
        //   this.current_store.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+moment(end_time).tz(timezone !== undefined ? timezone: 'Europe/Zurich').format('DD/MM à HH:mm');
        //   if(bool){
        //     this.current_store.main_nextopentime = 'Partenaire en congé Précommandez, réouvre le '+moment(end_time).tz(timezone !== undefined ? timezone: 'Europe/Zurich').format('DD/MM à HH:mm');
        //   }
        // }
      }
    });
  }

  check_next_open_time(
    server_date: Date,
    current_store: any,
    week_index: number,
    bool: boolean,
    count: number,
    end_time: any,
    loop_count: any
  ) {
    count++;
    let date: any = JSON.parse(JSON.stringify(server_date));
    date = new Date(date).toLocaleString('en-US', {
      timeZone: this.helper.common_data.cart_data.timezone,
    });
    date = new Date(date);
    let weekday = date.getDay();
    let current_time = date.getTime();

    var days = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ];
    var date_time = JSON.parse(JSON.stringify(server_date));
    date_time = new Date(date_time);
    if (week_index == 6) {
      week_index = 0;
    } else {
      week_index++;
    }
    date_time = date_time.setDate(date_time.getDate() + count);
    date_time = new Date(date_time);

    if (loop_count != 7) {
      loop_count++;
      let day_time = current_store.store_time[week_index].day_time;
      day_time = day_time.filter((x: any) => {
        if (x.is_on == true || x.is_on == undefined) {
          return x;
        }
      });
      if (current_store.store_time[week_index].is_store_open) {
        // if(store.store_time[week_index].is_store_open_full_time){
        //     store.nextopentime = days[week_index];
        //     if(bool){
        //       store.main_nextopentime = days[week_index];
        //     }
        // }
        // else {

        if (day_time.length == 0) {
          this.check_next_open_time(
            server_date,
            current_store,
            week_index,
            bool,
            count,
            end_time,
            loop_count
          );
        } else {
          day_time.forEach((store_time: any, index: number) => {
            let open_time = store_time.store_open_time;
            open_time = open_time.split(':');
            let open_date_time = date_time.setHours(
              open_time[0],
              open_time[1],
              0,
              0
            );
            open_date_time = new Date(open_date_time);
            open_date_time = open_date_time.getTime();

            let close_time = store_time.store_close_time;
            close_time = close_time.split(':');
            let close_date_time = date_time.setHours(
              close_time[0],
              close_time[1],
              0,
              0
            );
            close_date_time = new Date(close_date_time);
            close_date_time = close_date_time.getTime();

            if (!end_time || close_date_time >= end_time) {
              if (
                current_time < open_date_time &&
                !current_store.nextopentime
              ) {
                if (!end_time || open_date_time >= end_time) {
                  const diffTime = Math.abs(open_date_time - current_time);
                  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                  if (diffDays < 7) {
                    var date = new Date(
                      date_time.setHours(open_time[0], open_time[1], 0, 0)
                    );
                    current_store.nextopentime =
                      days[date.getDay()] + ' à ' + store_time.store_open_time;
                  } else {
                    open_date_time = new Date(open_date_time);
                    current_store.nextopentime =
                      open_date_time.getDate() +
                      '/' +
                      (open_date_time.getMonth() + 1) +
                      ' à ' +
                      store_time.store_open_time;
                  }
                  if (end_time) {
                    current_store.conges = true;
                    current_store.nextopentime =
                      'Partenaire en congé Précommandez, réouvre le ' +
                      current_store.nextopentime;
                  }
                } else {
                  const diffTime = Math.abs(end_time - current_time);
                  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                  var end_time_date = new Date(end_time);
                  var hours = ('0' + end_time_date.getHours()).slice(-2);
                  var minutes = ('0' + end_time_date.getMinutes()).slice(-2);
                  if (diffDays < 7) {
                    var date = new Date(
                      date_time.setHours(
                        end_time_date.getHours(),
                        end_time_date.getMinutes(),
                        0,
                        0
                      )
                    );
                    current_store.nextopentime =
                      days[date.getDay()] + ' à ' + hours + ':' + minutes;
                  } else {
                    current_store.nextopentime =
                      end_time_date.getDate() +
                      '/' +
                      (end_time_date.getMonth() + 1) +
                      ' à ' +
                      hours +
                      ':' +
                      minutes;
                  }
                  current_store.nextopentime =
                    'Partenaire en congé Précommandez, réouvre le ' +
                    current_store.nextopentime;
                  current_store.conges = true;
                }
              }
            }
          });
          if (!current_store.nextopentime) {
            this.check_next_open_time(
              server_date,
              current_store,
              week_index,
              bool,
              count,
              end_time,
              loop_count
            );
          }
        }
        // }
      } else {
        this.check_next_open_time(
          server_date,
          current_store,
          week_index,
          bool,
          count,
          end_time,
          loop_count
        );
      }
    }
  }

  async get_fav_address(json: any) {
    var res_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.GET_FAVOUTIRE_ADDRESSES,
      json,
      false,
      false,
      true,
      true
    );
    if (res_data.success) {
      if (res_data.success)
        this.favourite_addresses = res_data.favourite_addresses;
    } else {
      this.favourite_addresses = [];
    }
  }

  load_autocomplete(current_store: any, bool: boolean) {
    let autocompleteElm = <HTMLInputElement>document.getElementById('address');
    let autocomplete = new google.maps.places.Autocomplete(autocompleteElm, {});

    var current_location = localStorage.getItem('current_location');
    if (current_location && bool) {
      this.check_valid_address(
        JSON.parse(current_location),
        current_store,
        false
      );
    }
    this.is_autocomplete_loaded = true;
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        this.is_show_address = false;
        var json: any = {
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          type: 'destination',
          floor: this.helper.common_data.cart_data.destination_address.floor,
          entry_code:
            this.helper.common_data.cart_data.destination_address.entry_code,
          note: this.helper.common_data.cart_data.destination_address.note,
        };
        for (var i = 0; i < place.address_components.length; i++) {
          if (place.address_components[i].types[0] == 'locality') {
            json.city1 = place.address_components[i].long_name;
          } else if (
            place.address_components[i].types[0] ==
            'administrative_area_level_1'
          ) {
            json.city2 = place.address_components[i].long_name;
            json.city_code = place.address_components[i].short_name;
            json.state = place.address_components[i].long_name;
          } else if (
            place.address_components[i].types[0] ==
            'administrative_area_level_2'
          ) {
            json.city3 = place.address_components[i].long_name;
          } else if (place.address_components[i].types[0] == 'country') {
            json.country = place.address_components[i].long_name;
            json.country_code = place.address_components[i].short_name;
            json.country_code_2 = place.address_components[i].short_name;
          } else if (place.address_components[i].types[0] == 'postal_code') {
            json.postal_code = place.address_components[i].long_name;
          }
        }
        let autocomplete_element: any =
          document.getElementsByClassName('pac-container');
        if (autocomplete_element.length > 0) {
          autocomplete_element =
            autocomplete_element[autocomplete_element.length - 1];
          setTimeout(() => {
            autocomplete_element.style.display = 'none';
          });
        }
        this.check_valid_address(json, current_store, true);
      }
    });
  }

  select_address(address: any, current_store: any) {
    address.type = 'destination';
    this.is_show_address = false;
    this.check_valid_address(address, current_store, true);
  }

  async check_valid_address(json: any, current_store: any, loading: boolean) {
    json.store_id = this.helper.website_data.detail.store_id;
    json.cart_id = this.common_data._user_cart.cart_data.cart_id;
    json.is_whop = true;
    this.helper.ngZone.run(async () => {
      var response_data = await this.helper.http_post_method_requester(
        this.helper.POST_METHOD.CHECK_VALID_ADDRESS,
        json,
        loading,
        true,
        false,
        true
      );

      if (response_data.success) {
        if (response_data.min_order_price) {
          this.helper.common_data._user_cart.cart_data.min_order_price =
            response_data.min_order_price;
        }
        this.is_address_valid = true;
        this.helper.common_data.cart_data.address = json.address;
        this.helper.common_data.cart_data.destination_address.address =
          json.address;
        this.helper.common_data.cart_data.destination_address.address_id =
          json._id;
        this.helper.common_data.cart_data.destination_address.country_code =
          json.country_code;
        this.helper.common_data.cart_data.destination_address.country =
          json.country;
        this.helper.common_data.cart_data.destination_address.postal_code =
          json.postal_code;
        this.helper.common_data.cart_data.destination_address.location = [
          json.latitude,
          json.longitude,
        ];
        this.helper.common_data.cart_data.destination_address.floor =
          json.floor;
        this.helper.common_data.cart_data.destination_address.entry_code =
          json.entry_code;
        this.helper.common_data.cart_data.destination_address.note = json.note;
        this.helper.common_data.cart_data.pickup_address.address =
          current_store.address;
        this.helper.common_data.cart_data.pickup_address.location =
          current_store.location;
        this.helper.common_data.cart_data.city_id = response_data.city_id;
        // if (this.helper.router.url == '/checkout') {
        this.helper.common_data._user_cart.cart_data.destination_addresses[0] =
          this.helper.common_data.cart_data.destination_address;
        if (localStorage['user']) {
          let user = JSON.parse(localStorage['user']);
          this.helper.common_data._user_cart.cart_data.destination_addresses[0].user_details =
            {
              name: user.first_name + ' ' + user.last_name,
              country_phone_code: user.country_phone_code,
              phone: user.phone,
              email: user.email,
            };
        }
        // }
        localStorage.setItem('current_location', JSON.stringify(json));
        this.get_distance(current_store);
      } else {
        this.is_address_valid = false;
        this.helper.common_data.NotDeliver_observable.next({ success: true });

        this.helper.common_data.cart_data.address = JSON.parse(
          JSON.stringify(
            this.helper.common_data.cart_data.destination_address.address
          )
        );
      }
    });
  }

  get_distance(current_store: any) {
    if (
      this.helper.common_data.cart_data.destination_address.location[0] &&
      this.helper.common_data.cart_data.destination_address.location[1]
    ) {
      let google_distance = 0;
      let google_time = 0;
      let origin = {
        lat: parseFloat(
          this.helper.common_data.cart_data.pickup_address.location[0]
        ),
        lng: parseFloat(
          this.helper.common_data.cart_data.pickup_address.location[1]
        ),
      };
      let destination = {
        lat: parseFloat(
          this.helper.common_data.cart_data.destination_address.location[0]
        ),
        lng: parseFloat(
          this.helper.common_data.cart_data.destination_address.location[1]
        ),
      };
      let service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response: any, status: any) => {
          if (status == google.maps.DistanceMatrixStatus.OK) {
            this.helper.common_data.cart_data.google_distance =
              response.rows[0].elements[0].distance != undefined
                ? response.rows[0].elements[0].distance.value
                : 1;
            this.helper.common_data.cart_data.google_time =
              response.rows[0].elements[0].duration != undefined
                ? response.rows[0].elements[0].duration.value
                : 1;
            if (!this.helper.common_data.cart_data.google_distance) {
              this.helper.common_data.cart_data.google_distance = 1;
            }
            if (!this.helper.common_data.cart_data.google_time) {
              this.helper.common_data.cart_data.google_time = 1;
            }
            this.cart_service.is_add_cart_observable.next(true);
          } else {
            this.helper.common_data.cart_data.google_distance = 1;
            this.helper.common_data.cart_data.google_time = 1;
          }
          this.check_and_set_schedule(current_store);
          if (this.helper.common_data.cart_data.is_schedule_order) {
            this.check_open(
              current_store,
              this.helper.common_data.cart_data.schedule_date,
              false,
              true
            );
          } else {
            let date: any = this.helper.common_data.cart_data.server_date;
            date = new Date(date).toLocaleString('en-US', {
              timeZone: this.helper.common_data.cart_data.timezone,
            });
            date = new Date(date);
            this.check_open(current_store, date, true, true);
          }
          this.calculate_delivery_time(
            current_store.preparation_time_for_pickup,
            current_store.preparation_time_for_delivery
          );
          if (current_store.close || this.schedule_time_error) {
            if (this.date_array.length > 0) {
              this.set_date(this.date_array[0], current_store);
              if (this.time_array[0]) {
                this.schedule_time = this.time_array[0].time_format;
              } else {
                this.date_array.splice(0, 1);
                this.set_date(this.date_array[0], current_store);
                this.schedule_time = this.time_array[0].time_format;
              }
              this.change_schedule(true, current_store);
            }
          }
        }
      );
    } else {
    }
  }
}
