import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { Helper } from '../shared/helper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../shared/cart.service';
import { DeliverySettingService } from '../shared/delivery-setting.service';
import { OfferService } from '../shared/offer.service';
declare var stripe: any;
declare var elements: any;
import { Cart } from '../shared/cart.model';
import { LoginService } from '../shared/login.service';
import * as moment from 'moment-timezone';
import { LoginRegisterModalComponent } from '../login-register-modal/login-register-modal.component';
import { Params } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./checkout.component.scss'],
  providers: [
    Helper,
    CartService,
    DeliverySettingService,
    OfferService,
    LoginService,
  ],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  user_id: string = '';
  server_token: string = '';
  add_cart_subscriber: any;
  current_store: any = {};
  order_payment: any = {
    cart_offer_message: '',
    cart_offer_price: 0,
    total_cart_price: 0,
    total_service_price: 0,
    delivery_offer_message: '',
    delivery_offer_price: 0,
    service_fee: 0,
    total: 0,
  };
  delivery_currency: string = '';
  login_header_text: string = this.helper.text.pay_text4;

  selected_item_index: number = 0;
  selected_product_index: number = 0;
  current_item: any = {};
  current_formule: any = {};
  required_count: number = 0;
  total: number = 0;
  required_temp_count: number = 0;
  qty: number = 1;
  note_for_item: string = '';
  modalRef: any;
  edit_cart_modalRef: any;

  promo_code_apply_on_minimum_amount: number = 0;
  promo_error: number = 0;
  promocode: string = '';
  promo_code_detail: string = '';
  promo_applied: boolean = false;

  is_show_pickup_delivery: boolean = false;
  is_show_pre_asap: boolean = false;

  wallet: number = 0;
  wallet_currency_code: string = '';
  is_use_wallet: boolean = false;
  public card_list: any[] = [];
  public selected_payment_gateway: string =
    this.helper.PAYMENT_GATEWAY_CONSTANT.STRIPE;
  applePay: boolean = false;
  googlePay: boolean = false;
  prButton: any;
  paymentRequest: any;

  mod_ref: any;
  mod_ref1: any;
  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;
  error_message: string = '';
  is_card_element_created: boolean = false;
  session_subscriber: any;

  is_show_invoice: boolean = false;
  @ViewChild('CardError') CardError: any;
  @ViewChild('MoaAlert') MoaAlert: any;
  @ViewChild('AccountPopup') AccountPopup!: LoginRegisterModalComponent;

  add_preparation_time_min: number = 0;

  constructor(
    public helper: Helper,
    public login_service: LoginService,
    public offer_service: OfferService,
    public delivery_setting_service: DeliverySettingService,
    private modalService: NgbModal,
    public cart_service: CartService
  ) {}

  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    var json = {
      floor: this.helper.common_data.cart_data.destination_address.floor,
      entry_code:
        this.helper.common_data.cart_data.destination_address.entry_code,
      note: this.helper.common_data.cart_data.destination_address.note,
    };
    this.delivery_setting_service.update_address_detail(json);
    if (this.edit_cart_modalRef) {
      this.edit_cart_modalRef.close();
    }
    if (this.mod_ref) {
      this.mod_ref.close();
    }
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this.mod_ref1) {
      this.mod_ref1.close();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.edit_cart_modalRef = this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass:
        'edit-cart-popup popup-mid product-item-popup mob-full-popup',
    });
  }
  openModal1(template: TemplateRef<any>) {
    this.login_service.modal_ref1 = this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'card-error-popup popup-mid',
    });
  }
  openModal2(template: TemplateRef<any>) {
    this.mod_ref = this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'card-edit-popup popup-mid',
    });
  }

  openModal4(template: TemplateRef<any>) {
    this.login_service.modal_ref1 = this.modalService.open(template, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'forgot-popup',
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (elements._elements.length > 0) {
        elements._elements = [];
      }
    }, 1000);
  }

  ngOnInit(): void {
    if (this.helper.common_data._user_cart.cart_data.cart.length > 0) {
      this.check_detail();
      var detail = this.helper.common_data.observable.subscribe(
        async (data) => {
          if (data) {
            setTimeout(() => {
              if (detail) {
                detail.unsubscribe();
              }
            }, 100);

            this.current_store = data.current_store ? data.current_store : {};
            this.delivery_setting_service.check_and_set_schedule(
              this.current_store
            );
            this.cart_service.add_to_cart();
            // this.cart_service.is_add_cart_observable.next(true);
            this.add_cart_subscriber =
              this.cart_service.is_add_cart_observable.subscribe(
                async (data: any) => {
                  if (data) {
                    if (
                      !this.helper.common_data.cart_data.google_distance &&
                      !this.helper.common_data.cart_data.ipickup_delivery
                    ) {
                      this.delivery_setting_service.get_distance(
                        this.current_store
                      );
                    } else {
                      this.get_order_invoice();
                    }
                  }
                }
              );
          }
        }
      );
    } else {
      this.helper.route.params.subscribe((x: Params) => {
        if (this.helper.common_data.cart_data.is_order_pay || x['table_no']) {
          this.helper.router.navigate(['shop/table/' + x['table_no']]);
        } else {
          this.helper.router.navigate(['shop']);
        }
      });
    }
  }

  go_to_home() {
    this.helper.router.navigate(['/home/table']);
  }

  ngOnDestroy() {
    if (this.add_cart_subscriber) {
      this.add_cart_subscriber.unsubscribe();
    }
    if (this.cardNumberElement) {
      this.cardNumberElement.unmount();
    }
    if (this.cardExpiryElement) {
      this.cardExpiryElement.unmount();
    }
    if (this.cardCvcElement) {
      this.cardCvcElement.unmount();
    }
    if (this.session_subscriber) {
      this.session_subscriber.unsubscribe();
    }
  }

  go_to_shop() {
    if (this.edit_cart_modalRef) {
      this.edit_cart_modalRef.close();
    }
    var json = {
      floor: this.helper.common_data.cart_data.destination_address.floor,
      entry_code:
        this.helper.common_data.cart_data.destination_address.entry_code,
      note: this.helper.common_data.cart_data.destination_address.note,
    };
    this.delivery_setting_service.update_address_detail(json);
    if (this.helper.common_data.cart_data.is_order_pay) {
      this.helper.router.navigate([
        'shop/table/' + this.helper.common_data.cart_data.table_no,
      ]);
    } else {
      this.helper.router.navigate(['shop']);
    }
  }

  check_detail() {
    if (localStorage['user']) {
      let user = JSON.parse(localStorage['user']);
      if (user && user._id) {
        this.user_id = user._id;
        this.server_token = user.server_token;
        this.delivery_setting_service.get_fav_address({
          user_id: this.user_id,
          server_token: this.server_token,
        });
        this.get_card();
        if (this.session_subscriber) {
          this.session_subscriber.unsubscribe();
        }
        if (!this.helper.common_data.cart_data.ipickup_delivery) {
          setTimeout(() => {
            this.delivery_setting_service.load_autocomplete(
              this.current_store,
              false
            );
          }, 100);
        }
      }
    } else {
      this.user_id = '';
      this.server_token = '';
      this.login_service.user_login = {
        cart_unique_token: localStorage.getItem('cart_unique_token'),
        email: '',
        password: '',
        login_by: this.helper.title.manual,
        social_id: '',
      };
      this.login_service.user_forgot_password = {
        email: '',
      };
      this.login_service.error_message = '';
      this.login_service.user_register = {
        cart_unique_token: localStorage.getItem('cart_unique_token'),
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        social_id: '',
        login_by: this.helper.title.manual,
        confirm_password: '',
        country_id: '',
        city: '',
        address: '',
        country_phone_code: '',
        phone: null,
        image_url: './default.png',
        referral_code: '',
        is_phone_number_verified: false,
        terms: false,
        is_email_verified: false,
        currency_code: '',
        is_whop: true,
        website_id: this.helper.website_data.detail.store_id,
      };
      this.login_service.country_list = this.helper.country_list;
      if (this.login_service.country_list.length > 0) {
        this.login_service.user_register.currency_code = 'CHF';
        this.login_service.user_register.country_phone_code = '+41';
        this.login_service.user_register.country_id = 'Switzerland';
        if (
          this.helper.website_data.detail &&
          this.helper.website_data.detail.country_phone_code
        ) {
          let index = this.login_service.country_list.findIndex(
            (x) =>
              x.country_phone_code ==
              this.helper.website_data.detail.country_phone_code
          );
          if (index !== -1) {
            this.login_service.user_register.currency_code =
              this.login_service.country_list[index].currency_code;
            this.login_service.user_register.country_phone_code =
              this.login_service.country_list[index].country_phone_code;
            this.login_service.user_register.country_id =
              this.login_service.country_list[index].country_name;
          }
        }
      }
      setTimeout(() => {
        this.delivery_setting_service.load_autocomplete(
          this.current_store,
          false
        );
      }, 100);
      this.session_subscriber =
        this.helper.common_data.session_observable.subscribe((data) => {
          if (data) {
            this.check_detail();
          }
        });
    }
  }

  async get_order_invoice() {
    var bool;
    await this.helper.ngZone.run(async () => {
      let get_order_cart_invoice_json: any = {
        user_id: this.user_id,
        cart_unique_token: '',
        total_distance: this.helper.common_data.cart_data.google_distance,
        total_time: this.helper.common_data.cart_data.google_time,
        store_id: this.helper.website_data.detail.store_id,
        is_order_pay: this.helper.common_data.cart_data.is_order_pay,
        order_type: 7,
        total_cart_price:
          this.helper.common_data._user_cart.cart_data.total_cart_amount,
        total_item_count:
          this.helper.common_data._user_cart.cart_data.total_item,
        is_user_pick_up_order:
          this.helper.common_data.cart_data.ipickup_delivery,
        is_offer_apply: true,
        is_whop: true,
      };

      if (this.user_id === '') {
        get_order_cart_invoice_json.cart_unique_token =
          localStorage.getItem('cart_unique_token');
      }

      var res_data = await this.helper.http_post_method_requester(
        this.helper.POST_METHOD.GET_ORDER_CART_INVOICE,
        get_order_cart_invoice_json,
        true,
        true,
        true,
        true
      );

      if (res_data.success) {
        this.order_payment = res_data.order_payment;
        this.helper.common_data.cart_data.timezone = res_data.timezone;
        this.helper.common_data.cart_data.server_date = res_data.server_time;
        this.current_store = res_data.store;
        this.current_store.is_pickup_possible = true;
        this.current_store.is_delivery_possible = true;
        this.delivery_setting_service.is_delay_order = res_data.is_delay_order;
        this.delivery_setting_service.close_till = res_data.close_till;
        this.delivery_setting_service.delay_distance = res_data.delay_distance;
        this.delivery_setting_service.delay_time = res_data.delay_time;
        this.delivery_setting_service.is_emergency = res_data.is_emergency;
        this.delivery_setting_service.emergency_close_till =
          res_data.emergency_close_till;
        this.helper.common_data._user_cart.cart_data.order_payment_id =
          res_data.order_payment._id;
        this.delivery_currency = res_data.order_payment.order_currency_code;

        if (this.helper.common_data.cart_data.is_schedule_order) {
          this.delivery_setting_service.set_time(
            { time_format: this.delivery_setting_service.schedule_time },
            this.current_store
          );
        } else {
          let date: any = res_data.server_time;
          date = new Date(date).toLocaleString('en-US', {
            timeZone: res_data.timezone,
          });
          date = new Date(date);
          this.delivery_setting_service.check_open(
            this.current_store,
            date,
            false,
            true
          );
          // if(!this.helper.common_data.cart_data.ipickup_delivery && this.is_delay_order && (this.helper.common_data.cart_data.google_distance/1000) >= this.delay_distance){
          //   this.delay_order_error = this.delivery_setting_service.check_delay_order(this.close_till)
          // } else {
          //   this.delay_order_error = false;
          // }
        }
        this.offer_service.check_delivery_offer(
          this.current_store,
          this.delivery_currency
        );
        this.offer_service.check_cart_offer(
          this.current_store,
          this.delivery_currency
        );
        if (!this.paymentRequest) {
          this.setup_and_check_google_apple_pay();
        }
        if (this.promo_applied) {
          await this.check_promo();
        }
        bool = true;
      } else {
        if (res_data.error_code === 557) {
          if (this.edit_cart_modalRef) {
            this.edit_cart_modalRef.close();
          }
          if (this.helper.common_data.cart_data.is_order_pay) {
            this.helper.router.navigate([
              'shop/table/' + this.helper.common_data.cart_data.table_no,
            ]);
          } else {
            this.helper.router.navigate(['shop']);
          }
        }
        bool = false;
      }
    });
    return bool;
  }

  cart_decrease_qty(product_index: number, item_index: number) {
    this.cart_service.decrease_qty(
      product_index,
      item_index,
      this.edit_cart_modalRef,
      this.helper.common_data._user_cart.cart_data.min_order_price,
      this.MoaAlert
    );
    this.offer_service.check_cart_offer(
      this.current_store,
      this.delivery_currency
    );
  }

  cart_increase_qty(product_index: number, item_index: number) {
    this.cart_service.increase_qty(
      product_index,
      item_index,
      this.helper.common_data._user_cart.cart_data.min_order_price,
      this.MoaAlert
    );
    this.offer_service.check_cart_offer(
      this.current_store,
      this.delivery_currency
    );
  }

  remove_from_cart(product_index: number, item_index: number) {
    this.cart_service.remove_from_cart(
      product_index,
      item_index,
      this.edit_cart_modalRef,
      this.helper.common_data._user_cart.cart_data.min_order_price,
      this.MoaAlert
    );
    this.offer_service.check_cart_offer(
      this.current_store,
      this.delivery_currency
    );
  }

  select_formule(formule: any) {
    this.current_formule = JSON.parse(JSON.stringify(formule));
    console.log(this.current_formule);
    this.calculate_is_required();
    this.calculateTotalAmount();
  }

  open_edit_item_modal(
    template: TemplateRef<any>,
    item: any,
    selected_product_index: number,
    selected_item_index: number
  ) {
    this.current_formule = { formule_id: '' };
    if (item.formule_list && item.formule_list.length > 0) {
      this.current_formule = JSON.parse(JSON.stringify(item.formule_list[0]));
    }
    this.selected_item_index = selected_item_index;
    this.selected_product_index = selected_product_index;
    let item_index =
      this.helper.common_data._user_cart.cart_main_item.findIndex(
        (x: any) => x._id == item.item_id
      );
    let current_specification =
      this.helper.common_data._user_cart.cart_main_item[item_index]
        .specifications;
    let order_specification = item.specifications;
    let new_specification: any[] = [];

    current_specification.forEach((x: any) => {
      var index = order_specification.findIndex(
        (order_sp: any) => order_sp.unique_id == x.unique_id
      );
      if (x.formule_id) {
        index = order_specification.findIndex(
          (order_sp: any) =>
            order_sp.unique_id == x.unique_id &&
            order_sp.formule_id == x.formule_id
        );
      }
      if (index == -1) {
        x.list.forEach((y: any) => {
          y.price = Number(y.price);
        });
        new_specification.push(x);
      } else {
        var new_specification_list: any[] = [];
        x.list.forEach((y: any) => {
          y.price = Number(y.price);
          var list_index = order_specification[index].list.findIndex(
            (order_sp_list: any) => order_sp_list.unique_id == y.unique_id
          );
          if (list_index == -1) {
            y.is_default_selected = false;
            new_specification_list.push(y);
          } else {
            order_specification[index].list[list_index].price = y.price;
            new_specification_list.push(
              order_specification[index].list[list_index]
            );
          }
        });
        let json = {
          list: new_specification_list,
          unique_id: x.unique_id,
          name: x.name,
          formule_id: x.formule_id,
          is_required: x.is_required,
          min_selection: x.min_selection,
          max_selection: x.max_selection,
          price: Number(x.price),
          type: x.type,
        };
        new_specification.push(json);
      }
    });
    this.qty = item.quantity;
    this.required_count = 0;
    this.current_item = JSON.parse(JSON.stringify(item));
    this.current_item.price =
      this.helper.common_data._user_cart.cart_main_item[item_index].price;
    this.current_item.name =
      this.helper.common_data._user_cart.cart_main_item[item_index].name;
    // new_specification.sort(this.sortFormule);
    this.current_item.specifications = new_specification;
    this.current_item.formule_list =
      this.helper.common_data._user_cart.cart_main_item[
        item_index
      ].formule_list;
    this.calculate_is_required();
    this.edit_item_calculateTotalAmount();
    this.note_for_item = item.note_for_item;
    if (
      this.offer_service.check_buy_one_get_one(this.current_item) &&
      this.current_item.is_buy_one_get_one
    ) {
      this.current_item.is_buy_one_get_one = true;
    } else {
      this.current_item.is_buy_one_get_one = false;
    }
    this.modalRef = this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'product-item-popup popup-mid',
    });
  }

  calculate_is_required() {
    this.required_count = 0;
    this.current_item.specifications.forEach((specification_group: any) => {
      if (
        !specification_group.formule_id ||
        specification_group.formule_id == this.current_formule.formule_id
      ) {
        if (specification_group.is_required && !specification_group.is_close) {
          this.required_count++;
        }
        let list = specification_group.list.filter((x: any) => {
          if (!x.is_close) {
            return x;
          }
        });
        if (list.length < specification_group.min_selection) {
          specification_group.min_selection = list.length;
        }
        if (list.length < specification_group.max_selection) {
          specification_group.max_selection = list.length;
        }
      }
    });
  }

  edit_item_calculateTotalAmount() {
    this.total = this.current_item.price;
    if (this.current_formule.formule_id && this.current_formule.price) {
      this.total = Number(this.current_formule.price);
    }
    this.required_temp_count = 0;
    this.current_item.specifications.forEach(
      (specification_group: any, specification_group_index: number) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let isAllowed = false;

          if (
            specification_group.min_selection == undefined ||
            specification_group.max_selection == undefined
          ) {
            if (specification_group.type == 1) {
              specification_group.min_selection = 1;
              specification_group.max_selection = 1;
              // specification_group.is_required = true;
            } else {
              specification_group.max_selection =
                specification_group.list.length;
              if (specification_group.is_required) {
                specification_group.min_selection = 1;
              } else {
                specification_group.min_selection = 0;
              }
            }
          }
          if (specification_group.max_selection == 0) {
            specification_group.max_selection = 1;
          }

          var default_selected_count = 0;
          specification_group.selected_specification = 0;
          specification_group.list.forEach(
            (specification: any, specification_index: number) => {
              specification.price = Number(specification.price);
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                this.total = this.total + specification.price;
                default_selected_count++;
                specification_group.selected_specification++;
              }
              specification_group.default_selected_count =
                default_selected_count;
            }
          );

          if (
            specification_group.type == 1 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= 1) {
              this.required_temp_count++;
            }
          } else if (
            specification_group.type == 2 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= specification_group.min_selection) {
              this.required_temp_count++;
            }
          }
        }
      }
    );
    this.total = this.total * this.qty;

    this.offer_service.check_item_offer(this.current_item, this.total);
  }

  sortFormule(a: any, b: any) {
    if (a.formule_id) return -1;
    return 0;
  }

  changeradio(
    event: any,
    specification_group_index: number,
    specification_index: number
  ) {
    if (
      !this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_close
    ) {
      var index = this.current_item.specifications[
        specification_group_index
      ].list.findIndex((x: any) => x.is_default_selected == true);
      if (index !== -1) {
        this.current_item.specifications[specification_group_index].list[
          index
        ].is_default_selected = false;
      }
      this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_default_selected = true;
      this.calculateTotalAmount();
    }
  }

  changecheckbox(
    event: any,
    specification_group_index: number,
    specification_index: number
  ) {
    if (
      !this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_close
    ) {
      if (event.target.checked) {
        if (
          this.current_item.specifications[specification_group_index]
            .default_selected_count >=
          this.current_item.specifications[specification_group_index]
            .max_selection
        ) {
          var index = this.current_item.specifications[
            specification_group_index
          ].list.findIndex((x: any) => x.is_default_selected == true);
          this.current_item.specifications[specification_group_index].list[
            index
          ].is_default_selected = false;
        }
      }
      this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_default_selected = event.target.checked;
      this.calculateTotalAmount();
    }
  }

  calculateTotalAmount() {
    this.total = this.current_item.price;
    if (this.current_formule.formule_id && this.current_formule.price) {
      this.total = Number(this.current_formule.price);
    }
    this.required_temp_count = 0;
    this.current_item.specifications.forEach(
      (specification_group: any, specification_group_index: number) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let isAllowed = false;
          var default_selected_count = 0;
          specification_group.is_disabled = true;
          specification_group.selected_specification = 0;
          specification_group.list.forEach(
            (specification: any, specification_index: number) => {
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                this.total = this.total + specification.price;
                default_selected_count++;
                specification_group.selected_specification++;
              }
              specification_group.default_selected_count =
                default_selected_count;
            }
          );

          if (
            specification_group.type == 1 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= 1) {
              this.required_temp_count++;
              specification_group.is_disabled = false;
            }
          } else if (
            specification_group.type == 2 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= specification_group.min_selection) {
              this.required_temp_count++;
              specification_group.is_disabled = false;
            }
          }
        }
      }
    );
    this.total = this.total * this.qty;
    this.offer_service.check_item_offer(this.current_item, this.total);
  }

  updateCart() {
    let specificationPriceTotal = 0;
    let specificationPrice = 0;
    let specificationList: any[] = [];
    this.current_item.specifications.forEach(
      (specification_group: any, specification_group_index: number) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let specificationItemCartList: any[] = [];
          specification_group.list.forEach(
            (specification: any, specification_index: number) => {
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                specificationPrice = specificationPrice + specification.price;
                specificationPriceTotal =
                  specificationPriceTotal + specification.price;
                specificationItemCartList.push(specification);
              }
            }
          );

          if (specificationItemCartList.length > 0) {
            let specificationsItem_json = {
              list: specificationItemCartList,
              unique_id: specification_group.unique_id,
              name: specification_group.name,
              price: specificationPrice,
              type: specification_group.type,
              formule_id: specification_group.formule_id,
              is_required: specification_group.is_required,
              min_selection: specification_group.min_selection,
              max_selection: specification_group.max_selection,
            };
            specificationList.push(specificationsItem_json);
          }
          specificationPrice = 0;
        }
      }
    );

    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].item_price = this.current_item.price;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].offer_price =
      this.current_item.offer_price;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].is_buy_one_get_one =
      this.current_item.is_buy_one_get_one;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].total_specification_price =
      specificationPriceTotal;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].total_item_and_specification_price =
      this.total;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].note_for_item = this.note_for_item;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].specifications = specificationList;

    if (this.current_formule.formule_id) {
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].formule_list = [this.current_formule];
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].item_price = Number(
        this.current_formule.price
      );
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].item_name = this.current_formule.name;
    } else {
      this.helper.common_data._user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].formule_list = [];
    }

    var total_item_price = 0;
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].items.forEach((item: any) => {
      total_item_price =
        total_item_price + item.total_item_and_specification_price;
    });
    this.helper.common_data._user_cart.cart_data.cart[
      this.selected_product_index
    ].total_item_price = total_item_price;
    this.cart_service.calculateTotalAmount(null, null, this.MoaAlert);
    this.modalRef.close();
    this.offer_service.check_cart_offer(
      this.current_store,
      this.delivery_currency
    );
  }

  async check_promo() {
    if (this.promocode !== '') {
      let json = {
        user_id: this.user_id,
        server_token: this.server_token,
        promo_code_name: this.promocode,
        order_payment_id: this.order_payment._id,
      };
      var res_data = await this.helper.http_post_method_requester(
        this.helper.POST_METHOD.APPLY_PROMO_CODE,
        json,
        true,
        true,
        true,
        true
      );

      if (res_data.success) {
        this.promo_error = 0;
        this.promo_applied = true;
        this.order_payment = res_data.order_payment;
        this.promo_code_detail =
          res_data.promo_code_detail + ' “' + this.promocode + '”';
      } else {
        this.promo_applied = false;
        this.promo_code_detail = '';
        if (res_data.promo_code_apply_on_minimum_amount) {
          this.promo_error = 2;
          this.promo_code_apply_on_minimum_amount =
            res_data.promo_code_apply_on_minimum_amount;
        } else if (res_data.error_code == 551) {
          this.promo_error = 3;
        } else {
          this.promo_error = 1;
        }
      }
      return;
    } else {
      return;
    }
  }

  remove_promo() {
    this.promo_code_apply_on_minimum_amount = 0;
    this.promo_error = 0;
    this.promocode = '';
    this.promo_code_detail = '';
    this.promo_applied = false;
    this.get_order_invoice();
  }

  promo_text() {
    this.promo_error = 0;
  }

  select_delivery_option(ipickup_delivery: boolean) {
    this.helper.common_data.cart_data.ipickup_delivery = ipickup_delivery;
    this.is_show_pickup_delivery = false;
    this.selected_payment_gateway = this.helper.PAYMENT_GATEWAY_CONSTANT.STRIPE;
    if (
      !this.delivery_setting_service.is_autocomplete_loaded &&
      !ipickup_delivery
    ) {
      setTimeout(() => {
        this.delivery_setting_service.load_autocomplete(
          this.current_store,
          true
        );
      }, 100);
    }
    this.delivery_setting_service.check_and_set_schedule(this.current_store);
    if (this.helper.common_data.cart_data.is_schedule_order) {
      this.delivery_setting_service.check_open(
        this.current_store,
        this.helper.common_data.cart_data.schedule_date,
        false,
        true
      );
    } else {
      let date: any = this.helper.common_data.cart_data.server_date;
      date = new Date(date).toLocaleString('en-US', {
        timeZone: this.helper.common_data.cart_data.timezone,
      });
      date = new Date(date);
      this.delivery_setting_service.check_open(
        this.current_store,
        date,
        true,
        true
      );
    }
    this.delivery_setting_service.calculate_delivery_time(
      this.current_store.preparation_time_for_pickup,
      this.current_store.preparation_time_for_delivery
    );
    if (
      this.current_store.close ||
      this.delivery_setting_service.schedule_time_error
    ) {
      if (this.delivery_setting_service.date_array.length > 0) {
        this.delivery_setting_service.set_date(
          this.delivery_setting_service.date_array[0],
          this.current_store
        );
        if (this.delivery_setting_service.time_array[0]) {
          this.delivery_setting_service.schedule_time =
            this.delivery_setting_service.time_array[0].time_format;
        } else {
          this.delivery_setting_service.date_array.splice(0, 1);
          this.delivery_setting_service.set_date(
            this.delivery_setting_service.date_array[0],
            this.current_store
          );
          this.delivery_setting_service.schedule_time =
            this.delivery_setting_service.time_array[0].time_format;
        }
        this.delivery_setting_service.change_schedule(true, this.current_store);
      }
    }
    this.get_order_invoice();
  }

  change_pre_asap() {
    this.is_show_pre_asap = false;
    this.delivery_setting_service.change_schedule(
      !this.helper.common_data.cart_data.is_schedule_order,
      this.current_store
    );
  }

  async get_card() {
    var response_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.GET_CARD_LIST,
      { user_id: this.user_id, server_token: this.server_token },
      true,
      true,
      false,
      false
    );
    if (response_data.success) {
      this.card_list = response_data.cards;
      this.wallet = response_data.wallet;
      this.wallet_currency_code = response_data.wallet_currency_code;
      this.is_use_wallet = response_data.is_use_wallet;
    } else {
      this.wallet = response_data.wallet;
      this.wallet_currency_code = response_data.wallet_currency_code;
      this.is_use_wallet = response_data.is_use_wallet;
    }
  }

  change_payment_mode(selected_payment_gateway: any) {
    this.selected_payment_gateway = selected_payment_gateway;
  }

  setup_and_check_google_apple_pay() {
    this.paymentRequest = stripe.paymentRequest({
      country: 'CH',
      currency: 'chf',
      // currency: this.delivery_currency.toLowerCase() || 'chf',
      total: {
        label: 'total',
        amount: Math.round(this.order_payment.total * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['browserCard'],
    });

    var elements = stripe.elements();
    this.prButton = elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    });

    this.paymentRequest.canMakePayment().then(
      (result: any) => {
        if (result) {
          this.googlePay = result.googlePay;
          this.applePay = result.applePay;
          setTimeout(() => {
            // paymentRequest.show()
          }, 1000);
        } else {
          // document.getElementById('payment-request-button').style.display = 'none';
        }
      },
      (error: any) => {
        console.log(error);
      }
    );

    this.paymentRequest.on('paymentmethod', (ev: any) => {
      this.pay_order_payment(false, ev);
    });
  }

  async change_user_wallet_status(event: any) {
    var response_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.CHANGE_USER_WALLET_STATUS,
      {
        user_id: this.user_id,
        server_token: this.server_token,
        is_use_wallet: event.target.checked,
      },
      true,
      true,
      false,
      false
    );
    if (response_data.success) {
      this.is_use_wallet = event.target.checked;
    } else {
      this.is_use_wallet = !event.target.checked;
    }
  }

  async select_card(card_id: string, card_index: number) {
    if (!this.card_list[card_index].is_default) {
      var response_data = await this.helper.http_post_method_requester(
        this.helper.POST_METHOD.SELECT_CARD,
        {
          user_id: this.user_id,
          server_token: this.server_token,
          card_id: card_id,
        },
        true,
        true,
        false,
        false
      );
      if (response_data.success) {
        let index = this.card_list.findIndex((x) => x.is_default == true);
        if (index !== -1) {
          this.card_list[index].is_default = false;
        }
        this.card_list[card_index].is_default = true;
        if (this.mod_ref) {
          this.mod_ref.close();
        }
      } else {
      }
    }
  }

  add_new_card(template: TemplateRef<any>) {
    this.mod_ref1 = this.modalService.open(template, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'open-new-card-popup card-edit-popup popup-mid',
    });
    if (!this.is_card_element_created) {
      this.is_card_element_created = true;
      setTimeout(() => {
        this.cardNumberElement = elements.create('cardNumber', {});
        this.cardNumberElement.mount('#card-number-element');

        this.cardExpiryElement = elements.create('cardExpiry', {});
        this.cardExpiryElement.mount('#card-expiry-element');

        this.cardCvcElement = elements.create('cardCvc', {});
        this.cardCvcElement.mount('#card-cvc-element');
      }, 100);
    } else {
      setTimeout(() => {
        this.cardNumberElement.mount('#card-number-element');
        this.cardExpiryElement.mount('#card-expiry-element');
        this.cardCvcElement.mount('#card-cvc-element');
      }, 100);
    }
  }

  async add_card() {
    var response_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.GET_STRIPE_ADD_CARD_INTENT,
      {},
      true,
      false,
      false,
      true
    );
    stripe
      .handleCardSetup(response_data.client_secret, this.cardNumberElement, {
        payment_method_data: {
          billing_details: {
            // name: this.add_user.name,
            // phone: this.add_user.country_phone_code + this.add_user.phone,
            // email: this.add_user.email,
          },
        },
      })
      .then((result: any) => {
        if (result.error) {
          this.helper.loader_service.loading = false;
          this.error_message = result.error.message;
        } else {
          this.error_message = '';
          this.add_card_service({
            payment_method: result.setupIntent.payment_method,
          });
        }
      });
  }

  async add_card_service(card_data: any) {
    card_data.user_id = this.user_id;
    card_data.server_token = this.server_token;
    card_data.payment_id = this.helper.PAYMENT_GATEWAY_CONSTANT.STRIPE;
    card_data.type = 7;
    card_data.card_expiry_date =
      card_data.expiry_month + '/' + card_data.expiry_year;

    var response_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.ADD_CARD,
      card_data,
      true,
      true,
      false,
      true
    );

    if (response_data.success) {
      this.card_list.push(response_data.card);
      this.cardNumberElement.clear();
      this.mod_ref1.close();
    } else {
    }
  }

  async create_order() {
    if (this.user_id) {
      var res_data: any = await this.get_order_invoice();
      if (res_data) {
        if (
          (this.delivery_setting_service.schedule_time_error &&
            this.delivery_setting_service.is_schedule_order) ||
          (!this.current_store.is_pickup_possible &&
            this.helper.common_data.cart_data.ipickup_delivery) ||
          (!this.current_store.is_delivery_possible &&
            !this.helper.common_data.cart_data.ipickup_delivery)
        ) {
        } else {
          if (
            this.selected_payment_gateway ==
            this.helper.PAYMENT_GATEWAY_CONSTANT.STRIPE
          ) {
            let index = this.card_list.findIndex((x) => x.is_default == true);
            if (index !== -1) {
              this.pay_order_payment(false, null);
            } else {
              this.error_message = this.helper.title.please_add_card_first;
            }
          } else if (
            this.selected_payment_gateway ==
            this.helper.PAYMENT_GATEWAY_CONSTANT.CASH
          ) {
            this.pay_order_payment(true, null);
          } else if (
            this.selected_payment_gateway ==
              this.helper.PAYMENT_GATEWAY_CONSTANT.GOOGLE_PAY ||
            this.selected_payment_gateway ==
              this.helper.PAYMENT_GATEWAY_CONSTANT.APPLE_PAY
          ) {
            this.update_google_apple_pay();
          }
        }
      }
    } else {
      this.AccountPopup.openModal();
    }
  }

  update_google_apple_pay() {
    this.paymentRequest.update({
      total: {
        label: 'total',
        amount: Math.round(this.order_payment.total * 100),
      },
    });
    this.paymentRequest.show();
  }

  async pay_order_payment(payment_mode_cash: boolean, ev: any) {
    var payment_method = null;
    if (ev) {
      payment_method = ev.paymentMethod.id;
    }
    let json = {
      user_id: this.user_id,
      server_token: this.server_token,
      order_payment_id:
        this.helper.common_data._user_cart.cart_data.order_payment_id,
      payment_id: this.selected_payment_gateway,
      is_payment_mode_cash: payment_mode_cash,
      payment_method: payment_method,
    };
    if (payment_mode_cash) {
      this.pay_order_payment_api(json);
    } else {
      var res_data = await this.helper.http_post_method_requester(
        this.helper.POST_METHOD.GET_STRIPE_PAYMENT_INTENT,
        json,
        true,
        false,
        true,
        true
      );

      if (res_data.success) {
        if (res_data.is_payment_paid) {
          this.pay_order_payment_api(json);
        } else {
          if (
            this.selected_payment_gateway ==
            this.helper.PAYMENT_GATEWAY_CONSTANT.STRIPE
          ) {
            stripe
              .confirmCardPayment(res_data.client_secret, {
                payment_method: res_data.payment_method,
              })
              .then(
                (result: any) => {
                  if (result.paymentIntent) {
                    this.pay_order_payment_api(json);
                  } else {
                    this.openModal1(this.CardError);
                    this.helper.loader_service.loading = false;
                    // this.error_message = result.error.message;
                  }
                },
                (error: any) => {
                  this.openModal1(this.CardError);
                  this.helper.loader_service.loading = false;
                  // this.error_message = error.message;
                }
              );
          } else {
            stripe
              .confirmCardPayment(
                res_data.client_secret,
                {
                  payment_method: res_data.payment_method,
                },
                { handleActions: false }
              )
              .then(
                (result: any) => {
                  if (result.error) {
                    this.error_message = result.error.message;
                    this.helper.loader_service.loading = false;
                    ev.complete('fail');
                  } else {
                    ev.complete('success');
                    this.pay_order_payment_api(json);
                  }
                },
                (error: any) => {
                  this.error_message = error.message;
                }
              );
          }
        }
      } else {
        this.helper.loader_service.loading = false;
        this.error_message = res_data.error;
      }
    }
  }

  async pay_order_payment_api(json: any) {
    var res_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.PAY_ORDER_PAYMENT,
      json,
      true,
      false,
      true,
      true
    );
    if (res_data.success) {
      this.create_order_service();
    } else {
      this.helper.loader_service.loading = false;
      this.error_message = this.helper.ERROR_CODE[res_data.error_code];
    }
  }

  async create_order_service() {
    let selected_date = this.helper.common_data.cart_data.schedule_date;

    let milisecond = 0;
    var date = new Date();

    if (
      this.delivery_setting_service.delay_order_error &&
      !this.helper.common_data.cart_data.is_schedule_order
    ) {
      this.add_preparation_time_min = this.delivery_setting_service.delay_time;
    } else {
      this.add_preparation_time_min = 0;
    }
    if (this.helper.common_data.cart_data.is_schedule_order && selected_date) {
      var now: any = moment();
      var localOffset = now.utcOffset();
      now.tz(this.helper.common_data.cart_data.timezone); // your time zone, not necessarily the server's
      var centralOffset = now.utcOffset();
      var diffInMinutes = localOffset - centralOffset;
      selected_date = new Date(
        selected_date.setMinutes(selected_date.getMinutes() + diffInMinutes)
      );
      milisecond = selected_date.getTime();
    }
    this.helper.common_data._user_cart.cart_data.destination_addresses[0] =
      this.helper.common_data.cart_data.destination_address;
    // this.helper.common_data._user_cart.cart_data.destination_addresses[0].entry_code = this.helper.common_data.cart_data.destination_address.entry_code;
    // this.helper.common_data._user_cart.cart_data.destination_addresses[0].note = this.helper.common_data.cart_data.destination_address.delivery_note;
    let user = JSON.parse(localStorage['user']);
    this.helper.common_data._user_cart.cart_data.destination_addresses[0].user_details =
      {
        name: user.first_name + ' ' + user.last_name,
        country_phone_code: user.country_phone_code,
        phone: user.phone,
        email: user.email,
      };
    let json = {
      user_id: this.user_id,
      server_token: this.server_token,
      cart_id: this.helper.common_data._user_cart.cart_data.cart_id,
      delivery_note: this.helper.common_data.cart_data.destination_address.note,
      order_payment_id:
        this.helper.common_data._user_cart.cart_data.order_payment_id,
      destination_addresses:
        this.helper.common_data._user_cart.cart_data.destination_addresses,
      is_user_pick_up_order: this.helper.common_data.cart_data.ipickup_delivery,
      is_order_pay: this.helper.common_data.cart_data.is_order_pay,
      table_no: this.helper.common_data.cart_data.table_no,
      is_schedule_order: this.helper.common_data.cart_data.is_schedule_order,
      order_start_at: milisecond,
      add_preparation_time_min: this.add_preparation_time_min,
    };
    if (!this.helper.common_data.cart_data.destination_address.address_id) {
      var current_location: any = localStorage.getItem('current_location');
      if (current_location) {
        current_location = JSON.parse(current_location);
        current_location.note =
          this.helper.common_data.cart_data.destination_address.note;
        current_location.floor =
          this.helper.common_data.cart_data.destination_address.floor;
        current_location.entry_code =
          this.helper.common_data.cart_data.destination_address.entry_code;
        localStorage.setItem(
          'current_location',
          JSON.stringify(current_location)
        );

        var address_json: any = {
          user_id: this.user_id,
          server_token: this.server_token,
          latitude:
            this.helper.common_data.cart_data.destination_address.location[0],
          longitude:
            this.helper.common_data.cart_data.destination_address.location[1],
          address:
            this.helper.common_data.cart_data.destination_address.address,
          note: this.helper.common_data.cart_data.destination_address.note,
          address_name: 'Address',
          floor: this.helper.common_data.cart_data.destination_address.floor,
          entry_code:
            this.helper.common_data.cart_data.destination_address.entry_code,
          country:
            this.helper.common_data.cart_data.destination_address.country,
          city: this.helper.common_data.cart_data.destination_address.city,
          state: '',
          postal_code:
            this.helper.common_data.cart_data.destination_address.postal_code,
          country_code:
            this.helper.common_data.cart_data.destination_address.country_code,
        };
        var res_data = await this.helper.http_post_method_requester(
          this.helper.POST_METHOD.ADD_FAVOURITE_ADDRESS,
          address_json,
          true,
          true,
          true,
          true
        );
        if (res_data.success) {
          current_location._id =
            res_data.favourite_addresses[
              res_data.favourite_addresses.length - 1
            ]._id;
          localStorage.setItem(
            'current_location',
            JSON.stringify(current_location)
          );
        }
      }
    } else {
      var current_location: any = localStorage.getItem('current_location');
      if (current_location) {
        current_location = JSON.parse(current_location);
        current_location.note =
          this.helper.common_data.cart_data.destination_address.note;
        current_location.floor =
          this.helper.common_data.cart_data.destination_address.floor;
        current_location.entry_code =
          this.helper.common_data.cart_data.destination_address.entry_code;
        localStorage.setItem(
          'current_location',
          JSON.stringify(current_location)
        );

        var address_json: any = {
          user_id: this.user_id,
          server_token: this.server_token,
          floor: this.helper.common_data.cart_data.destination_address.floor,
          note: this.helper.common_data.cart_data.destination_address.note,
          entry_code:
            this.helper.common_data.cart_data.destination_address.entry_code,
          address_id:
            this.helper.common_data.cart_data.destination_address.address_id,
        };
        var res_data = await this.helper.http_post_method_requester(
          this.helper.POST_METHOD.UPDATE_FAVOURITE_ADDRESS,
          address_json,
          true,
          true,
          true,
          true
        );
      }
    }
    var res_data = await this.helper.http_post_method_requester(
      this.helper.POST_METHOD.CREATE_ORDER,
      json,
      true,
      true,
      true,
      true
    );
    if (res_data.success) {
      this.helper.common_data._user_cart = new Cart();
      localStorage.setItem(
        'cart_unique_token',
        this.helper.generatorRandomChar(32)
      );
      this.helper.router.navigate(['order-detail/' + res_data.order_id]);
    } else {
    }
  }

  show_invoice(is_show_invoice: boolean) {
    if (is_show_invoice) {
      document.getElementsByTagName('body')[0].classList.add('modal-open');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }
    this.is_show_invoice = is_show_invoice;
  }

  check_button(item_id: string) {
    var div: any = document.getElementById(item_id);
    if (div) {
      if (div.scrollHeight > 75) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
