import { Component, OnInit } from '@angular/core';
import { Helper } from '../shared/helper';

export interface userProfile{
    user_id: string,
    server_token: string,
    email: string,
    old_password: string,
    new_password: string,
    first_name: string,
    last_name: string,
    address: string,
    phone: any,
    login_by: string,
    social_id: string,
    confirm_password: String,
    image_url: string
}

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
  providers: [Helper],
})
export class AccountProfileComponent implements OnInit {

  public edit: Boolean = false;
  public user_profile: userProfile = {
      user_id: '',
      server_token: '',
      email: '',
      old_password: '',
      new_password: '',
      first_name: '',
      last_name: '',
      address: '',
      phone: null,
      login_by: '',
      social_id: '',
      confirm_password: '',
      image_url: ''
  };

  constructor(public helper: Helper) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage['user']);
    this.user_profile = {
        user_id: user._id,
        server_token: user.server_token,
        email: user.email,
        old_password: '',
        new_password: '',
        first_name: user.first_name,
        last_name: user.last_name,
        address: user.address,
        phone: user.phone,
        login_by: user.login_by,
        confirm_password: '',
        social_id: user.social_id,
        image_url: user.image_url
    }
  }

  public formData = new FormData();
  async user_update(user_data:any)
  {

    var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE, this.user_profile, true, true, true, true);

      this.edit = false;
      this.user_profile.new_password="";
      if(response_data.success == false)
      {
          let user = JSON.parse(localStorage['user']);
          this.user_profile.first_name=user.first_name;
          this.user_profile.last_name=user.last_name;
          this.user_profile.address= user.address;
          this.user_profile.phone= user.phone;
          this.user_profile.email= user.email;
      }
      else
      {
          localStorage.setItem('user', JSON.stringify(response_data.user));
          this.helper.fire_observable();
      }
  }

}
