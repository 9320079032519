import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  loading: boolean = false;
  main_loading: boolean = true;
  constructor() { }
  
}
