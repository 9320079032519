import {Injectable} from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Cart, cartAddress, CartData } from "./cart.model";

@Injectable()
export class CommonData {

    public observable = new BehaviorSubject<any>(null);
	public NotDeliver_observable = new BehaviorSubject<any>(null);
    public _user_cart:Cart = new Cart();
    cart_data:any = {
        timezone: '',
        ipickup_delivery: false,
        is_order_pay: false,
        table_no: '',
        is_schedule_order: false,
        schedule_date: null,
        address: '',
        destination_address:new cartAddress('destination'),
        pickup_address:new cartAddress('pickup'),
        city_id: null,
        total_cart_amount: 0
    };
    public session_observable = new BehaviorSubject<Boolean>(false);
	
	public constructor() {

    }

    get user_cart():Cart{
        return this._user_cart;
    }

    set user_cart(cart:Cart){        
        this._user_cart = cart;
    }

}