import { Component, OnInit, OnDestroy } from '@angular/core';
import { Helper } from '../shared/helper';

@Component({
  selector: 'app-profile-common',
  templateUrl: './profile-common.component.html',
  styleUrls: ['./profile-common.component.scss']
})
export class ProfileCommonComponent implements OnInit,OnDestroy {

  user_name: string = '';
  session_subscriber:any;
  constructor(public helper: Helper) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage['user']);
    this.user_name = user.first_name + ' ' + user.last_name;
    this.session_subscriber = this.helper.common_data.session_observable.subscribe((data)=>{
      this.check_detail();
    });
  }

  ngOnDestroy(){
    if(this.session_subscriber){
      this.session_subscriber.unsubscribe();
    }
  }

  check_detail(){
    if(localStorage['user']){
      let user = JSON.parse(localStorage['user']);
      if(user && user._id){
        this.user_name = user.first_name + ' ' + user.last_name;
      }
    } else {
      this.user_name = '';
    }
  }

}
